import { stakeDepositLimit } from '../../utils/constants';
import Table from '../common/table';
import useNumber from '../../utils/hooks/useNumber';
import moment from 'moment/moment';
import useStake from '../../utils/hooks/useStake';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataLoader from '../common/loader';
import useHistory from '../../utils/hooks/useHistory';
import useAppStats from '../../utils/hooks/useAppStats';

const unStakeTableHeaderData = {
  headers: [
    {
      logo: null,
      data: null,
      heading: 'Currency',
      accessor: 'derived_currency',
    },

    {
      logo: null,
      data: null,
      heading: 'Amount',
      accessor: 'amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Date',
      accessor: 'unstaked_at',
      isComponent: true,
    },
    {
      logo: null,
      data: null,
      heading: 'Status',
      accessor: 'state',
    },
  ],
  body: [],
};

function UnstakeInfoContainer({
  unstakeData,
  unstakeListRes,
  isGetUnstakeDataProcessing,
  handleGetUnStakeHistory,
  selectedToken,
}) {
  const { untrailZeros } = useNumber();
  const { getUnstakeAndStakeStatusText } = useHistory();
  const { getAPYByCurrency } = useAppStats();

  return (
    <>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-column gap-15'>
          <h1 className='text-xl'>Unstake Activity</h1>
          <h1 className='text-xl text-active'>
            Projected APY -{' '}
            {getAPYByCurrency(selectedToken?.symbol?.toUpperCase()) || '--'}%
          </h1>
        </div>
        {/* <div className='flex flex-column gap-15'>
          <h1 className='text-xl'>You {selectedFilterOption.label}</h1>
          <h1 className='text-xl text-active text-right'>
            {stakeInputValue || '0'} {selectedToken?.symbol?.toUpperCase()}
          </h1>
        </div> */}
      </div>

      <div className='flex flex-column info-table-container stake-info-table-container unstake-info-table-container'>
        <Table>
          <Table.Header>
            {unStakeTableHeaderData?.headers?.map((item, index) => {
              return (
                <Table.Entry key={index}>
                  <span className='table-data-text'>{item?.heading}</span>
                </Table.Entry>
              );
            })}
          </Table.Header>
          <Table.Body>
            <>
              {isGetUnstakeDataProcessing && unstakeData?.length == 0 ? (
                <div className='mt-5'>
                  <DataLoader msg='Fetching unstaking balance' />
                </div>
              ) : (
                <>
                  <>
                    {unstakeListRes?.payload?.results?.length === 0 ? (
                      <div className='mt-5'>
                        <NotFound msg={'No data found'} />
                      </div>
                    ) : (
                      <>
                        <InfiniteScroll
                          hasMore={unstakeListRes?.payload?.next?.length}
                          dataLength={unstakeData.length}
                          next={handleGetUnStakeHistory}
                          loader={
                            <div className='mt-2 mb-2'>
                              <DataLoader />
                            </div>
                          }
                          height={300}
                        >
                          {unstakeData?.map((unstake, idx) => {
                            return (
                              <Table.Row key={idx}>
                                <Table.Entry>
                                  {unstake?.currency?.toUpperCase()}
                                </Table.Entry>
                                <Table.Entry>
                                  {untrailZeros(unstake?.amount)}
                                </Table.Entry>
                                <Table.Entry>
                                  {moment(unstake?.created_at).format('LLL')}
                                </Table.Entry>
                                <Table.Entry
                                  addClassName={`activity-status ${
                                    getUnstakeAndStakeStatusText(unstake)
                                      .className
                                  }`}
                                >
                                  <span>
                                    {getUnstakeAndStakeStatusText(unstake).text}
                                  </span>
                                </Table.Entry>
                              </Table.Row>
                            );
                          })}
                        </InfiniteScroll>
                      </>
                    )}
                  </>
                </>
              )}
            </>
          </Table.Body>
        </Table>
        <div className='flex flex-row'>
          <div className='progress-container'>
            <div className='progress-bar'></div>
          </div>
        </div>
        <div className='flex flex-row mt-3'>
          <p className='text-m'>{stakeDepositLimit} / 1000 Deposit Limit</p>
        </div>
      </div>
    </>
  );
}

export default UnstakeInfoContainer;
