import React, { useEffect, useState } from 'react';
import FallbackWrapper from '../../components/fallback-wrapper/fallback-wrapper';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { supportEmail } from '../../utils/constants';

function CrashFallback() {
  const crashLog = useRouteError();
  const [errorLogged, setErrorLogged] = useState(false);

  useEffect(() => {
    if (crashLog && !errorLogged) {
      Sentry.captureException(crashLog);
      setErrorLogged(true);
    }
  }, [crashLog, errorLogged]);

  const handleButtonClick = () => {
    window.location.reload();
  };
  return (
    <FallbackWrapper
      title='Something went wrong'
      handleButtonClick={handleButtonClick}
      buttonLabel={'Reload'}
    >
      <div className='fallback-text-div'>
        <p className='mt-3'>
          We've encountered an unexpected issue and are working hard to resolve
          it. Please try refreshing the page or coming back later.
        </p>
        <p className='mt-3'>
          If you're still facing problems, please contact our support team at{' '}
          <a href={`mailto:${supportEmail}`} className='support-link'>
            {supportEmail}
          </a>
          . We're here to help!
        </p>
      </div>
    </FallbackWrapper>
  );
}

export default CrashFallback;
