import React from 'react';

function InfoBox({ dataList, containerClassName }) {
  return (
    <div
      className={`info-group info-group-container ${
        containerClassName ? containerClassName : ''
      }`}
    >
      {dataList.map((item, idx) => (
        <div className='info-group info-group-item' key={idx}>
          {/* <> */}
          <div className='info-block'>
            <div className='info-row'>
              <p>{item.title}</p>
              {item.suffix ? <span>{item.suffix}</span> : null}
              {item.img ? <img src={item.img} alt='USDC' /> : null}
            </div>
            <div className='info-value'>
              <p>{item.value}</p>
            </div>
          </div>
          <div className='border-right'></div>
        </div>
      ))}
    </div>
  );
}

export default InfoBox;
