import { apiSlice } from './apiSlice';
import { baseUrl } from '../utils/constants';

export const feedbackApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation({
      query: ({ formData }) => ({
        url: `${baseUrl.replace('/multipli/v1', '')}/feedback/v2/feedback/`,
        method: 'POST',
        body: formData,
      }),
      transformResponse: (response) => {
        return response.payload;
      },
      providesTags: ['Orbs'],
    }),
  }),
});

export const { useSendFeedbackMutation } = feedbackApiSlice;
