import React, { useEffect, useState } from 'react';
import Stepper from '../common/stepper';
import GradientWrapper from '../common/gradient-wrapper';
import InfoTable from '../info-table/info-table';
import Button from '../common/button';
import useViewport from '../../utils/hooks/useViewPort';
import { useGetCoinStatsQuery } from '../../slices/appSlice';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import useWallet from '../../utils/hooks/useWallet';
import DataLoader from '../common/loader';
import useStake from '../../utils/hooks/useStake';
import toast from 'react-hot-toast';
import NotFound from '../common/not-found';
import { useSelector } from 'react-redux';
import { blockExplorerUrl, supportedChainId } from '../../utils/constants';
import Table from '../common/table';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';

let steps = [
  {
    stepDescription: '',
    stepLabel: 'Initiate your withdrawal request in the Wallet tab.',
    completed: true,
  },
  {
    stepDescription: '',
    stepLabel: 'Please wait for up to 24 hours.',
    completed: true,
  },
  {
    stepDescription: '',
    stepLabel:
      'Click on Withdraw below to complete the withdrawal transaction.',
    completed: true,
  },
];

let overrideStyles = {
  LabelDescription: () => ({
    fontSize: '12px',
    maxWidth: 160,
  }),
  LabelTitle: () => ({
    fontSize: '12px',
    maxWidth: 160,
    color: 'rgba(255, 255, 255, 0.5)',
  }),
};

const withdrawalTableHeaderData = {
  headers: [
    {
      logo: null,
      data: null,
      heading: 'Token',
      accessor: 'token',
    },
    {
      logo: null,
      data: null,
      heading: 'Amount',
      accessor: 'amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Withdraw',
      accessor: 'withdraw',
      isComponent: true,
    },
  ],
  body: [],
};

function StakeWithdrawalContainer({ showPopup }) {
  const { width } = useViewport();
  const { data: coinData } = useGetCoinStatsQuery();
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [pendingWithdrawalSymbol, setPendingWithdrawalSymbol] = useState(null);
  const { getStarkUserBalance, starkWithdrawal } = useWallet();
  const [isFetchWithdrawalBalanceLoading, setIsFetchWithdrawalBalanceLoading] =
    useState(false);
  const { startInitiatelL1Withdrawal } = useStake();
  const { isConnected, chainId } = useWeb3ModalAccount();
  const { userInfo } = useSelector((state) => state.auth);
  const { isUserLoggedIn } = useUserAuthentication();

  const handleWithdraw = async (balanceData) => {
    try {
      setPendingWithdrawalSymbol(balanceData?.symbol?.toLowerCase());
      let res = await starkWithdrawal(balanceData?.starkAssetId);
      await startInitiatelL1Withdrawal(
        balanceData?.balance,
        balanceData?.token,
        res.hash
      );
      getStarkUserBalances();
      setPendingWithdrawalSymbol(null);
      showPopup(
        'Withdrawal Successful',
        `Your withdrawal is now sent for mining, Please click here to know the status `,
        `${blockExplorerUrl}/tx/${res?.hash}`,
        'Etherscan'
      );
    } catch (error) {
      // console.log({ error });
      setPendingWithdrawalSymbol(null);
      toast.error(error);
    }
  };

  const getStarkUserBalances = async () => {
    setIsFetchWithdrawalBalanceLoading(true);

    try {
      const promises = coinData?.stakeCoinList.map((coinInfo) =>
        getStarkUserBalance(
          coinInfo?.symbol,
          coinInfo?.stark_asset_id,
          coinInfo?.blockchain_decimal
        )
      );
      const balances = await Promise.all(promises);
      let formattedBalance = [];
      balances.forEach((balanceData) => {
        if (balanceData.amount > 0) {
          formattedBalance.push({
            ...balanceData,
            token: balanceData?.symbol.toUpperCase(),
            amount: `${
              balanceData.amount
            } ${balanceData?.symbol?.toUpperCase()}`,
            balance: balanceData?.amount,
          });
        }
      });
      setIsFetchWithdrawalBalanceLoading(false);
      setWithdrawalList(formattedBalance);
      return formattedBalance;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (
      coinData?.stakeCoinList?.length &&
      isConnected &&
      userInfo?.payload?.tokens?.access &&
      chainId == supportedChainId
    ) {
      getStarkUserBalances();
    } else if (!isConnected || !userInfo?.payload?.tokens?.access) {
      setWithdrawalList([]);
    }
  }, [
    coinData?.stakeCoinList,
    isConnected,
    userInfo?.payload?.tokens?.access,
    chainId,
  ]);

  return (
    <div className='withdrawal-container'>
      <div className='withdrawal-inner-container'>
        <GradientWrapper>
          <div className='pad-3-5'>
            <h4>Withdrawal Guide</h4>
            <div className='mt-3'>
              <Stepper
                steps={steps}
                overrideStyles={overrideStyles}
                orientation={width >= 550 ? 'horizontal' : 'vertical'}
                labelPosition={width >= 550 ? 'bottom' : 'right'}
                currentStepIndex={null}
              />
            </div>
          </div>
        </GradientWrapper>
      </div>
      <div className='available-balance mt-3'>
        <h4>Ready for Withdrawal</h4>
        <Table>
          <Table.Header>
            {withdrawalTableHeaderData?.headers?.map((item, index) => {
              return (
                <Table.Entry key={index}>
                  <span className='table-data-text'>{item?.heading}</span>
                </Table.Entry>
              );
            })}
          </Table.Header>
          <Table.Body>
            <>
              {isConnected &&
              userInfo?.payload?.tokens?.access &&
              chainId != supportedChainId ? (
                <div className='mt-5'>
                  <NotFound
                    msg={
                      'Please switch the network to see the available balance.'
                    }
                  />
                </div>
              ) : (
                <>
                  {isFetchWithdrawalBalanceLoading ? (
                    <div className='mt-5'>
                      <DataLoader msg='Fetching withdrawal balance' />
                    </div>
                  ) : (
                    <>
                      <>
                        {withdrawalList?.length === 0 ? (
                          <div className='mt-5'>
                            <NotFound
                              msg={
                                isUserLoggedIn
                                  ? 'No data found'
                                  : 'Connect the wallet to see the available balance'
                              }
                            />
                          </div>
                        ) : (
                          <>
                            {withdrawalList?.map((balanceData) => {
                              return (
                                <Table.Row key={balanceData.token}>
                                  <Table.Entry>
                                    {balanceData?.token}
                                  </Table.Entry>
                                  <Table.Entry>
                                    {balanceData?.amount}
                                  </Table.Entry>
                                  <Table.Entry>
                                    <Button
                                      className='btn btn-primary btn-small w-100px'
                                      onClick={() =>
                                        handleWithdraw(balanceData)
                                      }
                                      isLoading={
                                        pendingWithdrawalSymbol ===
                                        balanceData?.token?.toLowerCase()
                                      }
                                    >
                                      Withdraw
                                    </Button>
                                  </Table.Entry>
                                </Table.Row>
                              );
                            })}
                          </>
                        )}
                      </>
                    </>
                  )}
                </>
              )}
            </>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default StakeWithdrawalContainer;
