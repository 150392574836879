import { apiSlice } from './apiSlice';

export const stakeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initiateStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/stake/initiate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    processStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/stake/process/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    initiateUnStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/unstake/initiate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    processUnStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/unstake/process/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    initiateDepositAndStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/stake-deposit/initiate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    validateDepositAndStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/stake-deposit/validate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    processDepositAndStake: builder.mutation({
      query: ({ token, data }) => ({
        url: `/stake-deposit/process/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    claimYield: builder.mutation({
      query: ({ token, data }) => ({
        url: `/yield/claim/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useInitiateStakeMutation,
  useProcessStakeMutation,
  useProcessDepositAndStakeMutation,
  useInitiateDepositAndStakeMutation,
  useValidateDepositAndStakeMutation,
  useInitiateUnStakeMutation,
  useProcessUnStakeMutation,
  useClaimYieldMutation,
} = stakeSlice;
