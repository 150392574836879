import { ethers } from 'ethers';
import React from 'react';

function useNumber() {
  function isDecimal(num) {
    return !!(num % 1);
  }

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1]?.length || 0;
  };

  const maxLengthCheck = (object, decimalPoint, stateValue) => {
    const { value } = object.target;
    const sanitizedValue = removeCommas(value);
    const wholeNumberPart = sanitizedValue.split('.')[0];
    const maxLength = 9;
    // generates the largest integer possible say 99999999
    const MAX_JS_SAFE_INT_FOR_MULTIPLI = Math.pow(10, maxLength) - 1;

    // Handle scientific notation explicitly incase it comes by chance
    if (sanitizedValue.toLowerCase().includes('e')) {
      object.target.value = sanitizedValue.slice(0, -1);
      return;
    }

    // didnt use any util, because using any util will convert the number into e
    if (sanitizedValue > MAX_JS_SAFE_INT_FOR_MULTIPLI) {
      let valueStr = sanitizedValue.toString();
      if (parseInt(valueStr, 10) > MAX_JS_SAFE_INT_FOR_MULTIPLI) {
        valueStr = valueStr.slice(0, maxLength);
      }
      object.target.value = valueStr;
      return;
    }
    if (wholeNumberPart?.length > maxLength) {
      object.target.value = toFixedTrunc(wholeNumberPart, maxLength);
      return;
    }

    const sanitizedValueInNumber = Number(sanitizedValue);

    // Regular expression to match only numbers and optionally one decimal point
    const regex = /^[0-9]*(\.[0-9]*)?$/;

    // Check if the input matches the regex pattern
    if (!regex.test(sanitizedValue)) {
      object.target.value = stateValue; // Reset to previous value or handle invalid input
      return;
    }

    const decimalIndex = String(sanitizedValue).indexOf('.');

    if (decimalIndex !== -1) {
      const decimalPart = sanitizedValue?.split('.')[1];
      const decimalPartLength = decimalPart?.length;

      if (decimalPartLength > Number(decimalPoint)) {
        object.target.value = `${wholeNumberPart}.${decimalPart?.slice(
          0,
          decimalPoint
        )}`;
      }
    } else if (String(sanitizedValue).length >= maxLength) {
      object.target.value = toFixedTrunc(stateValue, maxLength);
    }
  };

  function toFixedTrunc(x, n) {
    const factor = 10 ** n;
    return Math.trunc(x * factor) / factor;
  }

  function calculatePercentage(per, value) {
    if (per == null || value == null) {
      return 0;
    }

    // Calculate temp1 as a decimal
    const temp1 = (per * value) / 100;
    return temp1;
  }

  function untrailZeros(number) {
    if (number) {
      // Convert number to string
      let numStr = number.toString();

      // If there is a decimal point, remove trailing zeros and the decimal point if needed
      if (numStr.includes('.')) {
        numStr = numStr.replace(/\.?0+$/, '');
      }

      // Convert back to the original type
      return Number(numStr);
    }
  }

  function dequantize(number, decimal) {
    return ethers.parseUnits(number, decimal);
  }

  function formatNumber(value) {
    if (value === '' || isNaN(Number(value))) return '';
    // Convert value to string and split into integer and decimal parts
    const [integerPart, decimalPart] = value.toString().split('.');

    // Format integer part with commas
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine formatted integer part with decimal part, if present
    return decimalPart !== undefined && decimalPart !== null
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }

  function removeCommas(value) {
    return value.replace(/,/g, '');
  }

  function isValidNumber(value) {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      Number(value) >= 0
    );
  }

  function convertToAbbreviation(num) {
    if (!String(num).length) {
      return;
    }
    if (num >= 1e12) {
      // Trillions (T)
      return (num / 1e12).toFixed(1).replace(/\.0$/, '') + 'T';
    } else if (num >= 1e9) {
      // Billions (B)
      return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    } else if (num >= 1e6) {
      // Millions (M)
      return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (num >= 1e3) {
      // Thousands (K)
      return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
    } else {
      // If less than 1,000, return the number as is
      return num.toString();
    }
  }

  function getCoveragePercentage(totalValue, coveredValue) {
    if (totalValue === 0) {
      return 0; // To avoid division by zero
    }

    // Calculate the percentage and round it
    let percentageCovered = Math.round((coveredValue / totalValue) * 100);

    // Ensure the percentage does not exceed 100
    return Math.min(percentageCovered, 100);
  }
  return {
    maxLengthCheck,
    toFixedTrunc,
    calculatePercentage,
    untrailZeros,
    dequantize,
    formatNumber,
    removeCommas,
    isValidNumber,
    convertToAbbreviation,
    getCoveragePercentage,
  };
}

export default useNumber;
