import React from 'react';
import Button from '../common/button';
import { images } from '../../utils/images';

const FallbackWrapper = ({
  title,
  handleButtonClick,
  children,
  imageSrc,
  buttonLabel,
}) => {
  return (
    <div className='flex flex-column justify-center items-center h-full fallback-page'>
      <img src={imageSrc || images.notFoundImage} alt={title} />
      <h2 className='mt-4'>{title}</h2>
      {children}
      <Button onClick={handleButtonClick} className='btn btn-primary mt-4 mb-2'>
        {buttonLabel}
      </Button>
    </div>
  );
};

export default FallbackWrapper;
