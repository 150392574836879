import { useSelector } from 'react-redux';
// import { useGetMarketStatsQuery } from '../../slices/appSlice';
import {
  useInitiateNormalL1WithdrawalMutation,
  useInitiateNormalWithdrawalMutation,
  useLazyGetUserDepositVaultIdListQuery,
  useLazyGetUserMultipliBalanceQuery,
  useStartDepositMutation,
  useValidateNormalWithdrawalMutation,
} from '../../slices/userSlice';
import useAppStats from './useAppStats';
import useNumber from './useNumber';
import { getKeyPairFromSignature } from '../stark-ex/app';
import BigNumberUtils from '../../utils/stark-ex/bignumber-ethers-v5';
import useWallet from './useWallet';
import {
  useClaimYieldMutation,
  useInitiateDepositAndStakeMutation,
  useInitiateStakeMutation,
  useInitiateUnStakeMutation,
  useProcessDepositAndStakeMutation,
  useProcessStakeMutation,
  useProcessUnStakeMutation,
  useValidateDepositAndStakeMutation,
} from '../../slices/stakeSlice';
import useSegment from './useSegment';
import * as Sentry from '@sentry/react';

function useStake() {
  const { toFixedTrunc, formatNumber } = useNumber();
  // const { data: marketData } = useGetMarketStatsQuery();
  const { formatMarketPair } = useAppStats();
  const { userInfo } = useSelector((state) => state.auth);
  const [fetchDepositVaultId, { isLoading: isFetchDepositVaultIdLoading }] =
    useLazyGetUserDepositVaultIdListQuery();
  const [fetchUserBalance, { isLoading: isFetchUserBalanceLoading }] =
    useLazyGetUserMultipliBalanceQuery();
  const [startDeposit, { isLoading: isStartDepositLoading }] =
    useStartDepositMutation();
  const {
    initiateDeposit,
    getAllowance,
    approveAllowance,
    signMsgHash,
    getEstimatedGasFees,
    getApprovalGasFees,
    getStarkDepositTransferredAmount,
  } = useWallet();
  const { signature } = useSelector((state) => state.auth);
  const [initiateStake] = useInitiateStakeMutation();
  const [processStake] = useProcessStakeMutation();
  const [initiateDepositAndStake] = useInitiateDepositAndStakeMutation();
  const [validateDepositAndStake] = useValidateDepositAndStakeMutation();
  const [processDepositAndStake] = useProcessDepositAndStakeMutation();
  const [initiateNormalWithdrawal] = useInitiateNormalWithdrawalMutation();
  const [validateNormalWithdrawal] = useValidateNormalWithdrawalMutation();
  const [initiateUnStake] = useInitiateUnStakeMutation();
  const [processUnStake] = useProcessUnStakeMutation();
  const [initiateNormalL1Withdrawal] = useInitiateNormalL1WithdrawalMutation();
  const { sendTrackEvent } = useSegment();
  const [claimYield] = useClaimYieldMutation();
  const { getAPYByCurrency } = useAppStats();

  const getAPYStats = (amount, decimalPoints, symbol) => {
    let inputData = [
      {
        days: '60',
        projected_returns: '--',
        projected_apy: getAPYByCurrency(symbol?.toUpperCase()),
      },
      {
        days: '180',
        projected_returns: '--',
        projected_apy: getAPYByCurrency(symbol?.toUpperCase()),
      },
      {
        days: '360',
        projected_returns: '--',
        projected_apy: getAPYByCurrency(symbol?.toUpperCase()),
      },
      {
        days: '720',
        projected_returns: '--',
        projected_apy: getAPYByCurrency(symbol?.toUpperCase()),
      },
    ];

    let formattedData = inputData.map((item) => {
      // Convert constantYield to decimal
      const constantYieldDecimal =
        getAPYByCurrency(symbol?.toUpperCase()) / 100;

      // Calculate daily yield
      const dailyYield = constantYieldDecimal / 365;

      // Calculate yield amount for 1 day
      const yieldAmount = amount * dailyYield;

      const projectedReturns = yieldAmount * item['days'];

      item['projected_returns'] = formatNumber(
        toFixedTrunc(projectedReturns, decimalPoints)
      );

      return item;
    });

    return formattedData;
  };

  // const lastPrice = (symbol) => {
  //   if (marketData) {
  //     return marketData?.payload[formatMarketPair(symbol)]?.ticker?.last;
  //   }
  // };

  const handleGetUserMultipliBalance = async (selectedToken) => {
    if (userInfo?.payload?.tokens?.access) {
      try {
        let data = await fetchUserBalance(
          userInfo?.payload?.tokens?.access
        ).unwrap();
        return (
          data?.payload.find((item) => item?.currency === selectedToken?.symbol)
            ?.balance || null
        );
      } catch (error) {
        const errorMsg = error?.data?.message || error?.error;
        throw errorMsg || 'Something went wrong, Please try again';
      }
    }
  };

  const processSmartContractDeposit = async (amount, selectedToken) => {
    try {
      sendTrackEvent('depositInitiated', {
        amount,
        token: selectedToken?.symbol,
      });
      sendTrackEvent('fetchDepositVaultIdInitiate', {
        token: selectedToken?.symbol,
      });
      let vaultRes = await fetchDepositVaultId({
        token: userInfo?.payload?.tokens?.access,
        coin: selectedToken?.symbol,
      }).unwrap();

      let starkPublicKey = getKeyPairFromSignature(signature)
        .getPublic()
        .getX();
      const finalKey = BigNumberUtils.BigNumber.from(
        starkPublicKey.toString()
      ).toHexString();
      const vaultId = vaultRes?.payload?.id;
      if (vaultId) {
        sendTrackEvent('fetchDepositVaultIdSuccess', {
          vaultId,
        });
      }
      let depositRes = await initiateDeposit(
        finalKey,
        vaultId,
        amount,
        selectedToken
      );

      sendTrackEvent('depositSuccess', {
        amount,
        token: selectedToken?.symbol,
        vaultId,
      });

      return { smDepositRes: depositRes };
    } catch (error) {
      sendTrackEvent('depositFailed', {
        amount,
        token: selectedToken?.symbol,
        error: error,
      });
      throw error;
    }
  };

  const checkAllowance = async (selectedToken, stakeInputValue) => {
    try {
      sendTrackEvent('allowanceCheckInitiated', {
        token: selectedToken?.symbol,
        amount: stakeInputValue,
      });

      let allowance = await getAllowance(selectedToken?.token_contract);
      const isAllowanceSufficient =
        Number(allowance) >= Number(stakeInputValue);

      sendTrackEvent('allowanceCheckCompleted', {
        token: selectedToken?.symbol,
        amount: stakeInputValue,
        isAllowanceSufficient,
      });

      return isAllowanceSufficient;
    } catch (error) {
      sendTrackEvent('allowanceCheckFailed', {
        token: selectedToken?.symbol,
        amount: stakeInputValue,
        error: error.message,
      });
      throw error;
    }
  };

  const handleApproveAllowance = async (selectedToken) => {
    try {
      sendTrackEvent('approveAllowanceInitiated', {
        token: selectedToken?.symbol,
      });

      let res = await approveAllowance(selectedToken?.token_contract);

      sendTrackEvent('approveAllowanceSuccess', {
        token: selectedToken?.symbol,
      });

      return res;
    } catch (error) {
      sendTrackEvent('approveAllowanceFailed', {
        token: selectedToken?.symbol,
        error: error.message,
      });
      throw error;
    }
  };

  const handleAllowanceApprovalGasFees = async (
    selectedToken,
    stakeInputValue
  ) => {
    try {
      const approvalGasFees = await getApprovalGasFees(
        selectedToken?.token_contract,
        stakeInputValue
      );

      return approvalGasFees;
    } catch (error) {
      throw error;
    }
  };

  const handleDepositEstimatedGasFees = async (selectedToken) => {
    try {
      let vaultRes = await fetchDepositVaultId({
        token: userInfo?.payload?.tokens?.access,
        coin: selectedToken?.symbol,
      }).unwrap();

      let starkPublicKey = getKeyPairFromSignature(signature)
        .getPublic()
        .getX();

      const finalKey = BigNumberUtils.BigNumber.from(
        starkPublicKey.toString()
      ).toHexString();

      const vaultId = vaultRes?.payload?.id;

      let estimateGasFeesRes = await getEstimatedGasFees(
        finalKey,
        selectedToken,
        vaultId
      );
      return estimateGasFeesRes;
    } catch (error) {
      throw error;
    }
  };

  const startNormalWithdrawal = async (selectedToken, amount) => {
    try {
      let initiateNormalWithdrawalRes = await initiateNormalWithdrawal({
        token: userInfo?.payload?.tokens?.access,
        data: {
          token_id: selectedToken?.symbol,
          amount: amount,
          network: 'ETHEREUM',
        },
      }).unwrap();
      let signature = signMsgHash(
        initiateNormalWithdrawalRes?.payload?.msg_hash,
        initiateNormalWithdrawalRes?.payload?.nonce
      );
      let validateNormalWithdrawalRes = await validateNormalWithdrawal({
        token: userInfo?.payload?.tokens?.access,
        data: {
          ...signature,
        },
      }).unwrap();
      return validateNormalWithdrawalRes;
    } catch (error) {
      // console.log({ error });
      const errorMsg = error?.data?.message || error?.error;
      throw errorMsg || 'Something went wrong, Please try again';
    }
  };

  const performStake = async (selectedToken, stakeInputValue) => {
    try {
      sendTrackEvent('stakeRequestInitiate', {
        amount: stakeInputValue,
        asset: selectedToken?.symbol,
      });
      let initiateStakeRes = await initiateStake({
        token: userInfo?.payload?.tokens?.access,
        data: {
          currency: selectedToken?.symbol,
          amount: String(stakeInputValue),
        },
      }).unwrap();
      let signatureData = signMsgHash(
        initiateStakeRes?.payload?.msg_hash,
        initiateStakeRes?.payload?.nonce
      );

      let processReqBody = {
        user_stake_uuid: initiateStakeRes?.payload?.user_stake_uuid,
        signature: signatureData.signature,
        msg_hash: initiateStakeRes?.payload?.msg_hash,
        frontend_timestamp: Date.now(),
        frontend_apy: getAPYByCurrency(selectedToken?.symbol?.toUpperCase()),
      };

      let processStakeRes = await processStake({
        token: userInfo?.payload?.tokens?.access,
        data: processReqBody,
      }).unwrap();

      if (processStakeRes) {
        sendTrackEvent('stakeRequestSuccess', {
          amount: stakeInputValue,
          asset: selectedToken?.symbol,
        });
      }
      return {
        amount: String(stakeInputValue),
        created_at: new Date().toISOString(),
        currency: selectedToken?.symbol,
        derived_currency: `x${selectedToken?.symbol}`,
        stake_uuid: initiateStakeRes?.payload?.user_stake_uuid,
        staked_at: new Date().toISOString(),
        state: 'COMPLETED',
        uuid: initiateStakeRes?.payload?.user_stake_uuid,
      };
    } catch (error) {
      sendTrackEvent('stakeRequestFailed', {
        amount: stakeInputValue,
        asset: selectedToken?.symbol,
        error: error?.data?.message || error?.error,
      });
      throw error?.data?.message || error?.error;
    }
  };

  const initiateDepositWithValidation = async (
    selectedToken,
    stakeInputValue
  ) => {
    try {
      let { smDepositRes } = await processSmartContractDeposit(
        stakeInputValue,
        selectedToken
      );

      let transferredAmount = await getStarkDepositTransferredAmount(
        smDepositRes,
        selectedToken?.quanitization
      );

      let initiateStakeRes = await initiateDepositAndStake({
        token: userInfo?.payload?.tokens?.access,
        data: {
          currency: selectedToken?.symbol,
          amount: String(transferredAmount),
          network: 'ETHEREUM',
        },
      }).unwrap();

      let signatureData = signMsgHash(
        initiateStakeRes?.payload?.msg_hash,
        initiateStakeRes?.payload?.nonce
      );

      let validateReqBody = {
        stake_deposit_uuid: initiateStakeRes?.payload?.stake_deposit_uuid,
        signature: signatureData.signature,
        msg_hash: initiateStakeRes?.payload?.msg_hash,
        frontend_timestamp: Date.now(),
        frontend_apy: getAPYByCurrency(selectedToken?.symbol?.toUpperCase()),
      };

      let _ = await validateDepositAndStake({
        token: userInfo?.payload?.tokens?.access,
        data: validateReqBody,
      }).unwrap();

      let processReqBody = {
        stake_deposit_uuid: initiateStakeRes?.payload?.stake_deposit_uuid,
        deposit_blockchain_hash: smDepositRes.deposit_blockchain_hash,
        deposit_blockchain_nonce: smDepositRes.deposit_blockchain_nonce,
        stake_deposit_uuid: initiateStakeRes?.payload?.stake_deposit_uuid,
        stakeDepositAmount: String(stakeInputValue),
        created_at: new Date().toISOString(),
        uuid: initiateStakeRes?.payload?.stake_deposit_uuid,
        network: 'ETHEREUM',
        state: 'INITIATED',
        currency: selectedToken?.symbol,
      };

      if (Number(transferredAmount) !== Number(stakeInputValue)) {
        Sentry.captureMessage(
          `The requested amount and the executed amount do not match—requested amount: [${stakeInputValue}], executed amount: [${transferredAmount}]`
        );
      }

      return processReqBody;
    } catch (error) {
      throw error?.data?.message || error?.error || error;
    }
  };

  const performProcessDepositAndStake = async (processReqBody) => {
    try {
      let _ = await processDepositAndStake({
        token: userInfo?.payload?.tokens?.access,
        data: processReqBody,
      }).unwrap();

      return processReqBody;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const performStakeAndDeposit = async (
    selectedToken,
    txDetails,
    stakeInputValue
  ) => {
    try {
      sendTrackEvent('depositAndStakeRequestInitiate', {
        currency: selectedToken?.symbol,
        amount: stakeInputValue,
        network: 'ETHEREUM',
      });
      let initiateStakeRes = await initiateDepositAndStake({
        token: userInfo?.payload?.tokens?.access,
        data: {
          currency: selectedToken?.symbol,
          amount: stakeInputValue,
          network: 'ETHEREUM',
        },
      }).unwrap();

      let signatureData = signMsgHash(
        initiateStakeRes?.payload?.msg_hash,
        initiateStakeRes?.payload?.nonce
      );

      let validateReqBody = {
        stake_deposit_uuid: initiateStakeRes?.payload?.stake_deposit_uuid,
        signature: signatureData.signature,
        msg_hash: initiateStakeRes?.payload?.msg_hash,
        frontend_timestamp: Date.now(),
        frontend_apy: getAPYByCurrency(selectedToken?.symbol?.toUpperCase()),
      };

      let _ = await validateDepositAndStake({
        token: userInfo?.payload?.tokens?.access,
        data: validateReqBody,
      }).unwrap();

      let processReqBody = {
        stake_deposit_uuid: initiateStakeRes?.payload?.stake_deposit_uuid,
        deposit_blockchain_hash: txDetails.deposit_blockchain_hash,
        deposit_blockchain_nonce: txDetails.deposit_blockchain_nonce,
      };

      let processStakeRes = await processDepositAndStake({
        token: userInfo?.payload?.tokens?.access,
        data: processReqBody,
      }).unwrap();
      sendTrackEvent('depositAndStakeRequestSuccess', {
        currency: selectedToken?.symbol,
        amount: stakeInputValue,
        network: 'ETHEREUM',
      });
      return processStakeRes;
    } catch (error) {
      sendTrackEvent('depositAndStakeRequestFailure', {
        currency: selectedToken?.symbol,
        amount: stakeInputValue,
        network: 'ETHEREUM',
        error: error?.data?.message || error?.error,
      });
      throw error?.data?.message || error?.error;
    }
  };

  const performUnStake = async (selectedToken, amount) => {
    try {
      sendTrackEvent('unstakeRequestInitiate', {
        amount: amount,
        asset: selectedToken?.symbol,
      });
      let initiateUnStakeRes = await initiateUnStake({
        token: userInfo?.payload?.tokens?.access,
        data: {
          derived_currency: selectedToken?.symbol,
        },
      }).unwrap();
      let signatureData = signMsgHash(
        initiateUnStakeRes?.payload?.msg_hash,
        initiateUnStakeRes?.payload?.nonce
      );

      let processReqBody = {
        user_unstake_uuid: initiateUnStakeRes?.payload?.user_unstake_uuid,
        signature: signatureData.signature,
        msg_hash: initiateUnStakeRes?.payload?.msg_hash,
        frontend_timestamp: Date.now(),
      };

      let processUnStakeRes = await processUnStake({
        token: userInfo?.payload?.tokens?.access,
        data: processReqBody,
      }).unwrap();

      if (processUnStakeRes) {
        sendTrackEvent('unstakeRequestSuccess', {
          amount: amount,
          asset: selectedToken?.symbol,
        });
      }

      return processUnStakeRes;
    } catch (error) {
      sendTrackEvent('unstakeRequestFailed', {
        amount: amount,
        asset: selectedToken?.symbol,
        error: error?.data?.message || error.error,
      });
      throw error?.data?.message || error?.error;
    }
  };

  const startInitiatelL1Withdrawal = async (amount, token_id, hash) => {
    try {
      let initiateNormalL1WithdrawalRes = await initiateNormalL1Withdrawal({
        token: userInfo?.payload?.tokens?.access,
        data: {
          amount: amount,
          token_id: token_id,
          l1_withdrawal_blockchain_hash: hash,
        },
      }).unwrap();
      return initiateNormalL1WithdrawalRes;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const processClaimYield = async (currency) => {
    try {
      let processClaimYieldRes = await claimYield({
        token: userInfo?.payload?.tokens?.access,
        data: {
          currency,
        },
      }).unwrap();
      return processClaimYieldRes;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  return {
    getAPYStats,
    // lastPrice,
    handleGetUserMultipliBalance,
    processSmartContractDeposit,
    checkAllowance,
    handleApproveAllowance,
    performStake,
    startNormalWithdrawal,
    performUnStake,
    startInitiatelL1Withdrawal,
    performStakeAndDeposit,
    processClaimYield,
    handleDepositEstimatedGasFees,
    handleAllowanceApprovalGasFees,
    initiateDepositWithValidation,
    performProcessDepositAndStake,
  };
}

export default useStake;
