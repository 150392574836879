import React from 'react';
import { images } from '../../utils/images';

function LinkText({ text, link }) {
  return (
    <span className='link_text'>
      <a href='#'>Learn more</a>
      <img src={images.arrowIcon} />
    </span>
  );
}

export default LinkText;
