import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../utils/constants';
import { logout } from './authSlice';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/react';

const unauthorizedErrorMessage =
  'Your session has expired. Kindly log in again to continue.';

const networkErrorMessage = 'Poor network connection, please try again later.';

const customBackoff = (attempt) => {
  const delay = Math.min(1000 * 2 ** attempt, 30000);
  return new Promise((resolve) => setTimeout(resolve, delay));
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
});

const baseQueryWithSessionHandler = async (args, api, extraOptions) => {
  let authorizationHeader = args.headers?.Authorization;

  if (authorizationHeader) {
    try {
      const token = authorizationHeader.split(' ')[1]; // Extract the token
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      const tenMinutesInMilliseconds = 10 * 60 * 1000;

      if (expirationTime - Date.now() < tenMinutesInMilliseconds) {
        api.dispatch(logout());
        api.dispatch({ type: 'WEBSOCKET_DISCONNECT' });

        return {
          error: unauthorizedErrorMessage,
        };
      }
    } catch (error) {
      api.dispatch(logout());
      api.dispatch({ type: 'WEBSOCKET_DISCONNECT' });

      return {
        error: unauthorizedErrorMessage,
        // error: 'Invalid token format or error in decoding.',
      };
    }
  }

  try {
    const result = await baseQuery(args, api, extraOptions);

    if (result.data) {
      return result;
    }

    if (result.error) {
      const errorMsg =
        result.error?.data?.message || result.error?.error || 'Unknown error';
      Sentry.captureMessage(`[${args?.url}]-${errorMsg}`, {
        level: 'error',
        extra: {
          url: args?.url,
          method: args?.method,
          status: result.error?.status,
          ...result,
        },
      });

      if (result.error?.status === 'FETCH_ERROR') {
        result.error.error = networkErrorMessage;
      }

      return result;
    }
  } catch (error) {
    Sentry.captureException(error);
    return {
      error: 'An unexpected error occurred.',
    };
  }
};

const staggeredBaseQueryWithSessionHandler = retry(
  baseQueryWithSessionHandler,
  {
    backoff: customBackoff,
    maxRetries: 3,
    retryCondition: (error, _, { baseQueryApi, attempt }) => {
      return (
        baseQueryApi.type === 'query' &&
        attempt < 3 &&
        error !== unauthorizedErrorMessage
      );
    },
  }
);

export const apiSlice = createApi({
  baseQuery: staggeredBaseQueryWithSessionHandler,
  tagTypes: [
    'AppMetaData',
    'CoinMetaData',
    'MarketMetaData',
    'UserBalanceData',
    'UserDepositVaultIdData',
    'UnstakeHistory',
    'YieldHistory',
    'DepositHistory',
    'WithdrawalsHistory',
    'stakeHistory',
    'yieldClaimHistory',
    'Orbs',
    'ReferralCode',
    'OrbsLeaderboard',
    'PlatformStatsData',
    'PublicMarketMetaData',
  ],
  endpoints: (builder) => ({}),
});
