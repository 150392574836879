import React, { useState } from 'react';
import Modal from '../common/modal';
import toast from 'react-hot-toast';
import Button from '../common/button';
import { checkEmail } from '../../utils/string-methods';
import useFeedback from '../../utils/hooks/useFeedback';
import { Tooltip } from 'react-tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useInfoPopup from '../../utils/hooks/useInfoPopup';
import FeedbackImageUploader from './feedback-image-uploader';

const FeedbackPopup = ({ isOpen, closeModal }) => {
  const { sendFeedbackData, isSendFeedbackLoading } = useFeedback();
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const { showPopup } = useInfoPopup();

  const startFeedBackUploading = () => {
    return toast.loading('Uploading your feedback image(s)...', {
      closeOnClick: false,
      draggable: false,
    });
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');

    document.execCommand('insertText', false, pastedData);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    let uploadingToastId;

    if (email.length && !checkEmail(email)) {
      return toast.error('Please enter a valid email.');
    }

    selectedImages.forEach((image) => {
      formData.append('images', image);
    });

    formData.append('feedback', `${feedback}-multipli`);
    formData.append('email', email);

    if (feedback.length == 0) {
      return toast.error('Share your feedback here!');
    }

    try {
      if (selectedImages.length) {
        uploadingToastId = startFeedBackUploading();
        closeModal();
      }
      const _ = await sendFeedbackData(formData);
      if (selectedImages.length) {
        toast.dismiss(uploadingToastId);
        toast.success(
          'Sent successfully!, Thank you! Your feedback is appreciated.'
        );
      } else {
        showPopup(
          'Feedback Received',
          `Thank you! Your feedback is appreciated.`
        );
      }
      setSelectedImages([]);
      setEmail('');
      setFeedback('');
      document.getElementById('fileInput').value = '';
      closeModal();
    } catch (error) {
      toast.error(error);
      if (uploadingToastId) {
        toast.dismiss(uploadingToastId);
      }
    }
  };

  return (
    <Modal
      state={isOpen}
      setState={closeModal}
      onClose={closeModal}
      modalClassName='login-modal'
    >
      <div className='login-modal-container'>
        <h3 className='text-medium'>Send us Feedback</h3>
        <p className='mt-3 text-secondary'>
          Please provide us with your feedback to help us improve.
        </p>
        <div className='feedback-container'>
          <label>
            Email
            <span
              className='feedback-email-icon'
              data-tooltip-content={
                'This data is being collected purely for communication regarding product feedback; it will not be stored or used for other purposes.'
              }
              data-tooltip-id='feedback-email-icon'
            >
              <AiOutlineInfoCircle size={12} />
              <Tooltip
                place='bottom'
                className='tooltip-styles feedback-email-details'
                id={'feedback-email-icon'}
              />{' '}
            </span>
          </label>
          <input
            type='email'
            name='email'
            placeholder='brinerd@gmail.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onPaste={handlePaste}
          />
          <br />
          <label>Tell us how we can improve!</label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            onPaste={handlePaste}
          />
          <FeedbackImageUploader
            openFeedBackPopup={isOpen}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />
          <Button
            className='btn btn-block mt-4 btn-primary'
            onClick={handleSubmit}
            isLoading={isSendFeedbackLoading}
            disabled={isSendFeedbackLoading}
          >
            Send Feedback
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackPopup;
