// useWebSocket.js
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMessage } from '../../slices/websocketSlice';
import { wscacheKey } from '../constants';
import useUserAuthentication from './useUserAuthentication';

const useWebSocket = () => {
  const dispatch = useDispatch();
  // const userInfo = useSelector((state) => state.auth.userInfo);
  // const isLoggedIn = userInfo?.payload?.tokens?.access ? true : false;
  const { isUserLoggedIn } = useUserAuthentication();
  const websocketStatus = useSelector((state) => state.websocket.status);
  const websocketMessage = useSelector((state) => state.websocket.message);
  const hasConnected = useRef(false);

  useEffect(() => {
    if (isUserLoggedIn && !hasConnected.current) {
      dispatch({ type: 'WEBSOCKET_CONNECT' });
      hasConnected.current = true;
    } else {
      hasConnected.current = false;
    }
  }, [isUserLoggedIn, dispatch]);

  useEffect(() => {
    const savedMessage = localStorage.getItem(wscacheKey);
    if (savedMessage) {
      const parsedMessage = JSON.parse(savedMessage);
      Object.keys(parsedMessage).forEach((key) => {
        if (parsedMessage[key]) {
          dispatch(setMessage({ type: key, data: parsedMessage[key] }));
        }
      });
    }
  }, [dispatch]);

  return { websocketStatus, websocketMessage };
};

export default useWebSocket;
