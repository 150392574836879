import React, { useCallback, useEffect, useState, useRef } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { ENVIRONMENT, ENABLE_SEGMENT, analyticsKey } from '../constants';
import { useWalletInfo, useWeb3ModalAccount } from '@web3modal/ethers/react';
import useRoute from './useRoute';

let analyticsInstance = null;
let trackEventBuffer = [];
let pageEventBuffer = [];

async function initializeAnalytics() {
  if (!analyticsInstance) {
    [analyticsInstance] = await AnalyticsBrowser.load({
      writeKey: analyticsKey,
    });
  }
  return analyticsInstance;
}

function dispatchBufferedEvents(analytics) {
  if (trackEventBuffer.length > 0) {
    trackEventBuffer.forEach(({ eventName, body }) => {
      analytics.track(eventName, { ...body });
    });
    trackEventBuffer = [];
  }

  if (pageEventBuffer.length > 0) {
    pageEventBuffer.forEach(({ eventName, body }) => {
      analytics.page(eventName, { ...body });
    });
    pageEventBuffer = [];
  }
}

function useSegment() {
  const [analytics, setAnalytics] = useState(null);
  const { address } = useWeb3ModalAccount();
  const { walletInfo } = useWalletInfo();
  const { getQueryParams } = useRoute();
  const dispatchTimer = useRef(null);

  useEffect(() => {
    if (ENVIRONMENT === 'mainnet' && ENABLE_SEGMENT) {
      const loadAnalytics = async () => {
        const instance = await initializeAnalytics();
        setAnalytics(instance);
      };
      loadAnalytics();
    }
  }, []);

  useEffect(() => {
    if (analytics) {
      dispatchTimer.current = setInterval(() => {
        dispatchBufferedEvents(analytics);
      }, 5000);

      window.addEventListener('beforeunload', () => {
        dispatchBufferedEvents(analytics);
      });

      return () => {
        clearInterval(dispatchTimer.current);
        window.removeEventListener('beforeunload', () => {
          dispatchBufferedEvents(analytics);
        });
      };
    }
  }, [analytics]);

  const sendTrackEvent = useCallback((eventName, body = {}) => {
    if (ENABLE_SEGMENT && ENVIRONMENT === 'mainnet') {
      let queryParams = getQueryParams();
      if (Object.keys(queryParams).includes('')) {
        queryParams = null;
      }
      body['route'] = window?.location?.pathname;
      if (queryParams) {
        body['queryParams'] = queryParams;
      }
      if (address && !body['address']) {
        body['address'] = address;
      }
      if (walletInfo?.name && !body['walletType']) {
        body['walletType'] = walletInfo?.name;
      }
      trackEventBuffer.push({ eventName, body });
    }
  }, []);

  const sendPageEvent = useCallback((eventName, body = {}) => {
    if (ENABLE_SEGMENT && ENVIRONMENT === 'mainnet') {
      let queryParams = getQueryParams();
      if (Object.keys(queryParams).includes('')) {
        queryParams = null;
      }
      body['route'] = window?.location?.pathname;
      if (queryParams) {
        body['queryParams'] = queryParams;
      }
      if (address && !body['address']) {
        body['address'] = address;
      }
      if (!body['walletType']) {
        body['walletType'] = walletInfo?.name;
      }

      pageEventBuffer.push({ eventName, body });
    }
  }, []);

  return { sendPageEvent, sendTrackEvent, analytics };
}

export default useSegment;
