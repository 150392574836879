import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import authSlice from './slices/authSlice';
import loginModalSlice from './slices/loginModalSlice';
import infoModalSlice from './slices/infoModalSlice';
import websocketReducer from './slices/websocketSlice';
import websocketMiddleware from './middleware/websocketMiddleware';
import transactionReducer from './slices/transactionSlice';
import { chartApiSlice } from './slices/chartSlice';
import { publicStatsSlice } from './slices/publicStatsSlice';


const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [chartApiSlice.reducerPath]: chartApiSlice.reducer,
    [publicStatsSlice.reducerPath]: publicStatsSlice.reducer,
    auth: authSlice,
    loginModal: loginModalSlice,
    infoModal: infoModalSlice,
    auth: authSlice,
    websocket: websocketReducer,
    transaction: transactionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      chartApiSlice.middleware,
      publicStatsSlice.middleware,
      websocketMiddleware
    ),
  devTools: true,
});

export default store;
