export const truncate = (value, length) => {
  if (!value || typeof value !== 'string' || !length) {
    return '';
  }

  if (value?.length <= length) {
    return value;
  }

  const separator = '...';
  const stringLength = length - separator.length;
  const frontLength = Math.ceil(stringLength / 2);
  const backLength = Math.floor(stringLength / 2);

  return (
    value.substring(0, frontLength) +
    separator +
    value.substring(value.length - backLength)
  );
};

export function remove0x0Prefix(originalString) {
  if (originalString.startsWith('0x0')) {
    return '0x' + originalString.slice(3);
  } else {
    return originalString;
  }
}

export function removeHexPrefix(str, removeOx0 = false) {
  // Use a regular expression to remove "0x0" and "0x" from the beginning of the string
  if (removeOx0) {
    return str.replace(/^(0x0|0x)/, '');
  } else {
    return str.replace(/^0x/, '');
  }
}

/**
 * Convert the given string from X[dynamic] to x[dynamic]
 * @param {string} input - The input string to convert
 * @returns {string} - The converted string
 */
export function convertXCoin(input) {
  // if (typeof input !== 'string') {
  //   throw new TypeError('Input must be a string');
  // }
  if (input?.length) {
    if (input.startsWith('X') && input.length > 1) {
      return 'x' + input.slice(1);
    }
  }

  return input;
}

/**
 * Capitalize the first letter of the given string.
 * @param {string} input - The input string to convert.
 * @returns {string} - The converted string with the first letter capitalized.
 */
export function capitalizeFirstLetter(line) {
  if (line.length === 0) return line; // Return the line as is if it's empty
  return line.charAt(0).toUpperCase() + line.slice(1); // Capitalize the first letter
}

export function replaceUnderscoreAndHyphen(input) {
  if (input?.length) {
    return input.replace(/[_-]/g, ' ');
  }
}

export function checkEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}
