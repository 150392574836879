import React from 'react';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';

function StakeSwitch({
  onSwitch,
  selectedOption,
  options,
  userSelectedTokenBalance,
}) {
  const { isUserLoggedIn } = useUserAuthentication();
  return (
    <div className='flex flex-row gap-15 stake-switch'>
      {options.map((option) => (
        <p
          className={`text-l text-link text-medium ${
            selectedOption?.value === option?.value ? 'text-active' : ''
          } ${
            isUserLoggedIn && userSelectedTokenBalance == null ? 'disabled' : ''
          }`}
          key={option.value}
          onClick={() => onSwitch(option)}
        >
          {option.label}
        </p>
      ))}
    </div>
  );
}

export default StakeSwitch;
