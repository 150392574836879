import { useState } from 'react';
import {
  useGetChartDataMutation,
  useGetTokensYieldChartDataMutation,
} from '../../slices/chartSlice';

const useChartData = () => {
  const [trigger] = useGetChartDataMutation();
  const [triggerYieldChartDataFetch] = useGetTokensYieldChartDataMutation();
  const [updatedAt, setUpdatedAt] = useState(null);
  const [updatedYieldChartAt, setUpdatedYieldChartAt] = useState(null);

  const fetchData = async (params = null) => {
    try {
      const data = await trigger({ data: params }).unwrap();
      setUpdatedAt(data.fetchTime);
      return {
        historicalCexPerpsYieldsData: data.historicalCexPerpsYieldsData,
        averagePerpYield: data.averagePerpYield?.length
          ? [data.averagePerpYield]
          : [],
        pvNGraph: data.pvNGraph,
        currentYieldRates: data.currentYieldRates,
      };
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const fetchTokensYieldData = async (params = null) => {
    try {
      const data = await triggerYieldChartDataFetch({
        data: { periods: { ...params } },
      }).unwrap();
      setUpdatedYieldChartAt(data.fetchTime);
      return {
        wBTC: data?.wBTC,
        USDC: normalizeData(data?.USDC),
        USDT: normalizeData(data?.USDT),
        xUSDC: data?.xUSDC,
        xUSDT: data?.xUSDT,
      };
    } catch (error) {
      console.log({ error });
      throw error?.data?.message || error?.error;
    }
  };

  // Data normalization function
  // const normalizeData = (tokenData) => {
  //   const timestamps = []; // Store all unique timestamps

  //   // Collect all timestamps from all datasets
  //   tokenData.forEach((dataset) => {
  //     dataset.data.forEach((point) => {
  //       if (!timestamps.includes(point.timestamp)) {
  //         timestamps.push(point.timestamp);
  //       }
  //     });
  //   });

  //   // Sort timestamps (optional, but recommended)
  //   timestamps.sort();

  //   // // Normalize datasets
  //   const normalizedData = tokenData.map((dataset) => {
  //     const normalizedDataset = {
  //       ...dataset,
  //       data: timestamps.map((timestamp) => {
  //         const dataPoint = dataset.data.find(
  //           (point) => point.timestamp === timestamp
  //         );
  //         return dataPoint || { timestamp, value: null }; // Insert null if no data for this timestamp
  //       }),
  //     };
  //     return normalizedDataset;
  //   });

  //   return normalizedData;
  // };

  // const normalizeData = (tokenData) => {
  //   const timestamps = []; // Store all unique timestamps

  //   // Collect all timestamps from all datasets
  //   tokenData.forEach((dataset) => {
  //     dataset.data.forEach((point) => {
  //       if (!timestamps.includes(point.timestamp)) {
  //         timestamps.push(point.timestamp);
  //       }
  //     });
  //   });

  //   // Sort timestamps (optional, but recommended)
  //   timestamps.sort();

  //   // Function to fill missing data with 0
  //   const fillMissingData = (data, allTimestamps) => {
  //     return allTimestamps.map((timestamp) => {
  //       const existingDataPoint = data.find((d) => d.timestamp === timestamp);
  //       return existingDataPoint ? existingDataPoint : { value: 0, timestamp };
  //     });
  //   };

  //   // Normalize datasets and fill missing data
  //   const normalizedData = tokenData.map((dataset) => {
  //     const filledData = fillMissingData(dataset.data, timestamps);
  //     return {
  //       ...dataset,
  //       data: filledData,
  //     };
  //   });

  //   return normalizedData;
  // };

  // const normalizeData = (tokenData) => {
  //   if (tokenData?.length) {
  //     const timestamps = []; // Store all unique timestamps

  //     // Collect all timestamps from all datasets
  //     tokenData.forEach((dataset) => {
  //       dataset.data.forEach((point) => {
  //         if (!timestamps.includes(point.timestamp)) {
  //           timestamps.push(point.timestamp);
  //         }
  //       });
  //     });

  //     // Sort timestamps (optional, but recommended)
  //     timestamps.sort();

  //     // Get the earliest timestamp across all datasets
  //     const earliestTimestamp = timestamps[0];

  //     // Normalize datasets with a filler for the starting point
  //     const normalizedData = tokenData.map((dataset) => {
  //       // Fill missing data at the start
  //       if (dataset.data[0].timestamp > earliestTimestamp) {
  //         console.log({ dataset: dataset.data, earliestTimestamp });
  //         dataset?.data.unshift({ timestamp: earliestTimestamp, value: 0 });
  //       }

  //       const normalizedDataset = {
  //         ...dataset,
  //         data: timestamps.map((timestamp) => {
  //           const dataPoint = dataset.data.find(
  //             (point) => point.timestamp === timestamp
  //           );
  //           return dataPoint || { timestamp, value: null }; // Insert null if no data for this timestamp
  //         }),
  //       };
  //       return normalizedDataset;
  //     });

  //     return normalizedData;
  //   }
  // };

  const normalizeData = (tokenData) => {
    if (tokenData?.length) {
      const timestamps = []; // Store all unique timestamps

      // Collect all timestamps from all datasets
      tokenData.forEach((dataset) => {
        dataset.data.forEach((point) => {
          if (!timestamps.includes(point.timestamp)) {
            timestamps.push(point.timestamp);
          }
        });
      });

      // Sort timestamps (optional, but recommended)
      timestamps.sort();

      // Get the earliest timestamp across all datasets
      const earliestTimestamp = timestamps[0];

      // Normalize datasets with a filler for the starting point
      const normalizedData = tokenData.map((dataset) => {
        // Create a new dataset with modified data
        const datasetData = [...dataset.data];

        // Fill missing data at the start
        if (datasetData[0]?.timestamp > earliestTimestamp) {
          datasetData.unshift({ timestamp: earliestTimestamp, value: 0 });
        }

        const normalizedDataset = {
          ...dataset,
          data: timestamps.map((timestamp) => {
            const dataPoint = datasetData.find(
              (point) => point.timestamp === timestamp
            );
            return dataPoint || { timestamp, value: null }; // Insert null if no data for this timestamp
          }),
        };
        return normalizedDataset;
      });

      return normalizedData;
    }
  };

  return {
    fetchData,
    updatedAt,
    fetchTokensYieldData,
    updatedYieldChartAt,
  };
};

export default useChartData;
