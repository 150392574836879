import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { ChartTooltip, PieChartLegends, PieChartLabel } from '../charts/utils';

import useViewport from '../../utils/hooks/useViewPort';

function PieCharts({ chartData, chartStyle }) {
  const { width } = useViewport();
  let points = width > 550 ? 1.5 : 0.1;

  return (
    <ResponsiveContainer height={300} {...chartStyle}>
      <PieChart>
        <Pie
          data={chartData}
          innerRadius={60}
          outerRadius={100}
          paddingAngle={2}
          dataKey='value'
          label={<PieChartLabel points={points} />}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              stroke={entry.borderColor}
            />
          ))}
        </Pie>
        <Tooltip
          content={
            <ChartTooltip
              valueKey='days'
              labelType='label'
              addClassName='pie-chart-label'
            />
          }
        />
        <Legend align='center' content={<PieChartLegends />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default PieCharts;
