import React, { useEffect } from 'react';
import FallbackWrapper from '../../components/fallback-wrapper/fallback-wrapper';
import { useNavigate } from 'react-router-dom';
import { useGetHealthStatusMutation } from '../../slices/appSlice';

function IPFallback() {
  const navigate = useNavigate();
  const [getHealthStatus] = useGetHealthStatusMutation();

  const checkHealthStatus = async () => {
    try {
      const response = await getHealthStatus().unwrap();
      navigate('/');
    } catch (error) {}
  };

  const handleButtonClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    checkHealthStatus();
  }, []);

  return (
    <FallbackWrapper
      title={'Oops! Something went wrong!'}
      handleButtonClick={handleButtonClick}
      buttonLabel={'Reload'}
    >
      <div className='fallback-text-div'>
        <p className='mt-3'>
          Unfortunately, due to legal restrictions, Multipli's interface is
          currently unavailable to users in the United States, Canada, North
          Korea, Iran, Cuba, Syria, Myanmar, Crimea, Donetsk, or Luhans. We are
          working to resolve this issue and hope to make Multipli accessible to
          all users soon.
        </p>
      </div>
    </FallbackWrapper>
  );
}

export default IPFallback;
