import React from 'react';
import { useLazyYieldInfoQuery } from '../../slices/historySlice';
import { useSelector } from 'react-redux';
import { useLazyGetUserMultipliBalanceQuery } from '../../slices/userSlice';
import { allowedStakeCoins, allowedUnStakeCoins } from '../constants';
import useUserAuthentication from './useUserAuthentication';
import useAppStats from './useAppStats';
import useNumber from './useNumber';
import { convertXCoin } from '../string-methods';

function useHoldings() {
  const [yieldInfoData, { isError: yieldInfoDataError }] =
    useLazyYieldInfoQuery();
  const [fetchUserBalance, { isError: fetchUserBalanceError }] =
    useLazyGetUserMultipliBalanceQuery();
  const { userInfo } = useSelector((state) => state.auth);
  const { isUserLoggedIn } = useUserAuthentication();
  const { getInfoAboutCoins } = useAppStats();
  const { toFixedTrunc } = useNumber();

  const populateYieldData = (yieldData, userBalance) => {
    const yieldKeys = Object.keys(yieldData);
    return yieldKeys.map((key) => {
      const balanceData = userBalance.payload.find((item) => {
        return item.currency === `x${key}`;
      });
      return {
        currency: `x${key.toUpperCase()}`,
        symbol: key,
        amount: balanceData ? balanceData.balance : '0.0',
        yield: yieldData[key] || '0.0',
        logo: getInfoAboutCoins(`x${key}`)?.logo,
      };
    });
  };

  const populateUnStakeData = (userBalance) => {
    return userBalance.payload
      .filter((item) => allowedStakeCoins.includes(item.currency.toLowerCase()))
      .map((item) => ({
        currency: `${convertXCoin(item.currency.toUpperCase())}`,
        amount: item.balance,
        symbol: item.currency,
        yield: '--',
        missed_yield: toFixedTrunc(item.balance * 0.18, 3) || '0.00',
        logo: getInfoAboutCoins(`${item.currency}`)?.logo,
      }));
  };

  const getStakedHoldingList = async () => {
    try {
      let yieldData = await yieldInfoData({
        token: userInfo?.payload?.tokens?.access,
      }).unwrap();
      let userBalance = await fetchUserBalance(
        userInfo?.payload?.tokens?.access
      ).unwrap();
      let result = populateYieldData(yieldData?.payload, userBalance);
      return result;
    } catch (error) {
      throw error?.data?.message || error?.error || error;
    }
  };

  const getUnstakedHoldingList = async () => {
    try {
      let userBalance = await fetchUserBalance(
        userInfo?.payload?.tokens?.access
      ).unwrap();
      let result = populateUnStakeData(userBalance);
      return result;
    } catch (error) {
      throw error?.data?.message || error?.error || error;
    }
  };

  const getHoldingPlaceHolderData = (type) => {
    if (type === 'stake') {
      return allowedUnStakeCoins.map((item) => {
        return {
          currency: `${convertXCoin(item.toUpperCase())}`,
          amount: '--',
          yield: '--',
          missed_yield: '--',
          symbol: item,
          logo: getInfoAboutCoins(item)?.logo,
        };
      });
    } else if (type === 'unstake') {
      if (!isUserLoggedIn || fetchUserBalanceError || yieldInfoDataError) {
        return allowedStakeCoins.map((item) => {
          return {
            currency: `${convertXCoin(item.toUpperCase())}`,
            amount: '--',
            yield: '--',
            missed_yield: '--',
            symbol: item,
            logo: getInfoAboutCoins(`${item}`)?.logo,
          };
        });
      }
    }
  };

  return {
    getStakedHoldingList,
    getUnstakedHoldingList,
    getHoldingPlaceHolderData,
    yieldInfoDataError,
    fetchUserBalanceError,
  };
}

export default useHoldings;
