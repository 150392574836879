import { useState } from 'react';
import Button from '../common/button';
import DataLoader from '../common/loader';
import NotFound from '../common/not-found';
import Table from '../common/table';
import HeadingContainer from '../heading-container/heading-container';
import useStake from '../../utils/hooks/useStake';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setModalState } from '../../slices/loginModalSlice';
import useSegment from '../../utils/hooks/useSegment';
import ConnectWalletBtn from '../common/connect-wallet-btn';

const stakedHoldings = {
  headers: [
    {
      logo: null,
      heading: null,
      data: 'Asset',
    },
    {
      logo: null,
      heading: null,
      data: 'Value',
    },
    {
      logo: null,
      heading: null,
      data: 'Yield',
    },
    {
      logo: null,
      heading: null,
      data: 'Claim Yield',
    },
  ],
};

export function StakeHoldings({
  isHoldingFetchOperationLoading,
  stakedCoinsList,
  handleGetHoldingsData,
  triggerHistoryFetch,
  setTriggerHistoryFetch,
}) {
  const { displayTextOrPlaceholder } = useUserAuthentication();
  const [yieldClaimProcessingCurrency, setYieldClaimProcessingCurrency] =
    useState(null);
  const { processClaimYield } = useStake();
  const { isUserLoggedIn } = useUserAuthentication();
  const { sendTrackEvent } = useSegment();
  const dispatch = useDispatch();

  const handleClaimYield = async (symbol) => {
    setYieldClaimProcessingCurrency(symbol);
    sendTrackEvent('yieldClaimInitiated', {
      token: symbol,
    });
    try {
      let _ = await processClaimYield(symbol);
      setTimeout(() => {
        handleGetHoldingsData();
        setTriggerHistoryFetch(true);
      }, 500);
      setTimeout(() => {
        setTriggerHistoryFetch(false);
      }, 0);
      toast.success('Yield Claimed Successfully');
      setYieldClaimProcessingCurrency(null);
      sendTrackEvent('yieldClaimSuccess', {
        token: symbol,
      });
    } catch (error) {
      sendTrackEvent('yieldClaimFailed', {
        token: symbol,
        error: error,
      });
      setYieldClaimProcessingCurrency(null);
      toast.error(error);
    }
  };

  return (
    <div className='mt-4'>
      <HeadingContainer
        title={'Multipli Holdings (Staked)'} //
        subHeading={'View your staked assets.'}
        statusIndicator={false}
      />
      <div className='holding-table-container'>
        <Table>
          <Table.Header>
            {stakedHoldings?.headers?.map((item, index) => {
              return (
                <Table.Entry key={index}>
                  <span className='table-data-text'>{item?.data}</span>
                </Table.Entry>
              );
            })}
          </Table.Header>
          <Table.Body>
            <>
              {isHoldingFetchOperationLoading ? (
                <div className='mt-4 mb-4'>
                  <DataLoader msg='Fetching staked holdings' />
                </div>
              ) : (
                <>
                  {stakedCoinsList === 0 ? (
                    <div className='mt-5'>
                      <NotFound msg={'No data found'} />
                    </div>
                  ) : (
                    <>
                      {stakedCoinsList?.map((stakeCoin, idx) => {
                        return (
                          <Table.Row key={idx}>
                            <Table.Entry>
                              <Table.EntryLogo
                                src={stakeCoin?.logo}
                                alt=''
                              ></Table.EntryLogo>
                              {stakeCoin?.currency}
                            </Table.Entry>
                            <Table.Entry>
                              {displayTextOrPlaceholder(stakeCoin?.amount)}
                            </Table.Entry>
                            <Table.Entry addClassName='unstake-status'>
                              {displayTextOrPlaceholder(stakeCoin.yield)}
                            </Table.Entry>
                            <Table.Entry addClassName='unstake-status'>
                              {isUserLoggedIn ? (
                                <Button
                                  isLoading={
                                    yieldClaimProcessingCurrency ===
                                    stakeCoin.symbol
                                  }
                                  className='btn btn-primary btn-small w-100px'
                                  onClick={() =>
                                    handleClaimYield(stakeCoin.symbol)
                                  }
                                  disabled={
                                    yieldClaimProcessingCurrency?.length > 0 ||
                                    stakeCoin.yield == 0
                                  }
                                >
                                  Claim
                                </Button>
                              ) : (
                                <ConnectWalletBtn
                                  className='btn btn-primary btn-small w-200px'
                                  eventSource={'claimYield'}
                                />
                              )}
                            </Table.Entry>
                          </Table.Row>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </>
          </Table.Body>
        </Table>
      </div>
      {/* <CustodiansContainer data={custodiansData} /> */}
    </div>
  );
}
