import React, { useState, useEffect } from 'react';
import TabNav from '../../components/common/tab-nav';
import HeadingContainer from '../../components/heading-container/heading-container';
import InfoTable from '../../components/info-table/info-table';
import CustodiansContainer from '../../components/custodians-container/custodians-container';
import { images } from '../../utils/images';
import DashboardChart from '../../components/dashboard/dashboard-chart';
// chart data
// import { historicalCexPerpsYieldsData } from '../../utils/chart-data/hedging/yield-on-cex-perp';
// import { historicalDexPerpsYieldsData } from '../../utils/chart-data/hedging/yield-on-dex-perp';
// import { currentCexPerps } from '../../utils/chart-data/hedging/current-cex-perp';
// import { averagePerpYield } from '../../utils/chart-data/hedging/averge-perp-yield';
// import { currentDexPerps } from '../../utils/chart-data/hedging/current-dex-perp';
// import { perpYieldDayCounts } from '../../utils/chart-data/hedging/perp-yield-day-counts';
// import { xBTCSupply } from '../../utils/chart-data/supply/xbtc-supply';
// import { xUSDCSupply } from '../../utils/chart-data/supply/xusdc-supply';
// import { xUSDTSupply } from '../../utils/chart-data/supply/xusdt-supply';
// import { xBTCYields } from '../../utils/chart-data/yields/xbtc-yield';
// import { xUSDCAndUSDTYields } from '../../utils/chart-data/yields/xusdc-and-xusdt-yield';
// import { yieldsComparison } from '../../utils/chart-data/yields/yield-comparison';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useSegment from '../../utils/hooks/useSegment';
import useChart from '../../utils/hooks/useChart';
import FallBackLoader from '../../components/common/fallback-loader';
import moment from 'moment';
import { dashboardParam } from '../../utils/constants';
import useRoute from '../../utils/hooks/useRoute';
import { useGetPublicStatsQuery } from '../../slices/publicStatsSlice';
import useDashboard from '../../utils/hooks/useDashboard';

const initalHedgingChartState = {
  perp_yield_period: 'weekly',
  avg_perp_period: 'weekly',
  pvn_period: 'weekly',
  token: 'BTC',
};

// yield filter
const yieldInitalChartState = {
  wBTC: 'monthly',
  USDT: 'monthly',
  USDC: 'monthly',
  xUSDC: 'monthly',
  xUSDT: 'monthly',
};

function Dashboard() {
  const solvencyData = {
    headers: [
      {
        logo: null,
        heading: null,
        data: 'Asset',
        accessor: 'asset',
      },
      {
        logo: images.binanceLogo,
        heading: null,
        data: '51%',
        accessor: 'binanceValue',
      },
      {
        logo: images.okxLogo,
        heading: null,
        data: '45%',
        accessor: 'okxValue',
      },
      {
        logo: null,
        heading: 'Unallocated',
        data: '4%',
        accessor: 'unallocatedValue',
      },
    ],

    body: [
      {
        asset: { logo: images.bitcoinLogo, token: 'BTC' },
        binanceValue: '21.54Mn',
        okxValue: '18.34Mn',
        unallocatedValue: '0',
      },
      {
        asset: { logo: images.tetherLogo, token: 'USDT' },
        binanceValue: '12.78Mn',
        okxValue: '9.78Mn',
        unallocatedValue: '6,789.54',
      },
      {
        asset: { logo: images.usdcLogo, token: 'USDC' },
        binanceValue: '9.58Mn',
        okxValue: '7.28Mn',
        unallocatedValue: '3,456.32',
      },
    ],
  };

  // const allocationData = {
  //   headers: [
  //     {
  //       logo: null,
  //       heading: null,
  //       data: 'Asset',
  //       accessor: 'asset',
  //     },
  //     {
  //       logo: images.binanceLogo,
  //       heading: null,
  //       data: '51%',
  //       accessor: 'binanceValue',
  //     },
  //     {
  //       logo: images.okxLogo,
  //       heading: null,
  //       data: '45%',
  //       accessor: 'okxValue',
  //     },
  //     {
  //       logo: null,
  //       heading: 'Unallocated',
  //       data: '4%',
  //       accessor: 'unallocatedValue',
  //     },
  //   ],

  //   body: [
  //     {
  //       asset: { logo: images.bitcoinLogo, token: 'BTC' },
  //       binanceValue: '21.54Mn',
  //       okxValue: '18.34Mn',
  //       unallocatedValue: '0',
  //     },
  //     {
  //       asset: { logo: images.tetherLogo, token: 'USDT' },
  //       binanceValue: '12.78Mn',
  //       okxValue: '9.78Mn',
  //       unallocatedValue: '6,789.54',
  //     },
  //     {
  //       asset: { logo: images.usdcLogo, token: 'USDC' },
  //       binanceValue: '9.58Mn',
  //       okxValue: '7.28Mn',
  //       unallocatedValue: '3,456.32',
  //     },
  //   ],
  // };

  const yieldData = {
    headers: [
      {
        logo: null,
        heading: null,
        data: 'Asset',
        accessor: 'asset',
      },
      {
        logo: null,
        heading: null,
        data: 'Value Locked',
        accessor: 'valueLocked',
      },
      {
        logo: null,
        heading: null,
        data: 'APY',
        accessor: 'apy',
      },
      {
        logo: null,
        heading: null,
        data: 'Yield Generated (Over 90 days)',
        accessor: 'yieldGen',
      },
    ],

    body: [
      {
        asset: { logo: images.bitcoinLogo, token: 'BTC' },
        valueLocked: '12.54Mn',
        apy: '18.34%',
        yieldGen: '2.45Mn',
      },
      {
        asset: { logo: images.tetherLogo, token: 'USDT' },
        valueLocked: '14.56Mn',
        apy: '20.09%',
        yieldGen: '2.92Mn',
      },
      {
        asset: { logo: images.usdcLogo, token: 'USDC' },
        valueLocked: '10.09Mn',
        apy: '25.98%',
        yieldGen: '2.54Mn',
      },
    ],
  };

  const [hedgingChartParams, setHedgingChartParams] = useState(
    initalHedgingChartState
  );
  const [yieldChartParams, setYieldChartParams] = useState(
    yieldInitalChartState
  );
  const [tableData, setTableData] = useState(solvencyData);
  const [currentTab, setCurrentTab] = useState('Allocation');
  const [selectedHedge, setSelectedHedge] = useState('BTC Hedging');
  const navigate = useNavigate();
  const location = useLocation();
  const [query] = useSearchParams();
  const { sendTrackEvent } = useSegment();
  const {
    updatedAt: chartUpdatedAt,
    fetchData: fetchChartData,
    fetchTokensYieldData,
  } = useChart({
    hedgingChartParams,
    yieldChartParams,
  });
  const [historicalCexPerpsYieldsData, setHistoricalCexPerpsYieldsData] =
    useState([]);
  const [averagePerpYield, setAveragePerpYield] = useState({});
  const [pvNGraph, setPvNGraph] = useState([]);
  const [currentYieldRates, setCurrentYieldRates] = useState([]);
  const [tokensYield, setTokensYield] = useState([]);
  const [isHedgingChartLoading, setHedgingChartLoading] = useState(false);
  const [isYieldChartLoading, setYieldChartLoading] = useState(false);
  const { getQueryParamsString } = useRoute();
  const {
    data: publicStatsData,
    isLoading: isPublicStatsDataLoading,
    isError: isPublicStatsDataError,
  } = useGetPublicStatsQuery();
  const { convertToAllocationData } = useDashboard();

  const tabMap = {
    Allocation: {},
    Hedging: yieldData,
    // Supply: solvencyData,
    Yields: [],
  };

  const custodiansData = [
    { logo: images.cefuLogo, address: 'Coming Soon' },
    {
      logo: images.copperLogo,
      address: 'Coming Soon',
    },
  ];

  const handleHedgingGetChartData = async (token = null) => {
    setHedgingChartLoading(true);
    try {
      let data = await fetchChartData({
        ...hedgingChartParams,
        token:
          token?.replace(' Hedging', '') ||
          selectedHedge?.replace(' Hedging', ''),
      });
      setAveragePerpYield(data.averagePerpYield);
      setHistoricalCexPerpsYieldsData(data.historicalCexPerpsYieldsData);
      setPvNGraph(data.pvNGraph);
      setCurrentYieldRates(data.currentYieldRates);
      setHedgingChartLoading(false);
    } catch (error) {
      setHedgingChartLoading(false);
    }
  };

  const handleYieldGetChartData = async () => {
    setYieldChartLoading(true);
    try {
      let data = await fetchTokensYieldData({
        ...yieldChartParams,
      });

      setTokensYield(data);
      setYieldChartLoading(false);
    } catch (error) {
      setYieldChartLoading(false);
    }
  };

  const resetChartState = (option) => {
    let token = option?.replace(' Hedging', '');
    setHistoricalCexPerpsYieldsData([]);
    setAveragePerpYield({});
    setPvNGraph([]);
    setCurrentYieldRates([]);
    setHedgingChartParams({ ...initalHedgingChartState, token });
    setYieldChartParams({ ...yieldInitalChartState });
  };

  useEffect(() => {
    handleHedgingGetChartData();
  }, [hedgingChartParams]);

  useEffect(() => {
    handleYieldGetChartData();
  }, [yieldChartParams]);

  // useEffect(() => {
  //   setTableData(tabMap[currentTab]);
  // }, [currentTab]);

  const handleChangeDashboardTab = (data) => {
    sendTrackEvent('analyticsTabChanged', {
      tab: data,
    });
    const searchParamsString = getQueryParamsString({
      [dashboardParam]: data,
    });
    navigate(`/dashboard?${searchParamsString}`);
  };

  useEffect(() => {
    if (location.search) {
      let currenctTab = query.get(dashboardParam);
      let tabList = Object.keys(tabMap);
      if (tabList.includes(currenctTab)) {
        setCurrentTab(currenctTab);
      }
    }
  }, [location]);

  const handleHedgingAssetChange = (option) => {
    resetChartState(option);
    handleHedgingGetChartData(option);
    trackHedgingEvent(option);
  };

  const trackHedgingEvent = (newTab) => {
    sendTrackEvent('hedgingAssetToggled', {
      asset: newTab?.replace(' Hedging', ''),
    });
  };

  const handleChartTimelineChange = (key, value) => {
    const updatedParams = {
      ...hedgingChartParams,
      [key]: value,
    };
    setHedgingChartParams(updatedParams);
  };

  const handleYieldChartTimelineChange = (key, value) => {
    const updatedParams = {
      ...yieldChartParams,
      [key]: value,
    };
    setYieldChartParams(updatedParams);
  };

  return (
    <div className='page-main'>
      <div className='dashboard'>
        <div className='dashboard-header'>
          <TabNav
            data={Object.keys(tabMap)}
            setCurrentTab={handleChangeDashboardTab}
            currentTab={currentTab}
            addContainerClassName='tablet-hide'
          />
          {currentTab === 'Allocation' && (
            <HeadingContainer
              title={'Asset Bifurcation'}
              subHeading={'Updated: 6 mins ago'}
              statusIndicator={true}
            />
          )}
        </div>
        {currentTab === 'Allocation' ? (
          <div className='mt-4'>
            <HeadingContainer
              title={'Collateral'}
              subHeading={`Last Updated: ${moment()
                .subtract(1, 'days')
                .format('llll')}`}
              statusIndicator={false}
            />
          </div>
        ) : currentTab === 'Hedging' ? (
          <div className='mt-4 hedging-tab-container'>
            <TabNav
              data={['BTC Hedging', 'ETH Hedging']}
              setCurrentTab={setSelectedHedge}
              onChange={handleHedgingAssetChange}
              currentTab={selectedHedge}
            />
          </div>
        ) : null}
        {currentTab === 'Allocation' && (
          <>
            {isPublicStatsDataLoading || isPublicStatsDataError ? (
              <FallBackLoader />
            ) : (
              <>
                <div className='info-table-container'>
                  <InfoTable data={convertToAllocationData(publicStatsData)} />
                </div>
                <CustodiansContainer data={custodiansData} />
              </>
            )}
          </>
        )}

        {currentTab === 'Hedging' ? (
          <>
            {isHedgingChartLoading &&
            historicalCexPerpsYieldsData.length === 0 ? (
              <FallBackLoader />
            ) : (
              <div className='mt-4'>
                <HeadingContainer
                  title={`${selectedHedge?.replace(
                    ' Hedging',
                    ''
                  )} Perpetual Swaps`}
                  subHeading={
                    chartUpdatedAt
                      ? `Last Updated: ${moment(chartUpdatedAt).format('llll')}`
                      : null
                  }
                  statusIndicator={false}
                />
                {historicalCexPerpsYieldsData.length ? (
                  <div className='mt-4'>
                    <DashboardChart
                      data={historicalCexPerpsYieldsData}
                      title='Yield on Funding Fees'
                      handleChartTimelineChange={handleChartTimelineChange}
                      chartKey='perp_yield_period'
                    />
                  </div>
                ) : null}
                {/* <div className='mt-5'>
           <DashboardChart
             data={historicalDexPerpsYieldsData}
             title='Yield on DEX Perps'
             handleChartTimelineChange={handleChartTimelineChange}
             chartKey='perp_yield_period'
           />
         </div> */}
                {averagePerpYield?.length ? (
                  <div className='mt-5'>
                    <DashboardChart
                      data={averagePerpYield}
                      title='Average Perp Yield'
                      handleChartTimelineChange={handleChartTimelineChange}
                      chartKey='avg_perp_period'
                    />
                  </div>
                ) : null}
                {currentYieldRates?.length ? (
                  <div className='mt-5'>
                    <DashboardChart
                      data={currentYieldRates}
                      title='Current Yield Rates'
                      disableFilter={true}
                      type='barChart'
                    />
                  </div>
                ) : null}
                {/* <div className='mt-5'>
           <DashboardChart
             data={currentDexPerps}
             title='Current DEX Perp Yield'
             type='barChart'
             handleChartTimelineChange={handleChartTimelineChange}
           />
         </div> */}
                {pvNGraph?.length ? (
                  <div className='mt-5 mb-5'>
                    <DashboardChart
                      data={pvNGraph}
                      title='Perp Yield Day Counts'
                      type='pieChart'
                      handleChartTimelineChange={handleChartTimelineChange}
                      chartKey='pvn_period'
                    />
                  </div>
                ) : null}
              </div>
            )}
          </>
        ) : null}

        {/* {currentTab === 'Supply' ? (
          <div>
            <div className='mt-4 flex flex-row w-100 justify-between supply-container'>
              <DashboardChart data={xBTCSupply} title='xBTC Supply' />
              <DashboardChart data={xUSDCSupply} title='xUSDC Supply' />
            </div>
            <div className='mt-4 flex flex-row w-50 justify-between supply-container mb-4'>
              <DashboardChart data={xUSDTSupply} title='xUSDT Supply' />
            </div>
          </div>
        ) : null} */}

        {currentTab === 'Yields' ? (
          <>
            {isYieldChartLoading && tokensYield?.length === 0 ? (
              <FallBackLoader />
            ) : (
              <div>
                <div className='mt-4 flex flex-row w-100 justify-between supply-container'>
                  {tokensYield?.USDC ? (
                    <DashboardChart
                      data={tokensYield?.USDC}
                      title='USDC Yield'
                      handleChartTimelineChange={handleYieldChartTimelineChange}
                      chartKey='USDC'
                      filterOptions={['max', 'monthly', 'yearly']}
                      selectedFilterOption='monthly'
                    />
                  ) : null}
                  {tokensYield?.USDT ? (
                    <DashboardChart
                      data={tokensYield?.USDT}
                      title='USDT Yield'
                      handleChartTimelineChange={handleYieldChartTimelineChange}
                      chartKey='USDT'
                      filterOptions={['max', 'monthly', 'yearly']}
                      selectedFilterOption='monthly'
                    />
                  ) : null}

                  {/* <DashboardChart data={tokensYield} title='xBTC Yield' /> */}
                  {/* <DashboardChart
                data={xUSDCAndUSDTYields}
                title='xUSDC and xUSDT Yield'
              />  */}
                </div>

                <div className='mt-4 flex flex-row w-100 justify-between supply-container mb-4'>
                  {tokensYield?.wBTC ? (
                    <DashboardChart
                      data={tokensYield?.wBTC}
                      title='wBTC Yield'
                      handleChartTimelineChange={handleYieldChartTimelineChange}
                      chartKey='wBTC'
                      filterOptions={['max', 'monthly', 'yearly']}
                      selectedFilterOption='monthly'
                    />
                  ) : null}
                  {tokensYield?.xUSDC ? (
                    <DashboardChart
                      data={tokensYield?.xUSDC}
                      title='xUSDC Yield'
                      handleChartTimelineChange={handleYieldChartTimelineChange}
                      chartKey='xUSDC'
                      filterOptions={['max', 'monthly', 'yearly']}
                      selectedFilterOption='monthly'
                    />
                  ) : null}
                </div>

                <div className='mt-4 flex flex-row w-50 justify-between supply-container mb-4'>
                  {tokensYield?.xUSDT ? (
                    <DashboardChart
                      data={tokensYield?.xUSDT}
                      title='XUSDT Yield'
                      handleChartTimelineChange={handleYieldChartTimelineChange}
                      chartKey='xUSDT'
                      filterOptions={['max', 'monthly', 'yearly']}
                      selectedFilterOption='monthly'
                    />
                  ) : null}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Dashboard;
