/**
 *  BigNumber
 *
 *  A wrapper around the BN.js object. We use the BN.js library
 *  because it is used by elliptic, so it is required regardless.
 *
 */

import BN from 'bn.js';
import { hexlify, isBytes, isHexString } from '@ethersproject/bytes';

const _constructorGuard = {};

const MAX_SAFE = 0x1fffffffffffff;

function isBigNumberish(value) {
  return (
    value != null &&
    (BigNumber.isBigNumber(value) ||
      (typeof value === 'number' && value % 1 === 0) ||
      (typeof value === 'string' && !!value.match(/^-?[0-9]+$/)) ||
      isHexString(value) ||
      typeof value === 'bigint' ||
      isBytes(value))
  );
}

// Only warn about passing 10 into radix once
let _warnedToStringRadix = false;

class BigNumber {
  constructor(constructorGuard, hex) {
    if (constructorGuard !== _constructorGuard) {
      throw new Error('cannot call constructor directly; use BigNumber.from');
    }

    this._hex = hex;
    this._isBigNumber = true;

    Object.freeze(this);
  }

  fromTwos(value) {
    return toBigNumber(toBN(this).fromTwos(value));
  }

  toTwos(value) {
    return toBigNumber(toBN(this).toTwos(value));
  }

  abs() {
    if (this._hex[0] === '-') {
      return BigNumber.from(this._hex.substring(1));
    }
    return this;
  }

  add(other) {
    return toBigNumber(toBN(this).add(toBN(other)));
  }

  sub(other) {
    return toBigNumber(toBN(this).sub(toBN(other)));
  }

  div(other) {
    const o = BigNumber.from(other);
    if (o.isZero()) {
      throw new Error('division by zero');
    }
    return toBigNumber(toBN(this).div(toBN(other)));
  }

  mul(other) {
    return toBigNumber(toBN(this).mul(toBN(other)));
  }

  mod(other) {
    const value = toBN(other);
    if (value.isNeg()) {
      throw new Error('cannot modulo negative values');
    }
    return toBigNumber(toBN(this).umod(value));
  }

  pow(other) {
    const value = toBN(other);
    if (value.isNeg()) {
      throw new Error('cannot raise to negative values');
    }
    return toBigNumber(toBN(this).pow(value));
  }

  and(other) {
    const value = toBN(other);
    if (this.isNegative() || value.isNeg()) {
      throw new Error("cannot 'and' negative values");
    }
    return toBigNumber(toBN(this).and(value));
  }

  or(other) {
    const value = toBN(other);
    if (this.isNegative() || value.isNeg()) {
      throw new Error("cannot 'or' negative values");
    }
    return toBigNumber(toBN(this).or(value));
  }

  xor(other) {
    const value = toBN(other);
    if (this.isNegative() || value.isNeg()) {
      throw new Error("cannot 'xor' negative values");
    }
    return toBigNumber(toBN(this).xor(value));
  }

  mask(value) {
    if (this.isNegative() || value < 0) {
      throw new Error('cannot mask negative values');
    }
    return toBigNumber(toBN(this).maskn(value));
  }

  shl(value) {
    if (this.isNegative() || value < 0) {
      throw new Error('cannot shift negative values');
    }
    return toBigNumber(toBN(this).shln(value));
  }

  shr(value) {
    if (this.isNegative() || value < 0) {
      throw new Error('cannot shift negative values');
    }
    return toBigNumber(toBN(this).shrn(value));
  }

  eq(other) {
    return toBN(this).eq(toBN(other));
  }

  lt(other) {
    return toBN(this).lt(toBN(other));
  }

  lte(other) {
    return toBN(this).lte(toBN(other));
  }

  gt(other) {
    return toBN(this).gt(toBN(other));
  }

  gte(other) {
    return toBN(this).gte(toBN(other));
  }

  isNegative() {
    return this._hex[0] === '-';
  }

  isZero() {
    return toBN(this).isZero();
  }

  toNumber() {
    try {
      return toBN(this).toNumber();
    } catch (error) {
      throw new Error('overflow');
    }
  }

  toBigInt() {
    try {
      return BigInt(this.toString());
    } catch (e) {}

    throw new Error('this platform does not support BigInt');
  }

  toString() {
    // Lots of people expect this, which we do not support, so check (See: #889)
    if (arguments.length > 0) {
      if (arguments[0] === 10) {
        if (!_warnedToStringRadix) {
          _warnedToStringRadix = true;
          console.warn(
            'BigNumber.toString does not accept any parameters; base-10 is assumed'
          );
        }
      } else if (arguments[0] === 16) {
        throw new Error(
          'BigNumber.toString does not accept any parameters; use bigNumber.toHexString()'
        );
      } else {
        throw new Error('BigNumber.toString does not accept parameters');
      }
    }
    return toBN(this).toString(10);
  }

  toHexString() {
    return this._hex;
  }

  toJSON(key) {
    return { type: 'BigNumber', hex: this.toHexString() };
  }

  static from(value) {
    if (value instanceof BigNumber) {
      return value;
    }

    if (typeof value === 'string') {
      if (value.match(/^-?0x[0-9a-f]+$/i)) {
        return new BigNumber(_constructorGuard, toHex(value));
      }

      if (value.match(/^-?[0-9]+$/)) {
        return new BigNumber(_constructorGuard, toHex(new BN(value)));
      }

      throw new Error('invalid BigNumber string');
    }

    if (typeof value === 'number') {
      if (value % 1) {
        throw new Error('underflow');
      }

      if (value >= MAX_SAFE || value <= -MAX_SAFE) {
        throw new Error('overflow');
      }

      return BigNumber.from(String(value));
    }

    const anyValue = value;

    if (typeof anyValue === 'bigint') {
      return BigNumber.from(anyValue.toString());
    }

    if (isBytes(anyValue)) {
      return BigNumber.from(hexlify(anyValue));
    }

    if (anyValue) {
      // Hexable interface (takes priority)
      if (anyValue.toHexString) {
        const hex = anyValue.toHexString();
        if (typeof hex === 'string') {
          return BigNumber.from(hex);
        }
      } else {
        // For now, handle legacy JSON-ified values (goes away in v6)
        let hex = anyValue._hex;

        // New-form JSON
        if (hex == null && anyValue.type === 'BigNumber') {
          hex = anyValue.hex;
        }

        if (typeof hex === 'string') {
          if (
            isHexString(hex) ||
            (hex[0] === '-' && isHexString(hex.substring(1)))
          ) {
            return BigNumber.from(hex);
          }
        }
      }
    }

    throw new Error('invalid BigNumber value');
  }

  static isBigNumber(value) {
    return !!(value && value._isBigNumber);
  }
}

// Normalize the hex string
function toHex(value) {
  // For BN, call on the hex string
  if (typeof value !== 'string') {
    return toHex(value.toString(16));
  }

  // If negative, prepend the negative sign to the normalized positive value
  if (value[0] === '-') {
    // Strip off the negative sign
    value = value.substring(1);

    // Cannot have multiple negative signs (e.g. "--0x04")
    if (value[0] === '-') {
      throw new Error('invalid hex');
    }

    // Call toHex on the positive component
    value = toHex(value);

    // Do not allow "-0x00"
    if (value === '0x00') {
      return value;
    }

    // Negate the value
    return '-' + value;
  }

  // Add a "0x" prefix if missing
  if (value.substring(0, 2) !== '0x') {
    value = '0x' + value;
  }

  // Normalize zero
  if (value === '0x') {
    return '0x00';
  }

  // Make the string even length
  if (value.length % 2) {
    value = '0x0' + value.substring(2);
  }

  // Trim to smallest even-length string
  while (value.length > 4 && value.substring(0, 4) === '0x00') {
    value = '0x' + value.substring(4);
  }

  return value;
}

function toBigNumber(value) {
  return BigNumber.from(toHex(value));
}

function toBN(value) {
  const hex = BigNumber.from(value).toHexString();
  if (hex[0] === '-') {
    return new BN('-' + hex.substring(3), 16);
  }
  return new BN(hex.substring(2), 16);
}

function throwFault(fault, operation, value) {
  const params = { fault: fault, operation: operation };
  if (value != null) {
    params.value = value;
  }

  throw new Error(fault);
}

// value should have no prefix
function _base36To16(value) {
  return new BN(value, 36).toString(16);
}

// value should have no prefix
function _base16To36(value) {
  return new BN(value, 16).toString(36);
}

export default {
  BigNumber,
  isBigNumberish,
  _base36To16,
  _base16To36,
};
