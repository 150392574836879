import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { rpcUrl, rpcUrls } from './constants';

// 1. Get projectId
const projectId = import.meta.env.VITE_APP_WC_KEY;
const appEnv = import.meta.env.VITE_APP_ENVIRONMENT;

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

const sepolia = {
  chainId: 11155111,
  name: 'Sepolia Testnet',
  currency: 'SepoliaETH',
  explorerUrl: 'https://sepolia.etherscan.io',
  rpcUrl: rpcUrls['testnet'],
};

// 3. Create modal metadata
const metadata = {
  name: 'Multipli',
  description: 'MAKE YIELD ON ANY ASSET',
  url: 'https://www.multipli.fi/',
  icons: ['https://avatars.mywebsite.com/'],
};

let testnetList = [sepolia];
let mainnetList = [mainnet];

// 4. Create initialization function
export const initializeWeb3Modal = () => {
  return createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: appEnv === 'mainnet' ? mainnetList : testnetList,
    projectId,
    allowUnsupportedChain: true,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    termsConditionsUrl: 'https://docs.multipli.fi/legal/terms-of-use',
    privacyPolicyUrl: 'https://docs.multipli.fi/legal/privacy-policy',
  });
};
