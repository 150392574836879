import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
};

const loginModalState = createSlice({
  name: 'loginModalState',
  initialState,
  reducers: {
    setModalState: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
});

export const { setModalState } = loginModalState.actions;

export default loginModalState.reducer;
