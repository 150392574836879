import React from 'react';

function StakeInputRow({
  placeholder,
  valueLabel,
  amount,
  ...otherInputProps
}) {
  return (
    <div className='flex flex-row justify-between items-baseline mt-4'>
      <div className='flex flex-row gap-10 items-baseline'>
        <input
          className='stake-input'
          placeholder={placeholder}
          // onInput={(e) => {
          //   e.target.style.height = 'auto';
          //   e.target.style.height = `${e.target.scrollHeight}px`;
          // }}
          {...otherInputProps}
        />
      </div>
      {valueLabel && (
        <div>
          <h6 className='text-m text-medium text-active'>{valueLabel}</h6>
        </div>
      )}
    </div>
  );
}

export default StakeInputRow;
