import React from 'react';
import Modal from './modal';
import LottieWrapper from './lottie-wrapper';
import doneAnimationData from '../../assets/json/done.json';
import { useDispatch, useSelector } from 'react-redux';
import { setModalState } from '../../slices/infoModalSlice';

function InfoPopup() {
  const { isModalOpen, title, description, link, linkText, type } = useSelector(
    (state) => state.infoModal
  );
  const dispatch = useDispatch();

  return (
    <Modal
      state={isModalOpen}
      setState={() => dispatch(setModalState({ isModalOpen: false }))}
      modalClassName='login-modal info-modal'
      showConfetti={true}
    >
      <div className='login-modal-container flex flex-column items-center align-center'>
        <h3 className='text-medium'>{title}</h3>
        {/* <FcApproval size={100} className='mr-1' /> */}
        <LottieWrapper
          animationData={doneAnimationData}
          width={100}
          height={100}
        />
        <p className='mt-3 text-secondary'>{description}</p>
        {linkText && (
          <a href={link} target='_blank' rel='noopener noreferrer'>
            {linkText}
          </a>
        )}
      </div>
    </Modal>
  );
}

export default InfoPopup;
