import React, { useState, useEffect } from 'react';
import Button from '../common/button';
import Dropdown, { DropdownItem } from '../common/dropdown';
import StakeInputRow from './stake-input-row';
import StakeSwitch from './stake-switch';
import InfoSection from './info-container';
import useDebouncedEffect from '../../utils/hooks/useDebouncedEffect';
import ConnectWalletBtn from '../common/connect-wallet-btn';
import { useSwitchNetwork, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useGetCoinStatsQuery } from '../../slices/appSlice';
import toast from 'react-hot-toast';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useNumber from '../../utils/hooks/useNumber';
import useWallet from '../../utils/hooks/useWallet';
import { useDispatch, useSelector } from 'react-redux';
import UserBalance from './stake-user-balance';
import TransactionDetails from './stake-transaction-details-tooltip';
import useStake from '../../utils/hooks/useStake';
import {
  DEPOSIT_FEE_DECIMAL_POINT,
  NORMAL_WITHDRAWAL_CONFIRMATION_HOURS,
  stakeParam,
  supportedChainId,
} from '../../utils/constants';
import { UnstakeAmountInfoTooltip } from './unstake-amount-info-tooltip';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import useSegment from '../../utils/hooks/useSegment';
import {
  capitalizeFirstLetter,
  convertXCoin,
} from '../../utils/string-methods';
import Decimal from 'decimal.js';
import { useNavigate } from 'react-router-dom';
import useRoute from '../../utils/hooks/useRoute';
import { RiHistoryFill } from 'react-icons/ri';
import { Tooltip } from 'react-tooltip';
import { setLatestTransactionData } from '../../slices/transactionSlice';
import { clearTransactionData } from '../../slices/transactionSlice';
import TransactionStatusInfo from './transaction-status-Info';

// solana
// optimisum
// tron
// eth

// const networkOptions = [
//   {
//     logo: 'https://cdn.icon-icons.com/icons2/2429/PNG/512/ethereum_logo_icon_147293.png',
//     name: 'Ethereum',
//   },
//   {
//     logo: 'https://seeklogo.com/images/S/solana-sol-logo-12828AD23D-seeklogo.com.png',
//     name: 'Solana',
//   },
//   {
//     logo: 'https://images.seeklogo.com/logo-png/52/2/optimism-logo-png_seeklogo-527144.png',
//     name: 'Optimism',
//   },
//   {
//     logo: 'https://cdn-icons-png.flaticon.com/512/12114/12114250.png',
//     name: 'Tron',
//   },
// ];

// let allowanceRequestCount = 1;

const activityTabMap = {
  withdraw: 'withdrawals',
  stake: 'stakes',
  unstake: 'unstakes',
};

function StakeCard({
  setStakeInputValue,
  stakeInputValue,
  selectedToken,
  setSelectedToken,
  setIsRequestModalOpen,
  setRequestModalMeta,
  setCurrentStepIndex,
  setSteps,
  setIsInfoModalOpen,
  setSelectedFilterOption,
  selectedFilterOption,
  stakeOptions,
  showPopup,
  handleGetUnStakeHistory,
}) {
  const { data: coinData } = useGetCoinStatsQuery();
  const {
    handleGetUserMultipliBalance,
    processSmartContractDeposit,
    checkAllowance,
    handleApproveAllowance,
    performStake,
    performUnStake,
    startNormalWithdrawal,
    performStakeAndDeposit,
    handleDepositEstimatedGasFees,
    handleAllowanceApprovalGasFees,
    initiateDepositWithValidation,
    performProcessDepositAndStake,
  } = useStake();
  const [userSelectedTokenBalance, setUserSelectedTokenBalance] =
    useState(null);
  const [userNativeBalance, setUserNativeBalance] = useState(null);
  const [
    userSelectedTokenMultipliBalance,
    setUserSelectedTokenMultipliBalance,
  ] = useState(null);
  const [
    userSelectedTokenBlockChainBalance,
    setUserSelectedTokenBlockChainBalance,
  ] = useState(null);
  const [estimateGasFees, setEstimatedGasFees] = useState(null);
  const [alllowanceApprovalGasFees, setAllowanceApprovalGasFees] =
    useState(null);
  const [totalGasFees, setTotalGasFees] = useState(null);
  const [feeError, setFeeError] = useState(null);
  const {
    maxLengthCheck,
    toFixedTrunc,
    calculatePercentage,
    formatNumber,
    removeCommas,
  } = useNumber();
  const { isConnected, chainId } = useWeb3ModalAccount();
  const [stakeOperationLoading, setStakeOperationLoading] = useState(false);
  const [isWithdrawalOperationLoading, setIsWithdrawalOperationLoading] =
    useState(null);
  const { getUserBlockBalance } = useWallet();
  const { userInfo } = useSelector((state) => state.auth);
  const [isTransactionDetailsToolTipOpen, setIsTransactionDetailsToolTipOpen] =
    useState(false);
  const { isUserLoggedIn } = useUserAuthentication();
  const { sendTrackEvent } = useSegment();
  const navigate = useNavigate();
  const { getQueryParamsString } = useRoute();
  const { isValidNumber } = useNumber();
  const dispatch = useDispatch();
  const { message, status: wsConnectionStatus } = useSelector(
    (state) => state.websocket
  );

  const handleStakeOperationChange = (option) => {
    if (
      option?.value === selectedFilterOption?.value ||
      (isUserLoggedIn && userSelectedTokenBalance == null)
    ) {
      return;
    }

    const searchParamsString = getQueryParamsString({
      [stakeParam]: option.value,
    });
    navigate(`/?${searchParamsString}`);
    resetState(true);
    if (option.value) {
      sendTrackEvent(`stateTabClicked`, {
        tabName: option.value,
      });
    }
  };

  const { switchNetwork } = useSwitchNetwork();
  const [isSwitchNetworkLoading, setIsSwitchNetworkLoading] = useState(false);

  const handleTokenChange = (item) => {
    if (selectedToken?.symbol === item?.symbol) {
      return;
    }

    setSelectedToken(item);
    setStakeInputValue('');
    sendTrackEvent('assetDropdownToggled', {
      asset: item.symbol,
      tabName: selectedFilterOption?.value,
    });
  };

  const gasFeeCondition =
    selectedToken?.symbol &&
    isUserLoggedIn &&
    chainId == supportedChainId &&
    selectedFilterOption?.value === 'stake' &&
    Number(stakeInputValue) > Number(userSelectedTokenMultipliBalance);

  const handleUserNativeBalance = async (symbol = 'eth') => {
    try {
      const { balance: nativeBalance } = await getUserBlockBalance(symbol, '');
      setUserNativeBalance(nativeBalance);
    } catch (error) {
      toast.error(
        error ||
          'There is an issue with balance calculation, please try again later.',
        {
          id: 'balance-calculation-issue',
        }
      );
    }
  };

  const handleUserTotalBalance = async (symbol, tokenContract) => {
    try {
      let { balance: blockBalanceOfUser } = await getUserBlockBalance(
        symbol,
        tokenContract
      );
      let multipliBalanceOfUser = await handleGetUserMultipliBalance(
        selectedToken
      );

      setUserSelectedTokenBlockChainBalance(Number(blockBalanceOfUser));
      setUserSelectedTokenMultipliBalance(Number(multipliBalanceOfUser));

      setUserSelectedTokenBalance(
        Number(blockBalanceOfUser) + Number(multipliBalanceOfUser)
      );

      return {
        blockBalanceOfUser,
        multipliBalanceOfUser,
      };
    } catch (error) {
      toast.error(
        error ||
          'There is an issue with balance calculation, please try again later.',
        {
          id: 'balance-calculation-issue',
        }
      );
    }
  };

  const handlePercentage = (per) => {
    let amount =
      selectedFilterOption?.value === 'withdraw'
        ? userSelectedTokenMultipliBalance
        : userSelectedTokenBalance;
    let value = calculatePercentage(per, amount);
    sendTrackEvent('quantityBtnClicked', {
      mode: selectedFilterOption?.value,
      type: `${per}%`,
    });
    setStakeInputValue(toFixedTrunc(value, selectedToken?.quanitization));
  };

  const handleBalanceRefetch = async () => {
    if (selectedFilterOption?.value === 'unstake') {
      setStakeInputValue('');
    }
    resetBalanceState();
    let _ = await handleUserTotalBalance(
      selectedToken?.symbol,
      selectedToken?.token_contract
    );
  };

  const handleGetDepositEstimatedGasFees = async () => {
    try {
      let gasRes = await handleDepositEstimatedGasFees(selectedToken);
      setEstimatedGasFees(gasRes);
      setFeeError(null);
    } catch (error) {
      setFeeError(error);
      toast.error('Something went wrong while fetching the gas fees.');
    }
  };

  const handleGetApprovalAllowanceFees = async (requestedAmount) => {
    try {
      if (!requestedAmount) {
        setAllowanceApprovalGasFees('0');
        return;
      }

      let requestedAmountDec = new Decimal(requestedAmount);
      let userMultipliBalanceDec = new Decimal(
        userSelectedTokenMultipliBalance || 0
      );

      let remainingBalance = requestedAmountDec.minus(userMultipliBalanceDec);
      let allowanceFeesRes = await handleAllowanceApprovalGasFees(
        selectedToken,
        remainingBalance.toNumber()
      );
      setAllowanceApprovalGasFees(allowanceFeesRes);
      setFeeError(null);
    } catch (error) {
      setFeeError(error);
      toast.error('Something went wrong while calculating the gas fees');
    }
  };

  useDebouncedEffect(
    () => {
      handleGetApprovalAllowanceFees(stakeInputValue);
    },
    1000,
    gasFeeCondition,
    [
      stakeInputValue,
      selectedFilterOption?.value,
      userInfo?.payload?.tokens?.access,
      isConnected,
      selectedToken?.symbol,
      chainId,
    ]
  );

  const updateStep = (index, completed) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, idx) =>
        idx <= index
          ? { ...step, completed: idx === index ? completed : true }
          : step
      )
    );
    setCurrentStepIndex(index);
  };

  const handleNormalWithdrawal = async (selectedToken, amount) => {
    try {
      sendTrackEvent('withdrawRequestInitiate', {
        amount: amount,
        asset: selectedToken?.symbol,
      });
      setIsWithdrawalOperationLoading(true);
      let startNormalWithdrawalRes = await startNormalWithdrawal(
        selectedToken,
        amount
      );
      setIsWithdrawalOperationLoading(false);
      showPopup(
        'Withdrawal initiated',
        `Your withdrawal is now processing, please wait upto ${NORMAL_WITHDRAWAL_CONFIRMATION_HOURS} hours to redeem the withdrawal to your wallet`
      );
      setTimeout(() => {
        handleUserTotalBalance(
          selectedToken?.symbol,
          selectedToken?.token_contract
        );
      }, 1000);
      if (startNormalWithdrawalRes) {
        sendTrackEvent('withdrawRequestSuccess', {
          amount: amount,
          asset: selectedToken?.symbol,
        });
      }
      return startNormalWithdrawalRes;
    } catch (error) {
      sendTrackEvent('withdrawRequestFailed', {
        amount: amount,
        asset: selectedToken?.symbol,
        error: error,
      });
      setIsWithdrawalOperationLoading(false);
      toast.error(error);
    }
  };

  const handleConfirm = async () => {
    if (checkLoading()) {
      return;
    }

    if (!String(stakeInputValue).length && stakeInputValue !== 0) {
      return toast.error(
        selectedFilterOption?.value === 'unstake'
          ? 'You cannot unstake with zero balance'
          : 'Please enter an amount greater than zero'
      );
    }

    resetState();

    try {
      if (selectedFilterOption?.value === 'withdraw') {
        handleNormalWithdrawal(selectedToken, stakeInputValue);
      } else if (selectedFilterOption?.value === 'unstake') {
        await handleUnstake(selectedToken, stakeInputValue);
      } else {
        await handleStake(
          selectedToken,
          stakeInputValue,
          userSelectedTokenMultipliBalance
        );
      }
      setStakeOperationLoading(false);
    } catch (error) {
      setStakeOperationLoading(false);
      toast.error(error.message || error);
    }
  };

  const handleUnstake = async (token, amount) => {
    setStakeOperationLoading(true);
    try {
      let _ = await performUnStake(token, amount);
      showPopup(
        'Unstaking Initiated',
        'The unstaking process has been initiated. Your tokens will be unstaked soon.'
      );
      setTimeout(() => {
        handleBalanceRefetch();
        handleGetUnStakeHistory(true);
      }, 1500);
    } catch (e) {
      throw e;
    }
  };

  const handleStake = async (token, amount, balance) => {
    if (Number(balance) < Number(amount)) {
      handleBalanceAction(amount);
    } else {
      setStakeOperationLoading(true);
      try {
        let performStakeRes = await performStake(token, amount);
        showPopup(
          'Stake Initiated',
          'The staking process has been initiated. Your tokens will be staked soon.'
        );

        setTimeout(() => {
          handleUserTotalBalance(
            selectedToken?.symbol,
            selectedToken?.token_contract
          );
        }, 1000);

        dispatch(clearTransactionData());
        dispatch(
          setLatestTransactionData({ type: 'stake', data: performStakeRes })
        );
      } catch (e) {
        console.log({ e });
        dispatch(clearTransactionData());
        throw e;
      }
    }
  };

  const handleBalanceAction = async (requestedAmount) => {
    try {
      setStakeOperationLoading(true);
      setIsRequestModalOpen(true);
      // Step 1: Confirming with balance
      updateStep(0, true);

      let requestedAmountDec = new Decimal(requestedAmount);
      let userMultipliBalanceDec = new Decimal(
        userSelectedTokenMultipliBalance || 0
      );
      let remainingBalance = requestedAmountDec.minus(userMultipliBalanceDec);

      if (userMultipliBalanceDec.lessThan(requestedAmountDec)) {
        let userBlockchainBalanceDec = new Decimal(
          userSelectedTokenBlockChainBalance || 0
        );

        if (
          userBlockchainBalanceDec.greaterThan(0) &&
          userBlockchainBalanceDec.greaterThanOrEqualTo(remainingBalance)
        ) {
          let allowanceApproved = await checkAllowance(
            selectedToken,
            remainingBalance.toNumber()
          );
          // Step 2: Checking allowance
          updateStep(1, true);
          if (!allowanceApproved) {
            // showPopup(
            //   'Processing allowance',
            //   'Requesting allowance approval due to insufficient balance or low allowance'
            // );
            // Step 3: Requesting allowance
            updateStep(2, true);
            await handleApproveAllowance(selectedToken);
            allowanceApproved = await checkAllowance(
              selectedToken,
              remainingBalance.toNumber()
            );
          }

          if (allowanceApproved) {
            // Step 4: Processing deposit
            updateStep(3, true);
            // showPopup(
            //   'Processing Deposit',
            //   'Processing deposit due to insufficient balance in your Multipli account'
            // );
            // let { smDepositRes } = await processSmartContractDeposit(
            //   remainingBalance,
            //   selectedToken
            // );

            let processStakeDepositReqBody =
              await initiateDepositWithValidation(
                selectedToken,
                remainingBalance
              );

            // Periodically check updated Multipli balance until it's sufficient
            // const intervalId = setInterval(async () => {
            //   const multipliBalance = await handleGetUserMultipliBalance(selectedToken);
            // if (multipliBalance >= requestedAmount) {
            // Step 5: Performing stake function
            // showPopup(
            //   'Stake in Progress',
            //   'Staking is in progress after deposit.'
            // );
            updateStep(4, true);
            // await performStakeAndDeposit(
            //   selectedToken,
            //   smDepositRes,
            //   remainingBalance
            // );
            dispatch(clearTransactionData());
            let performStakeAndDepositRes = await performProcessDepositAndStake(
              processStakeDepositReqBody
            );

            let stakeDepositLocalState = {
              ...performStakeAndDepositRes,
              amount: String(remainingBalance),
            };

            let remainingStake = requestedAmountDec.minus(remainingBalance);

            if (remainingStake.greaterThan(0)) {
              let performStakeRes = await performStake(
                selectedToken,
                remainingStake.toNumber()
              );
              dispatch(
                setLatestTransactionData({
                  type: 'stake',
                  data: performStakeRes,
                })
              );
            }
            dispatch(
              setLatestTransactionData({
                type: 'stake_deposit',
                data: stakeDepositLocalState,
              })
            );

            setIsRequestModalOpen(false);
            // showPopup(
            //   'Stake Initiated',
            //   'The staking process has been initiated. Your tokens will be staked soon.'
            // );
            handleUserTotalBalance(
              selectedToken?.symbol,
              selectedToken?.token_contract
            );
            resetState(true);
            // }, 5000); // Adjust interval as needed
          } else {
            updateStep(2, false); // Allowance approval failed
            resetState(true);
            toast.error('Allowance approval failed. Please try again.');
            // showPopup(
            //   'Allowance Approval Failed',
            //   'Allowance approval failed. Please try again.'
            // );
            // Optionally handle this state, e.g., show an error message or disable action
          }
        } else {
          // Insufficient blockchain balance or balance is null, or insufficient for requested amount
          toast.error('Insufficient blockchain balance.');
          resetState(true);
          // Optionally handle this state, e.g., show an error message or disable action
        }
      } else {
        // Step 5: Performing stake function
        updateStep(4, true);
        // showPopup(
        //   'Stake in Progress',
        //   'Staking is in progress with sufficient Multipli balance.'
        // );
        await performStake(selectedToken, requestedAmount);
        // showPopup('Stake Completed', 'Staking process completed successfully.');
        showPopup(
          'Stake Completed',
          'The staking process has been completed. Your tokens have been staked successfully'
        );
        handleUserTotalBalance(
          selectedToken?.symbol,
          selectedToken?.token_contract
        );
        resetState(true);
      }
    } catch (error) {
      if (error?.includes('user rejected action')) {
        sendTrackEvent(`stakeRequestAbort`, {
          amount: stakeInputValue,
          asset: selectedToken?.symbol,
          error: error,
        });
      }
      // console.error('An error occurred:', error);
      toast.error(error);
      dispatch(clearTransactionData());
      resetState(true);
    }

    // finally {
    //   alert('finally execute');
    //   resetState();
    // }
  };

  const resetBalanceState = () => {
    setUserSelectedTokenBlockChainBalance(null);
    setUserSelectedTokenMultipliBalance(null);
    setUserSelectedTokenBalance(null);
  };

  const resetState = (isInputValueReset = false) => {
    setIsRequestModalOpen(false);
    setStakeOperationLoading(false);
    // Reset steps to initial state
    setCurrentStepIndex(0);
    setSteps((prevSteps) =>
      prevSteps.map((step) => ({ ...step, completed: false }))
    );
    if (isInputValueReset) {
      setStakeInputValue('');
    }
  };

  // const performStake = async () => {
  //   try {
  //     let initiateDepositRes = await initiateStake({
  //       token: userInfo?.payload?.tokens?.access,
  //       data: {
  //         currency: selectedToken?.symbol,
  //         amount: stakeInputValue,
  //       },
  //     }).unwrap();
  //     return initiateDepositRes;
  //   } catch (error) {
  //     throw error?.data?.message || error?.error;
  //   }
  // };

  const checkInsufficientBalance = () => {
    if (selectedFilterOption?.value === 'withdraw') {
      return Number(stakeInputValue) > Number(userSelectedTokenMultipliBalance);
    } else {
      return Number(stakeInputValue) > Number(userSelectedTokenBalance);
    }
  };

  const checkInsufficientGasFees = () => {
    if (selectedFilterOption?.value === 'stake') {
      return (
        Number(stakeInputValue) > Number(userSelectedTokenMultipliBalance) &&
        Number(estimateGasFees) > Number(userNativeBalance)
      );
    }
    return false;
  };

  const handleSwitchNetwork = async () => {
    if (chainId !== supportedChainId) {
      try {
        sendTrackEvent('switchNetworkInitiated', {
          currentChainId: chainId,
          newChainId: supportedChainId,
        });
        setIsSwitchNetworkLoading(true);
        await switchNetwork(Number(supportedChainId));
        setIsSwitchNetworkLoading(false);
        sendTrackEvent('switchNetworkSuccess', {
          currentChainId: chainId,
          newChainId: supportedChainId,
        });
      } catch (error) {
        const errorString = error?.shortMessage || error?.message;
        sendTrackEvent('switchNetworkFailure', {
          currentChainId: chainId,
          newChainId: supportedChainId,
          error: errorString,
        });
        toast.error('Something went wrong, please try again later.');
        setIsSwitchNetworkLoading(false);
      }
    }
  };

  const checkLoading = () => {
    if (gasFeeCondition) {
      return (
        stakeOperationLoading ||
        (totalGasFees == null && !feeError) ||
        userNativeBalance == null ||
        userSelectedTokenBalance == null
      );
    }

    return isWithdrawalOperationLoading || userSelectedTokenBalance == null;
  };

  const handleInputAutoFill = () => {
    if (selectedFilterOption?.value === 'stake') {
      if (userSelectedTokenBalance > 0) {
        // if (String(stakeInputValue)?.length === 0) {
        setStakeInputValue(
          toFixedTrunc(userSelectedTokenBalance, selectedToken?.quanitization)
        );
        // }
        // setTimeout(() => {
        //   console.log('trigger-1');
        //   setIsTransactionDetailsToolTipOpen(true);
        // }, 0);
      } else {
        setStakeInputValue('1000');
        // setIsTransactionDetailsToolTipOpen(false);
      }
    } else if (selectedFilterOption?.value === 'unstake') {
      setStakeInputValue(
        userSelectedTokenMultipliBalance > 0
          ? toFixedTrunc(
              userSelectedTokenMultipliBalance,
              selectedToken?.quanitization
            )
          : ''
      );
    }
  };

  const handleStakeValueChange = (e) => {
    if (userSelectedTokenBalance == null && isUserLoggedIn) return;

    const rawValue = removeCommas(e.target.value);
    setStakeInputValue(rawValue);
  };

  useEffect(() => {
    if (coinData?.payload) {
      if (selectedFilterOption?.value === 'unstake') {
        setSelectedToken(coinData?.unstakeCoinList[0]);
      } else {
        setSelectedToken(coinData?.stakeCoinList[0]);
      }
    }
  }, [coinData?.payload, selectedFilterOption?.value]);

  useEffect(() => {
    if (gasFeeCondition) {
      handleGetDepositEstimatedGasFees();
    }

    return () => {
      setEstimatedGasFees(null);
      setAllowanceApprovalGasFees(null);
      setTotalGasFees(null);
    };
  }, [
    gasFeeCondition,
    selectedToken?.symbol,
    isConnected,
    userInfo?.payload?.tokens?.access,
    selectedFilterOption?.value,
    chainId,
  ]);

  useEffect(() => {
    if (gasFeeCondition) {
      setTotalGasFees(null);
      setAllowanceApprovalGasFees(null);
    }
  }, [gasFeeCondition, stakeInputValue]);

  useEffect(() => {
    if (alllowanceApprovalGasFees !== null && estimateGasFees !== null) {
      setTotalGasFees(
        Number(alllowanceApprovalGasFees) + Number(estimateGasFees)
      );
    }
  }, [estimateGasFees, alllowanceApprovalGasFees]);

  useEffect(() => {
    let timer;
    if (totalGasFees == null && gasFeeCondition) {
      timer = setTimeout(() => {
        setFeeError('Timeout');
      }, 3000);
    }

    return () => {
      setFeeError(null);
      clearTimeout(timer);
    };
  }, [totalGasFees, gasFeeCondition]);

  useEffect(() => {
    if (selectedToken?.symbol && isUserLoggedIn) {
      handleBalanceRefetch();
      handleUserNativeBalance();
      return () => {
        setUserSelectedTokenBalance(null);
      };
    } else {
      if (!isUserLoggedIn) {
        if (selectedFilterOption.value === 'stake') {
          setStakeInputValue('1000');
        } else {
          setStakeInputValue('');
        }
      }
    }
  }, [selectedToken?.symbol, isUserLoggedIn]);

  useEffect(() => {
    if (selectedToken?.symbol && isUserLoggedIn && message?.stake_deposit) {
      const messageStakeDeposit = message?.stake_deposit;
      if (
        messageStakeDeposit?.currency?.toLowerCase() ===
        selectedToken?.symbol?.toLowerCase()
      ) {
        handleUserTotalBalance(
          selectedToken?.symbol,
          selectedToken?.token_contract
        );
        return () => {
          setUserSelectedTokenBalance(null);
        };
      }
    }
  }, [selectedToken?.symbol, isUserLoggedIn, message]);

  useEffect(() => {
    if (
      String(stakeInputValue)?.length &&
      isValidNumber(stakeInputValue) &&
      isValidNumber(userSelectedTokenBalance) &&
      selectedFilterOption?.value === 'stake' &&
      !checkInsufficientBalance()
    ) {
      setIsTransactionDetailsToolTipOpen(true);
    } else {
      setIsTransactionDetailsToolTipOpen(false);
    }
  }, [
    stakeInputValue,
    userSelectedTokenMultipliBalance,
    userSelectedTokenBlockChainBalance,
    userSelectedTokenBalance,
    selectedFilterOption,
  ]);

  useEffect(() => {
    if (
      (!isUserLoggedIn || isValidNumber(userSelectedTokenBalance)) &&
      selectedFilterOption?.value
    ) {
      handleInputAutoFill();
    }

    return () => {
      resetState(true);
    };
  }, [userSelectedTokenBalance, selectedFilterOption?.value, isUserLoggedIn]);

  // useEffect(() => {
  //   // Check if both stake_deposit objects are present and have the same UUID
  //   if (latestTransactionData?.stake_deposit && message?.stake_deposit) {
  //     const latestStakeDeposit = latestTransactionData.stake_deposit;
  //     const messageStakeDeposit = message.stake_deposit;
  //     const supportedStatus = ['COMPLETED', 'FAILED'];

  //     if (
  //       latestStakeDeposit.uuid === messageStakeDeposit.uuid &&
  //       !supportedStatus.includes(latestStakeDeposit?.state)
  //     ) {
  //       // Check for status update in the WebSocket message
  //       if (editableStatus.includes(messageStakeDeposit.state)) {
  //         // Update the state in Redux
  //         dispatch(
  //           setLatestTransactionData({
  //             type: 'stake_deposit',
  //             data: messageStakeDeposit,
  //           })
  //         );
  //       }
  //     }
  //   }
  // }, [latestTransactionData, message, dispatch]);

  return (
    <>
      <div className='flex flex-row items-center justify-between'>
        <StakeSwitch
          onSwitch={handleStakeOperationChange}
          selectedOption={selectedFilterOption}
          userSelectedTokenBalance={userSelectedTokenBalance}
          options={stakeOptions}
        />
        {isUserLoggedIn && (
          <div
            className='activity-link'
            onClick={() =>
              navigate(
                `/holdings?activity-tab=${
                  activityTabMap[selectedFilterOption?.value]
                }&scroll-history=true`
              )
            }
          >
            <RiHistoryFill data-tooltip-id='activity-link' />
            <Tooltip
              id='activity-link'
              place='bottom'
              content={`View ${selectedFilterOption?.value} activity`}
            />
          </div>
        )}
      </div>
      <div className='stake-divider'></div>
      <div className='flex flex-row justify-between items-baseline'>
        <p className='text-m text-medium text-active user-balance-container'>
          You {capitalizeFirstLetter(selectedFilterOption?.value)}
        </p>
        <span
          data-tooltip-id='total-balance-tooltip'
          className=' info-circle-hidden'
        >
          <AiOutlineInfoCircle size={12} />
        </span>
        <UserBalance
          isConnected={isConnected}
          selectedToken={selectedToken}
          userSelectedTokenBalance={userSelectedTokenBalance}
          userSelectedTokenBlockChainBalance={
            userSelectedTokenBlockChainBalance
          }
          userSelectedTokenMultipliBalance={userSelectedTokenMultipliBalance}
          selectedFilterOption={selectedFilterOption}
        />
      </div>
      <div className='flex flex-row justify-between items-center mt-4'>
        <Dropdown
          buttonComp={
            <div className='flex flex-row items-center gap-5 stake-dropdown-item w-64px'>
              <img src={selectedToken?.logo} alt='btc' />
              <h6 className='text-m text-medium text-active'>
                {convertXCoin(selectedToken?.symbol?.toUpperCase())}
              </h6>
            </div>
          }
          content={(toggleDropdown) => (
            <div className='stake-dropdown-item'>
              {coinData?.[
                selectedFilterOption?.value === 'unstake'
                  ? 'unstakeCoinList'
                  : 'stakeCoinList'
              ]?.map((item) => (
                <DropdownItem
                  key={item?.symbol}
                  onClick={() => {
                    handleTokenChange(item);
                    toggleDropdown();
                  }}
                >
                  <div className='flex flex-row items-center gap-5'>
                    <img src={item?.logo} alt='btc' />
                    <h6 className='text-m text-medium text-active'>
                      {convertXCoin(item?.symbol?.toUpperCase())}
                    </h6>
                  </div>
                </DropdownItem>
              ))}
            </div>
          )}
        />
        <div className='flex flex-row items-center gap-5'>
          <Button
            className='btn stake-btn-secondary'
            onClick={() => handlePercentage(50)}
            disabled={
              selectedFilterOption?.value === 'unstake' ||
              userSelectedTokenBalance == null
            }
          >
            <p className='text-m text-medium'>50%</p>
          </Button>
          <Button
            className='btn stake-btn-secondary'
            onClick={() => handlePercentage(100)}
            disabled={
              selectedFilterOption?.value === 'unstake' ||
              userSelectedTokenBalance == null
            }
          >
            <p className='text-m text-medium'>MAX</p>
          </Button>
        </div>
      </div>
      <StakeInputRow
        placeholder='0.00'
        valueLabel={
          selectedFilterOption?.value === 'stake' ||
          selectedFilterOption?.value === 'withdraw'
            ? `${convertXCoin(selectedToken?.symbol?.toUpperCase())}`
            : `${convertXCoin(selectedToken?.symbol?.toUpperCase())}`
        }
        data-tooltip-id={
          selectedFilterOption?.value === 'unstake'
            ? 'unstake-amount-tooltip'
            : 'stake-amount-tooltip'
        }
        value={formatNumber(stakeInputValue)}
        onChange={handleStakeValueChange}
        onInput={(e) =>
          maxLengthCheck(e, selectedToken?.quanitization, stakeInputValue)
        }
        readOnly={selectedFilterOption?.value === 'unstake'}
        disabled={selectedFilterOption?.value === 'unstake'}
      />
      <TransactionDetails
        stakeInputValue={stakeInputValue}
        selectedToken={selectedToken}
        isOpen={isTransactionDetailsToolTipOpen}
        userSelectedTokenBlockChainBalance={userSelectedTokenBlockChainBalance}
        userSelectedTokenMultipliBalance={userSelectedTokenMultipliBalance}
      />
      <UnstakeAmountInfoTooltip />
      {selectedFilterOption?.value !== 'withdraw' && (
        <>
          <div className='stake-divider'></div>
          <div className='flex flex-row justify-between items-baseline'>
            <p className='text-m text-medium text-active'>You Receive</p>
          </div>
          <StakeInputRow
            placeholder='0.00'
            valueLabel={
              selectedFilterOption?.value !== 'stake'
                ? `${selectedToken?.symbol?.toUpperCase().replace('X', '')}`
                : `x${selectedToken?.symbol?.toUpperCase()}`
            }
            data-tooltip-id={
              selectedFilterOption?.value === 'unstake'
                ? 'unstake-amount-tooltip'
                : ''
            }
            // amount={`30 USDC (multipli account) + 1 USDC (blockchain deposit required)`}
            value={formatNumber(stakeInputValue)}
            onChange={handleStakeValueChange}
            onInput={(e) =>
              maxLengthCheck(e, selectedToken?.quanitization, stakeInputValue)
            }
            readOnly={selectedFilterOption?.value === 'unstake'}
            disabled={selectedFilterOption?.value === 'unstake'}
          />
        </>
      )}
      {selectedFilterOption?.value === 'withdraw' ? (
        <div className='items-baseline flex flex-row justify-between mt-4'>
          <div>
            <p className='text-m text-medium'>You Receive</p>
          </div>
          <div>
            <p className='text-m text-medium'>
              {stakeInputValue ? formatNumber(stakeInputValue) : ''}{' '}
              {selectedToken?.symbol?.toUpperCase()}
            </p>
          </div>
        </div>
      ) : (
        gasFeeCondition && (
          <div className='items-baseline flex flex-row justify-between mt-4'>
            <div>
              <p className='text-m text-medium'>Estimated Gas Fees</p>
            </div>
            <div>
              <p className='text-m text-medium'>
                {totalGasFees && !isNaN(totalGasFees) && !feeError
                  ? '~ ' +
                    toFixedTrunc(totalGasFees, DEPOSIT_FEE_DECIMAL_POINT) +
                    ' ETH'
                  : 'Loading...'}{' '}
              </p>
            </div>
          </div>
        )
      )}
      <TransactionStatusInfo
        selectedToken={selectedToken}
        selectedFilterOption={selectedFilterOption}
      />
      {/* <InfoSection
        text={
          selectedFilterOption.value === 'withdraw'
            ? `${convertXCoin(
                selectedToken?.symbol?.toUpperCase()
              )} will be available to claim 24 hours after withdrawal.`
            : `${convertXCoin(
                selectedToken?.symbol?.toUpperCase()
              )} will be available for claim 7 days after unstaking.`
        }
        containerClassName='mt-4'
      /> */}
      <div className='flex flex-row justify-center mt-4'>
        {isUserLoggedIn ? (
          <>
            {chainId != supportedChainId ? (
              <Button
                className='btn btn-primary btn-block'
                onClick={handleSwitchNetwork}
                isLoading={isSwitchNetworkLoading}
              >
                Switch Network
              </Button>
            ) : (
              <>
                {checkInsufficientBalance() ? (
                  <Button className='btn btn-block btn-mute'>
                    Insufficient Balance
                  </Button>
                ) : checkInsufficientGasFees() ? (
                  <Button className='btn btn-block btn-mute'>
                    Insufficient Gas Fees
                  </Button>
                ) : (
                  <Button
                    className='btn btn-primary btn-block'
                    onClick={handleConfirm}
                    isLoading={
                      stakeOperationLoading || isWithdrawalOperationLoading
                    }
                    disabled={checkLoading()}
                  >
                    Confirm
                  </Button>
                )}
              </>
            )}
          </>
        ) : (
          <ConnectWalletBtn
            className='btn btn-block bg-primary'
            eventSource={'stakeForm'}
          />
        )}
      </div>
    </>
  );
}

export default StakeCard;
