import {
  useLazyListDepositsQuery,
  useLazyListWithdrawalsQuery,
  useLazyListStakeQuery,
  useLazyListUnstakeQuery,
  useLazyListYieldClaimQuery,
  useStakeDepositInfoByUUIDMutation,
} from '../../slices/historySlice';
import { useSelector } from 'react-redux';
import { NORMAL_WITHDRAWAL_CONFIRMATION_HOURS } from '../constants';

const classNameByWithdrawStatus = {
  RECEIVED: 'color-orange',
  VERIFIED: 'color-orange',
  INITIATED: 'color-orange',
  PROCESSING: 'color-orange',
  PROCESSED: 'color-orange',
  IN_PROGRESS: 'color-orange',
  SUCCESS: 'color-green',
  FAILED: 'color-red',
  INVALID: 'color-red',
  RESOLVED: 'color-orange',
  CONFIRMING: 'color-orange',
  TRANSFER_INITIATED: 'color-orange',
  L2_SUCCESS: 'color-orange',
};

export function transformString(inputString) {
  // Replace underscores with spaces
  const transformedString = inputString?.replace('_', ' ');
  return transformedString;
}

function useHistory() {
  const [listDepositData] = useLazyListDepositsQuery();
  const [listWithdrawalData] = useLazyListWithdrawalsQuery();
  const { userInfo } = useSelector((state) => state.auth);
  const [listStakeData] = useLazyListStakeQuery();
  const [listUnstakeData] = useLazyListUnstakeQuery();
  const [stakeDepositInfoByUUID] = useStakeDepositInfoByUUIDMutation();
  const [listYieldClaimData] = useLazyListYieldClaimQuery();

  const getListDepositData = async (page) => {
    try {
      let depositList = await listDepositData({
        token: userInfo?.payload?.tokens?.access,
        page,
      }).unwrap();
      return depositList;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const getListWithdrawalData = async (page) => {
    try {
      let withdrawalList = await listWithdrawalData({
        token: userInfo?.payload?.tokens?.access,
        page,
      }).unwrap();
      return withdrawalList;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const getStakeList = async (page) => {
    try {
      let stakeList = await listStakeData({
        token: userInfo?.payload?.tokens?.access,
        page,
      }).unwrap();
      return stakeList;
    } catch (error) {
      console.log({ error });
      throw error?.data?.message || error?.error;
    }
  };

  const getUnstakeAndStakeStatusText = (data) => {
    let state = data.state;
    if (state === 'COMPLETED') {
      return { text: 'SUCCESS', className: 'color-green' };
    } else if (state === 'FAILED') {
      return { text: 'FAILED', className: 'color-red' };
    }
    return { text: 'PENDING', className: 'color-yellow' };
  };

  const getUnStakeList = async (page) => {
    try {
      let unstakeList = await listUnstakeData({
        token: userInfo?.payload?.tokens?.access,
        page,
      }).unwrap();
      return unstakeList;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const getStakeDepositInfoByUUID = async (uuid) => {
    try {
      let unstakeList = await stakeDepositInfoByUUID({
        token: userInfo?.payload?.tokens?.access,
        uuid,
      }).unwrap();
      return unstakeList;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  const getYieldClaimList = async (page) => {
    try {
      let unstakeList = await listYieldClaimData({
        token: userInfo?.payload?.tokens?.access,
        page,
      }).unwrap();
      return unstakeList;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  function getDepositStatusText(data) {
    if (
      data?.blockchain_deposit_status === 'Failed' ||
      data?.brine_deposit_status === 'Failed'
    ) {
      return { text: 'Failed', className: 'color-red' };
    }

    const blockchainSuccess = data?.blockchain_deposit_status === 'Success';
    const brineSuccess = data?.brine_deposit_status === 'Success';

    const successCount = (blockchainSuccess ? 1 : 0) + (brineSuccess ? 1 : 0);
    const statusText = `${successCount} of 2`;

    if (successCount === 2) {
      return { text: `${statusText} - Success`, className: 'color-green' };
    } else {
      return { text: `${statusText} - Pending`, className: 'color-orange' };
    }
  }

  const getCountDown = (time) => {
    let date1 = new Date(time);
    let date2 = new Date();
    let hoursDiff = Math.abs(date1 - date2) / 36e5;
    return {
      hoursDiff: Math.trunc(hoursDiff),
      minutesDiff: hoursDiff * 60,
      date1,
      date2,
    };
  };

  function getWithdrawStatus(data) {
    const hoursDiff = getCountDown(data?.created_at).hoursDiff;
    const transactionStatus = data.transaction_status;

    if (
      transactionStatus === 'CONFIRMING' &&
      hoursDiff >= NORMAL_WITHDRAWAL_CONFIRMATION_HOURS
    ) {
      return { text: 'READY', className: 'color-green' };
    }
    const statusText = transformString(transactionStatus?.toUpperCase());
    const statusColor =
      transactionStatus === 'CONFIRMING'
        ? hoursDiff >= NORMAL_WITHDRAWAL_CONFIRMATION_HOURS
          ? 'color-green'
          : classNameByWithdrawStatus[transactionStatus]
        : classNameByWithdrawStatus[transactionStatus];

    return { text: statusText, className: statusColor };
  }

  const getActivityStatus = (type, activityItem) => {
    if (type === 'deposits') {
      return getDepositStatusText(activityItem);
    } else if (type === 'withdrawals') {
      return getWithdrawStatus(activityItem);
    } else if (type === 'unstakes') {
      return getUnstakeAndStakeStatusText(activityItem);
    } else if (type === 'stakes' || type == 'yield') {
      return getUnstakeAndStakeStatusText(activityItem);
    }
  };

  return {
    getListDepositData,
    getListWithdrawalData,
    getStakeList,
    getActivityStatus,
    getWithdrawStatus,
    getUnstakeAndStakeStatusText,
    getUnStakeList,
    getYieldClaimList,
    getStakeDepositInfoByUUID,
  };
}

export default useHistory;
