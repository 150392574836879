import React from 'react';

const MultiChartLegend = ({ payload, handleLegendClick, activeLegends }) => {
  return (
    <ul className={'custom-legend'}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          className={`${activeLegends.includes(entry.value) ? '' : 'inactive'}`}
          onClick={() => handleLegendClick(entry.value)}
          style={{ color: entry.color }}
        >
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

export default MultiChartLegend;
