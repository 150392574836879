import React from 'react';
import InfoTableRow from '../info-table-row/info-table-row';

function InfoTable({ data }) {
  return (
    <div className='info-table'>
      <div className='info-table-header'>
        {data?.headers?.map((item, index) => (
          <div className='info-table-entry' key={index}>
            {item?.logo && (
              <img className='table-entry-logo' src={item.logo} alt='' />
            )}
            {item?.heading && (
              <div className={`table-entry-text`}>{item?.heading}</div>
            )}
            {item?.data && (
              <span className='table-data-text'>{item?.data}</span>
            )}
          </div>
        ))}
      </div>
      <div className='info-table-body'>
        {data?.body?.map((row, index) => {
          return <InfoTableRow key={index} data={data} row={row} />;
        })}
      </div>
    </div>
  );
}

export default InfoTable;
