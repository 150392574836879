function Button({ isLoading, children, loadingText, ...otherButtonProps }) {
  return (
    <button {...otherButtonProps}>
      {isLoading ? (
        <div className='flex flex-row items-center justify-center'>
          <div className='loader'></div>
          {loadingText && <span className='loader-text'>{loadingText}</span>}
        </div>
      ) : (
        <div>{children}</div>
      )}
    </button>
  );
}

export default Button;
