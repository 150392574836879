import { apiSlice } from './apiSlice';

export const leaderBoardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    orbsLeaderboardInfo: builder.query({
      query: (top = 50) => ({
        url: `/orbs/leaderboard/?top=${top}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['OrbsLeaderboard'],
    }),

    userOrbsInfo: builder.query({
      query: ({ token }) => ({
        url: '/orbs',
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['UserOrbs'],
    }),
  }),
});

export const { useOrbsLeaderboardInfoQuery, useLazyUserOrbsInfoQuery } =
  leaderBoardSlice;
