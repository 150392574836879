import { Tooltip } from 'react-tooltip';
import useNumber from '../../utils/hooks/useNumber';
import useAppStats from '../../utils/hooks/useAppStats';

function TransactionDetails({
  stakeInputValue,
  selectedToken,
  isOpen,
  userSelectedTokenMultipliBalance,
  userSelectedTokenBlockChainBalance,
}) {
  const { formatValueWithSymbol } = useAppStats();

  function generateTransactionMessage(userInput, mBalance, bBalance) {
    // Calculate the amount used from multiple accounts
    let multiAccountAmount = Math.min(userInput, mBalance);

    // Calculate the amount used from blockchain
    let blockchainAmount = userInput - multiAccountAmount;

    // Ensure blockchain amount doesn't go negative
    blockchainAmount = Math.max(0, blockchainAmount);

    if (multiAccountAmount == 0) {
      return `Using ${formatValueWithSymbol(
        blockchainAmount,
        selectedToken?.symbol
      )} from blockchain (deposit required)`;
    } else if (blockchainAmount == 0) {
      return `Using ${formatValueWithSymbol(
        multiAccountAmount,
        selectedToken?.symbol
      )} from Multipli account.`;
    } else {
      return `Using ${formatValueWithSymbol(
        multiAccountAmount,
        selectedToken?.symbol
      )} from Multipli account and ${formatValueWithSymbol(
        blockchainAmount,
        selectedToken?.symbol
      )} from blockchain (deposit required)`;
    }
  }
  return (
    <Tooltip id='stake-amount-tooltip' place='right' isOpen={isOpen}>
      <div className='stake-balance-tooltip-content'>
        <p>
          {generateTransactionMessage(
            stakeInputValue,
            userSelectedTokenMultipliBalance,
            userSelectedTokenBlockChainBalance
          )}
        </p>
      </div>
    </Tooltip>
  );
}

export default TransactionDetails;
