import React from 'react';
import useViewport from '../../utils/hooks/useViewPort';
import { truncate } from '../../utils/string-methods';

function InfoTableRow({ data, row }) {
  const { width } = useViewport();

  const formatValue = (value) => {
    if (
      typeof value === 'string' &&
      ((width < 1200 && width > 800) || width < 600)
    ) {
      return truncate(value, 12);
    }
    return value;
  };

  return (
    <div className='info-table-row'>
      {data.headers?.map((col, idx) => {
        return (
          <div key={idx} className='info-table-entry'>
            {typeof row[col.accessor] === 'object' ? (
              <>
                {col?.isComponent ? (
                  row[col.accessor]
                ) : (
                  <>
                    <img
                      className='table-entry-logo'
                      src={row[col.accessor]?.logo}
                      alt=''
                    />
                    <div className='table-entry-text'>
                      {row[col.accessor]?.token}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className='table-entry-text'>{row[col.accessor]}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default InfoTableRow;
