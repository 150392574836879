import React, { useState } from 'react';
import CustomSelect from '../custom-select/custom-select';

function TabNav({
  data,
  currentTab,
  setCurrentTab,
  addContainerClassName,
  onChange,
}) {
  const populatedSelectData = data?.reduce((acc, val) => {
    const item = { label: val, value: val };
    acc = [...acc, item];
    return acc;
  }, []);

  return (
    <>
      <div className={`tab-nav ${addContainerClassName}`}>
        {data.map((navText) => (
          <span
            key={navText}
            className={`tab-nav-item ${navText === currentTab ? 'active' : ''}`}
            onClick={() => {
              setCurrentTab(navText);
              if (typeof onChange === 'function') {
                onChange(navText);
              }
            }}
          >
            {navText}
          </span>
        ))}
      </div>
      <CustomSelect
        className={`tablet-show ${addContainerClassName}`}
        options={populatedSelectData}
        value={{ value: currentTab, label: currentTab }}
        defaultValue={populatedSelectData?.[0]}
        onChange={(val) => {
          setCurrentTab(val?.value);
          onChange(val?.value);
        }}
      />
    </>
  );
}

export default TabNav;
