import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './css/index.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import Orbs from './pages/orbs/orbs.jsx';
import Leaderboard from './pages/leaderboard/leaderboard.jsx';
import Stake from './pages/stake/stake.jsx';
import Dashboard from './pages/dashboard/dashboard.jsx';
import { Provider } from 'react-redux';
import store from './store.js';
import { initializeWeb3Modal } from './utils/web3Config.js';
import Holdings from './pages/holdings/holdings.jsx';
import * as Sentry from '@sentry/react';
import { dedupeIntegration } from '@sentry/react';
import { ENVIRONMENT, sentryDSN } from './utils/constants.js';
import NotFound from './pages/not-found-page/not-found-page.jsx';
import CrashFallback from './pages/crash-fallback/crash-fallback.jsx';
import IPFallback from './pages/ip-fallback/ip-fallback.jsx';
import ServerDown from './pages/server-down/server-down.jsx';

// Initialize Web3Modal
initializeWeb3Modal();

Sentry.init({
  dsn: sentryDSN,
  integrations: [dedupeIntegration()],
  enabled: ENVIRONMENT === 'mainnet', // Only enable in production environment
  enableTracing: true, // Enable tracing for performance monitoring
  tracesSampleRate: 0.2, // Capture 20% of transactions for performance monitoring for now, [Brine-FE has 0]
  sampleRate: 0.5, // Capture 50% of error events, kept it 50% as users are less for now and we will have to monitor more errors, will reduce in future so that we do not exceed the event limit
  ignoreErrors: [], // Specify errors to ignore, in future
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />} errorElement={<CrashFallback />}>
      <Route index={true} path='/' element={<Stake />} />
      <Route path='/orbs' element={<Orbs />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/leaderboard' element={<Leaderboard />} />
      <Route path='/holdings' element={<Holdings />} />
      <Route path='/ip-fallback' element={<IPFallback />} />
      <Route path='/server-down' element={<ServerDown />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
