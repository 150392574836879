export const url = {
  mainnet: 'https://api.multipli.fi/multipli/v1',
  testnet: 'https://api-testnet.multipli.fi/multipli/v1',
};
export const publicAppStatsUrls = {
  mainnet: 'https://api.multipli.fi/mapi/public/stats',
  testnet: 'https://api.multipli.fi/mapi/public/testnet-stats',
};
export const wsUrls = {
  testnet: 'wss://api-testnet.multipli.fi',
  mainnet: 'wss://api.multipli.fi',
};
export const starkContract = {
  mainnet: '0x1390f521A79BaBE99b69B37154D63D431da27A07',
  testnet: '0xA2eC709125Ea693f5522aEfBBC3cb22fb9146B52',
};
export const blockExplorerUrls = {
  mainnet: 'https://etherscan.io',
  testnet: 'https://sepolia.etherscan.io',
};
export const maxIntAllowance =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935'; // noqa:keycheck
export const NORMAL_WITHDRAWAL_CONFIRMATION_HOURS = 24;
export const cachePrefixKey = 'multipli-key';
export const wscacheKey = 'recentWSMessage';
export const latestTransactionCacheKey = 'recentTransaction';
export const supportedWSEvents = ['deposit', 'withdrawal', 'stake_deposit'];
export const fallbackAPY = {
  XUSDC: 10.5,
  XUSDT: 10.5,
  XBTC: 5,
  XPAXG: 5,
  XSOL: 5,
  USDC: 10.5,
  USDT: 10.5,
  BTC: 5,
  PAXG: 5,
  SOL: 5,
};
export const baseCurrency = 'usdc';
export const usdtBasedTokens = ['ldo', 'rpl', 'strk', 'usdt', 'comp'];
export const stakeDepositLimit = '493.42';
export const baseUrl = url[import.meta.env.VITE_APP_ENVIRONMENT];
export const publicStatsUrl =
  publicAppStatsUrls[import.meta.env.VITE_APP_ENVIRONMENT];
export const blockExplorerUrl =
  blockExplorerUrls[import.meta.env.VITE_APP_ENVIRONMENT];
export const wsbaseUrl = wsUrls[import.meta.env.VITE_APP_ENVIRONMENT];
export const rpcUrls = {
  testnet:
    'https://eth-sepolia.g.alchemy.com/v2/2WymMM-GrijDUVUj49EBZv0kFUbc_em-',
  mainnet:
    'https://eth-mainnet.g.alchemy.com/v2/2WymMM-GrijDUVUj49EBZv0kFUbc_em-',
};
export const supportedChainIds = {
  testnet: '11155111',
  mainnet: '1',
};
export const stakeCoinList = {
  mainnet: ['usdc'],
  testnet: ['usdt', 'usdc'],
};
export const unstakeCoinList = {
  mainnet: ['xusdc'],
  testnet: ['xusdt', 'xusdc'],
};
export let allowedStakeCoins =
  stakeCoinList[import.meta.env.VITE_APP_ENVIRONMENT];
export let allowedUnStakeCoins =
  unstakeCoinList[import.meta.env.VITE_APP_ENVIRONMENT];
export const starkContractAddress =
  starkContract[import.meta.env.VITE_APP_ENVIRONMENT];
export const rpcUrl = rpcUrls[import.meta.env.VITE_APP_ENVIRONMENT];
export const supportedChainId =
  supportedChainIds[import.meta.env.VITE_APP_ENVIRONMENT];
export const analyticsKey = import.meta.env.VITE_APP_ANALYTICS_KEY;
export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT;
export const HOME_ROUTE = 'stake';
export const ENABLE_SEGMENT =
  import.meta.env.VITE_APP_ENVIRONMENT === 'mainnet' ? true : false;
export const supportEmail = `support@multipli.fi`;
export const stakeParam = 'stake-tab';
export const dashboardParam = 'dashboard-tab';
export const activityParam = 'activity-tab';
export const sentryDSN = import.meta.env.VITE_APP_SENTRY_DSN;
export const DEPOSIT_FEE_DECIMAL_POINT = 5;
