import React from 'react';
import { useSendFeedbackMutation } from '../../slices/feedbackSlice';

function useFeedback() {
  const [sendFeedback, { isLoading: isSendFeedbackLoading }] =
    useSendFeedbackMutation();

  const sendFeedbackData = async (formData) => {
    try {
      const sendFeedbackRes = await sendFeedback({
        formData,
      }).unwrap();

      return sendFeedbackRes;
    } catch (error) {
      throw error?.data?.message || error?.error || error;
    }
  };

  return { sendFeedbackData, isSendFeedbackLoading };
}

export default useFeedback;
