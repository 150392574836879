import { allowedStakeCoins, allowedUnStakeCoins } from '../utils/constants';
import { apiSlice } from './apiSlice';

export const appMetaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppStats: builder.query({
      query: () => ({
        url: '/app-and-markets/',
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
      providesTags: ['AppMetaData'],
    }),
    getCoinStats: builder.query({
      query: () => ({
        url: '/coins/',
        method: 'POST',
      }),
      // Sanitize data before returning
      transformResponse: (response) => {
        let filterStakeData = [];
        let filterUnStakeData = [];
        let allData = [];

        Object.keys(response?.payload).forEach((key) => {
          if (allowedStakeCoins.includes(response?.payload[key]?.symbol)) {
            filterStakeData.push(response?.payload[key]);
          }
        });

        Object.keys(response?.payload).forEach((key) => {
          if (allowedUnStakeCoins.includes(response?.payload[key]?.symbol)) {
            filterUnStakeData.push(response?.payload[key]);
          }
        });

        Object.keys(response?.payload).forEach((key) => {
          allData.push(response?.payload[key]);
        });

        response['stakeCoinList'] = filterStakeData;
        response['unstakeCoinList'] = filterUnStakeData;
        response['allCoinList'] = allData;

        return response;
      },
      keepUnusedDataFor: 60,
      providesTags: ['CoinMetaData'],
    }),
    getMarketStats: builder.query({
      query: () => ({
        url: '/market/tickers/',
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      providesTags: ['MarketMetaData'],
    }),
    getHealthStatus: builder.mutation({
      query: () => ({
        url: '/health/',
        method: 'GET',
      }),
    }),
    getPlatformStats: builder.query({
      query: () => ({
        url: '/platform-stats/',
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
      providesTags: ['PlatformStatsData'],
    }),
  }),
});

export const {
  useGetAppStatsQuery,
  useGetCoinStatsQuery,
  useGetMarketStatsQuery,
  useGetHealthStatusMutation,
  useGetPlatformStatsQuery,
} = appMetaApiSlice;
