import {
  useGetAppStatsQuery,
  useGetCoinStatsQuery,
} from '../../slices/appSlice';
import { useGetPublicStatsQuery } from '../../slices/publicStatsSlice';
import { baseCurrency, fallbackAPY } from '../../utils/constants';
import useNumber from './useNumber';

function useAppStats() {
  const { data: coinData } = useGetCoinStatsQuery();
  const { toFixedTrunc, formatNumber } = useNumber();
  const {
    data: appStatsData,
    isLoading: isGetAppStatsLoading,
    isError: isGetAppStatsError,
  } = useGetAppStatsQuery();

  const {
    data: publicStatsData,
    isLoading: isPublicStatsDataLoading,
    isError: isPublicStatsDataError,
  } = useGetPublicStatsQuery();

  const getStaticMessageFromAppStats = () => {
    if (!isGetAppStatsLoading && !isGetAppStatsError) {
      let msg =
        appStatsData['payload']['app_config']['static-sign-msg'][
          `multipli_${import.meta.env.VITE_APP_ENVIRONMENT}`
        ];
      return msg;
    }
  };

  const formatMarketPair = (symbol) => {
    return symbol === 'usdc' || symbol === 'usdt'
      ? 'usdcusdt'
      : `${symbol}${baseCurrency}`;
  };

  const getInfoAboutCoins = (currency) => {
    if (coinData?.allCoinList) {
      const { allCoinList } = coinData;
      const data = allCoinList.find((data) => data.symbol === currency);
      return data;
    }
  };

  const formatValueWithSymbol = (amount, symbol) => {
    const formattedAmount = formatNumber(
      toFixedTrunc(amount, getInfoAboutCoins(symbol)?.quanitization)
    );
    return `${formattedAmount} ${symbol?.toUpperCase()}`;
  };

  const getAPYByCurrency = (currency) => {
    let apyValue = null;

    if (publicStatsData?.apy && !isPublicStatsDataLoading) {
      apyValue = publicStatsData?.apy?.[currency] || fallbackAPY[currency];
    }

    if (isPublicStatsDataError && !isPublicStatsDataLoading) {
      apyValue = fallbackAPY[currency] || 10.5;
    }

    return toFixedTrunc(apyValue, 2);
  };

  return {
    formatMarketPair,
    getStaticMessageFromAppStats,
    getInfoAboutCoins,
    formatValueWithSymbol,
    getAPYByCurrency,
  };
}

export default useAppStats;
