import React from 'react';
import MultiLineChart from '../charts/multi-line-chart';
import BarChart from '../charts/bar-chart';
import PieCharts from '../charts/pie-chart';

function Chart({ type, chartData, chartStyle, title }) {
  return (
    <>
      {type === 'multiLine' && (
        <MultiLineChart
          chartData={chartData}
          chartStyle={chartStyle}
          title={title}
        />
      )}
      {type === 'barChart' && (
        <BarChart chartData={chartData} chartStyle={chartStyle} />
      )}
      {type === 'pieChart' && (
        <PieCharts chartData={chartData} chartStyle={chartStyle} />
      )}
    </>
  );
}

export default Chart;
