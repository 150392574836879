import React, { useState } from 'react';
import { images } from '../../utils/images';
import useSegment from '../../utils/hooks/useSegment';
import useRoute from '../../utils/hooks/useRoute';
import FeedbackPopup from '../feedback/feedback-popup';
import { BsChatFill } from 'react-icons/bs';

function SocialHandler() {
  const { sendTrackEvent } = useSegment();
  const { returnRouteName } = useRoute();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const handleSocialClick = (socialMediaHandle) => {
    let url;
    switch (socialMediaHandle) {
      case 'TWITTER':
        url = 'https://x.com/multiplifi';
        break;
      case 'DISCORD':
        url = 'https://discord.gg/F6gjSB3f78';
        break;
      case 'GITBOOK':
        url = 'https://docs.multipli.fi/';
        break;
      case 'FEEDBACK':
        setIsFeedbackModalOpen(true);
        return;
      default:
        url = '';
    }

    if (url) {
      window.open(url, '_blank');
    }

    const eventsource = 'Layout' + '-' + returnRouteName();
    sendTrackEvent('socialHandlesClicked', {
      source: eventsource,
      type: socialMediaHandle,
    });
  };

  return (
    <div className='social-handler'>
      <FeedbackPopup
        isOpen={isFeedbackModalOpen}
        closeModal={() => setIsFeedbackModalOpen(false)}
      />

      <img
        className='social-handle'
        src={images.twitterLogo}
        alt='Twitter'
        onClick={() => handleSocialClick('TWITTER')}
      />
      <img
        className='social-handle'
        src={images.discordLogo}
        alt='Discord'
        onClick={() => handleSocialClick('DISCORD')}
      />
      <img
        className='social-handle'
        src={images.gitBookLogo}
        alt='GitBook'
        onClick={() => handleSocialClick('GITBOOK')}
      />
      <BsChatFill
        color='#C1C1C1'
        size={20}
        className='social-handle'
        onClick={() => handleSocialClick('FEEDBACK')}
      />
    </div>
  );
}

export default SocialHandler;
