import React, { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

const Modal = ({
  state,
  setState,
  children,
  modalClassName,
  onClose,
  showConfetti = false,
  disableClose = false,
}) => {
  const toggleModal = () => {
    if (disableClose) {
      return;
    }
    setState(!state);
    if (onClose) {
      onClose();
    }
  };

  const handleOutsideClick = (event) => {
    if (disableClose) {
      return;
    }
    if (
      event?.target?.className?.includes('modal') &&
      !event?.target?.className?.includes('modal-content')
    ) {
      toggleModal();
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <div
      className={`modal ${state ? 'show-modal' : ''} ${
        !modalClassName ? '' : modalClassName
      }`}
      onClick={handleOutsideClick}
    >
      <div className='modal-content'>
        {showConfetti && state && (
          <ConfettiExplosion
            zIndex={'100000'}
            className='modal-content-confetti'
            force={0.8}
            duration={3000}
            particleCount={250}
            width={1600}
          />
        )}
        {!disableClose && (
          <span className='close-button' onClick={toggleModal}>
            &times;
          </span>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
