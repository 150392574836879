import React from 'react';

function DataLoader({ msg, addClassName }) {
  return (
    <div className={`flex flex-column items-center ${addClassName}`}>
      <span className='loader'></span>
      {msg && <h5 className='mt-3'>{msg}</h5>}
    </div>
  );
}

export default DataLoader;
