import React from 'react';
import { setModalState } from '../../slices/loginModalSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from './button';
import useSegment from '../../utils/hooks/useSegment';
import useRoute from '../../utils/hooks/useRoute';
import { logout } from '../../slices/authSlice';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';

function ConnectWalletBtn({ onClose, eventSource, ...otherProps }) {
  const { isModalOpen } = useSelector((state) => state.loginModal);
  const { sendTrackEvent } = useSegment();
  const dispatch = useDispatch();
  const { returnRouteName } = useRoute();
  const { performLogout } = useUserAuthentication();

  const handleEventDispatch = () => {
    sendTrackEvent('connectWalletInitiated', {
      source: eventSource + '-' + returnRouteName(),
    });
  };

  const toogleModalState = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    if (eventSource) {
      handleEventDispatch();
    }
    performLogout(dispatch);
    dispatch(setModalState(!isModalOpen));
  };

  return (
    <Button
      className='btn btn-primary'
      onClick={() => toogleModalState()}
      {...otherProps}
    >
      Connect Wallet
    </Button>
  );
}

export default ConnectWalletBtn;
