export const getInitalStakeTableData = (selectedOption = 'stake') => {
  return {
    headers: [
      {
        logo: null,
        data: null,
        heading: 'Days',
        accessor: 'days',
      },
      {
        logo: null,
        data: null,
        heading: `${
          selectedOption === 'stake' ? 'Projected' : 'Missed'
        } Returns`,
        accessor: 'projected_returns',
      },
      {
        logo: null,
        data: null,
        heading: 'Projected APY',
        accessor: 'projected_apy',
      },
    ],
    body: [
      {
        days: '60',
        projected_returns: '--',
        projected_apy: '--',
      },
      {
        days: '180',
        projected_returns: '--',
        projected_apy: '--',
      },
      {
        days: '360',
        projected_returns: '--',
        projected_apy: '--',
      },
      {
        days: '720',
        projected_returns: '--',
        projected_apy: '--',
      },
    ],
  };
};
