import React from 'react';
import moment from 'moment';

function sortByValue(array) {
  return array.sort((a, b) => b.value - a.value);
}

const ChartTooltip = ({
  active,
  payload,
  label,
  addClassName,
  valueKey = 'value',
  labelType = 'timestamp',
}) => {
  if (active && payload && payload.length) {
    return (
      <div className={`custom-tooltip ${addClassName ? addClassName : ''}`}>
        {label && (
          <p className='label'>
            {labelType !== 'timestamp'
              ? label
              : `${moment(label).format('lll')}`}
          </p>
        )}
        {sortByValue(payload).map((item, idx) => {
          return (
            <div
              className='flex flex-row items-center justify-between intro-container'
              key={idx}
            >
              <p className='intro key'>
                <span style={{ color: item.stroke }} className='info-color'>
                  {item.name}
                </span>
              </p>
              <p className='intro value'>
                <span style={{ color: item.stroke }} className='info-color'>
                  {valueKey ? item?.payload[valueKey] : item[valueKey]}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
