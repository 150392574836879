import React from 'react';
import images from '../images';
import useNumber from './useNumber';

function useDashboard() {
  const { convertToAbbreviation } = useNumber();
  function convertToAllocationData(inputData) {
    const { volume_staked } = inputData;
    const assets = Object.keys(volume_staked);

    // Calculate total allocations
    let totalBinance = 0;
    let totalOkx = 0;
    let totalHtx = 0;
    let totalVolume = 0;

    assets.forEach((asset) => {
      const { total_volume_staked, allocation } = volume_staked[asset];
      totalBinance += total_volume_staked * (allocation.binance / 100);
      totalOkx += total_volume_staked * (allocation.okx / 100);
      totalHtx += total_volume_staked * (allocation.htx / 100);
      totalVolume += total_volume_staked;
    });

    // Create headers
    const headers = [
      {
        logo: null,
        heading: null,
        data: 'Asset',
        accessor: 'asset',
      },
      {
        logo: images.binanceLogo, // Replace with actual image reference
        heading: null,
        data: `${((totalBinance / totalVolume) * 100).toFixed(1)}%`,
        accessor: 'binanceValue',
      },
      {
        logo: images.okxLogo, // Replace with actual image reference
        heading: null,
        data: `${((totalOkx / totalVolume) * 100).toFixed(1)}%`,
        accessor: 'okxValue',
      },
      {
        logo: images.HTXLogo, // Replace with actual image reference
        heading: null,
        data: `${((totalHtx / totalVolume) * 100).toFixed(1)}%`,
        accessor: 'htxValue',
      },
    ];

    // Create body
    const body = assets.map((asset) => {
      const { total_volume_staked, allocation } = volume_staked[asset];
      return {
        asset: {
          logo: `images.${asset}Logo`, // Replace with actual image reference
          token: asset.toUpperCase(),
        },
        binanceValue: convertToAbbreviation(
          ((total_volume_staked * allocation.binance) / 100).toFixed(3)
        ),
        okxValue: convertToAbbreviation(
          ((total_volume_staked * allocation.okx) / 100).toFixed(3)
        ),
        htxValue: convertToAbbreviation(
          ((total_volume_staked * allocation.htx) / 100).toFixed(3)
        ),
      };
    });

    return { headers, body };
  }

  return { convertToAllocationData };
}

export default useDashboard;
