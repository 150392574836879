import React from 'react';
import {
  BarChart as RechartBarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { ChartTooltip } from '../charts/utils';

const BarShape = ({ fill, x, y, width, height }) => {
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      stroke='rgba(82, 82, 82, 0.4)' // Add the desired border color here
      strokeWidth={2} // Adjust the border width as needed
    />
  );
};

const BarChart = ({ chartData, chartStyle }) => {
  return (
    <ResponsiveContainer height={300} {...chartStyle}>
      <RechartBarChart data={chartData} barSize={60}>
        <XAxis dataKey='name' fontSize={12} />
        <YAxis fontSize={12} />
        <Tooltip
          content={
            <ChartTooltip
              type='bar'
              addClassName='bar-chart'
              labelType='label'
            />
          }
        />
        <Bar
          dataKey='value'
          fill='rgba(82, 82, 82, 0.4)'
          isAnimationActive={false}
          shape={<BarShape />}
        />
      </RechartBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
