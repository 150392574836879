import { createSlice } from '@reduxjs/toolkit';
import { wscacheKey } from '../utils/constants';

const loadInitialState = () => {
  const savedMessage = localStorage.getItem(wscacheKey);
  return {
    status: 'DISCONNECTED',
    message: savedMessage
      ? JSON.parse(savedMessage)
      : {
          deposit: null,
          withdrawal: null,
          stake: null,
          stake_deposit: null,
        },
  };
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: loadInitialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setMessage: (state, action) => {
      const { type, data } = action.payload;
      data.uuid = data?.stake_uuid || data?.stake_deposit_uuid;
      state.message[type] = data;
      localStorage.setItem(wscacheKey, JSON.stringify(state.message));
    },
    clearData: (state) => {
      state.status = 'DISCONNECTED';
      state.message = {
        deposit: null,
        withdrawal: null,
        stake: null,
        stake_deposit: null,
      };
      localStorage.removeItem(wscacheKey);
    },
    clearStoreData: (state) => {
      state.message = {
        deposit: null,
        withdrawal: null,
        stake: null,
        stake_deposit: null,
      };
      localStorage.removeItem(wscacheKey);
    },
    // dispatch(removeItemByUUID({ type: 'deposit', uuid: 'some-uuid-here' }));
    removeItemByUUID: (state, action) => {
      const { type, uuid } = action.payload;
      if (state.message[type] && Array.isArray(state.message[type])) {
        state.message[type] = state.message[type].filter(
          (item) => item.uuid !== uuid
        );
        if (state.message[type].length === 0) {
          state.message[type] = null;
        }
        localStorage.setItem(wscacheKey, JSON.stringify(state.message));
      }
    },
  },
});

export const {
  setStatus,
  setMessage,
  clearData,
  removeItemByUUID,
  clearStoreData,
} = websocketSlice.actions;
export default websocketSlice.reducer;
