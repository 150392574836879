import React from 'react';
import { images } from '../../utils/images';

function FallBackLoader() {
  return (
    <div className='fallback-loader-container'>
      <div className='lds-ring-1'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <img
          src={images.multipliLogoSM}
          alt='logo'
          className='fallback-loader-container-img'
        />
      </div>
    </div>
  );
}

export default FallBackLoader;
