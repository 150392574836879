import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useSelector } from 'react-redux';
import { logout } from '../../slices/authSlice';

function useUserAuthentication() {
  const { isConnected } = useWeb3ModalAccount();
  const userInfo = useSelector((state) => state.auth.userInfo);

  const isUserLoggedIn = () => {
    return isConnected && userInfo?.payload?.tokens?.access ? true : false;
  };

  const displayTextOrPlaceholder = (text) => {
    return isUserLoggedIn() ? text : '---';
  };

  const performLogout = (dispatch) => {
    dispatch(logout());
    dispatch({ type: 'WEBSOCKET_DISCONNECT' });
  };

  return {
    isUserLoggedIn: isUserLoggedIn(),
    displayTextOrPlaceholder,
    performLogout,
  };
}

export default useUserAuthentication;
