import React from 'react';
import Button from './button';

function IconButton({ title, iconComp, btnClassName, ...otherBtnProps }) {
  return (
    <Button
      className={`btn btn-small icon-button ${btnClassName}`}
      {...otherBtnProps}
    >
      <div className='flex flex-row items-center'>
        {iconComp}
        <p>{title}</p>
      </div>
    </Button>
  );
}

export default IconButton;
