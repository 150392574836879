import { useSelector } from 'react-redux';
import useNumber from '../../utils/hooks/useNumber';
import InfoSection from './info-container';
import { convertXCoin } from '../../utils/string-methods';
import { supportEmail } from '../../utils/constants';

function TransactionStatusInfo({ selectedToken, selectedFilterOption }) {
  const { latestTransactionData } = useSelector((state) => state.transaction);
  const { message, status: wsConnectionStatus } = useSelector(
    (state) => state.websocket
  );
  const { untrailZeros } = useNumber();

  const generateStakeMessage = () => {
    const stakeDeposit = latestTransactionData?.stake_deposit;
    const stake = latestTransactionData?.stake;
    const isStakeDepositCompleted = stakeDeposit?.state === 'COMPLETED';
    const isStakeCompleted = stake?.state === 'COMPLETED';

    if (stakeDeposit && stakeDeposit.state === 'FAILED') {
      return {
        state: 'failure',
        msg: `The staking process has failed; please reach out to ${supportEmail}`,
      };
    }

    if (stakeDeposit && stake) {
      const amount = Number(stakeDeposit?.amount) + Number(stake?.amount);
      const currency = stakeDeposit?.currency?.toUpperCase();

      if (!isStakeDepositCompleted) {
        return {
          state: 'loading',
          msg: `Your ${untrailZeros(
            amount
          )} ${currency} is being staked (Completed 1 of 2 operations)`,
        };
      } else {
        return {
          state: 'success',
          msg: `Your ${untrailZeros(
            amount
          )} ${currency} has been staked (Completed 2 of 2 operations)`,
        };
      }
    } else if (stakeDeposit && !isStakeDepositCompleted) {
      const amount = Number(stakeDeposit?.amount);
      const currency = stakeDeposit?.currency?.toUpperCase();

      return {
        state: 'loading',
        msg: `The staking of ${untrailZeros(
          amount
        )} ${currency} is in progress.`,
      };
    } else if (stakeDeposit && isStakeDepositCompleted) {
      const amount = Number(stakeDeposit?.amount);
      const currency = stakeDeposit?.currency?.toUpperCase();

      return {
        state: 'success',
        msg: `Your ${untrailZeros(amount)} ${currency} has been staked.`,
      };
    } else if (stake && !isStakeCompleted) {
      const amount = Number(stake?.amount);
      const currency = stake?.currency?.toUpperCase();

      return {
        state: 'loading',
        msg: `The staking of ${untrailZeros(
          amount
        )} ${currency} is in progress.`,
      };
    } else if (stake && isStakeCompleted) {
      const amount = Number(stake?.amount);
      const currency = stake?.currency?.toUpperCase();

      return {
        state: 'success',
        msg: `Your ${untrailZeros(amount)} ${currency} has been staked.`,
      };
    }

    return {
      state: 'info',
      msg: `Your staking of ${
        untrailZeros(stakeDeposit?.amount || 0) ||
        untrailZeros(stake?.amount || 0) ||
        'unknown amount'
      } ${
        stakeDeposit?.currency?.toUpperCase() ||
        stake?.currency?.toUpperCase() ||
        'unknown currency'
      } is in an unknown state.`,
    };
  };

  return (
    <>
      {(latestTransactionData?.stake || latestTransactionData?.stake_deposit) &&
      wsConnectionStatus === 'CONNECTED' &&
      selectedFilterOption.value === 'stake' ? (
        <InfoSection
          text={
            generateStakeMessage(
              latestTransactionData?.stake?.amount,
              latestTransactionData?.stake?.currency,
              latestTransactionData?.stake?.state
            ).msg
          }
          containerClassName='mt-4'
          state={
            generateStakeMessage(
              latestTransactionData?.stake?.amount,
              latestTransactionData?.stake?.currency,
              latestTransactionData?.stake?.state
            ).state
          }
        />
      ) : (
        <InfoSection
          text={
            selectedFilterOption.value === 'withdraw'
              ? `${convertXCoin(
                  selectedToken?.symbol?.toUpperCase()
                )} will be available to claim 24 hours after withdrawal.`
              : `${convertXCoin(
                  selectedToken?.symbol?.toUpperCase()
                )} will be available for claim 7 days after unstaking.`
          }
          containerClassName='mt-4'
        />
      )}
    </>
  );
}

export default TransactionStatusInfo;
