import React, { useEffect, useState } from 'react';
import { getInitalStakeTableData } from './stakeData';
import StakeCard from '../../components/stake/stake-card';
import Stepper from '../../components/common/stepper';
import { useDispatch, useSelector } from 'react-redux';
import useStake from '../../utils/hooks/useStake';
import { useGetCoinStatsQuery } from '../../slices/appSlice';
import FallBackLoader from '../../components/common/fallback-loader';
import Modal from '../../components/common/modal';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import InfoSection from '../../components/stake/info-container';
import { stakeParam } from '../../utils/constants';
import APYInfoContainer from '../../components/stake/stake-apy-info-container';
import StakeWithdrawalContainer from '../../components/stake/stake-withdrawal-container';
import useInfoPopup from '../../utils/hooks/useInfoPopup';
import DataLoader from '../../components/common/loader';
import UnstakeInfoContainer from '../../components/stake/unstake-info-container';
import uniqBy from 'lodash/uniqBy';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import useHistory from '../../utils/hooks/useHistory';
import { useSearchParams } from 'react-router-dom';

const stakeOptions = [
  {
    label: 'Stake',
    value: 'stake',
  },
  {
    label: 'Unstake',
    value: 'unstake',
  },
  {
    label: 'Withdraw',
    value: 'withdraw',
  },
];

function Stake() {
  const {
    data: coinData,
    isLoading: isCoinDataLoading,
    isError: isCoinDataError,
  } = useGetCoinStatsQuery();
  const [selectedFilterOption, setSelectedFilterOption] = useState(
    stakeOptions[0]
  );
  // const {
  //   data: marketData,
  //   isLoading: isMarketDataLoading,
  //   isError: isMarketDataError,
  //   error: marketDataError,
  // } = useGetMarketStatsQuery();
  const [stakeInputValue, setStakeInputValue] = useState('');
  const [stakeTableData, setStakeTableData] = useState(
    getInitalStakeTableData()
  );
  const { getAPYStats } = useStake();
  const [selectedToken, setSelectedToken] = useState(null);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [searchParams] = useSearchParams(false);
  const [requestModalMeta, setRequestModalMeta] = useState({
    title: null,
    description: null,
    link: null,
    linkText: null,
  });
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isGetUnstakeDataProcessing, setIsGetUnstakeDataProcessing] =
    useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const { isConnected } = useWeb3ModalAccount();
  const { isUserLoggedIn } = useUserAuthentication();
  const { getUnStakeList } = useHistory();
  const [steps, setSteps] = useState([
    {
      stepLabel: 'Fetching your balance',
      stepDescription: 'Retrieving your current balance on Multipli.',
      completed: false,
    },
    {
      stepLabel: 'Verifying allowance towards deposit',
      stepDescription:
        'Checking whether the allowance request for this token is approved.',
      completed: false,
    },
    {
      stepLabel: 'Approve allowance request',
      stepDescription:
        'Please approve the token allowance request through your wallet.',
      completed: false,
    },
    {
      stepLabel: 'Approve request to deposit amount into Multipli',
      stepDescription:
        'We have initiated a deposit request from your wallet to Multipli. Please approve the same from your wallet.',
      completed: false,
    },
    {
      stepLabel: 'Staking your assets now',
      stepDescription:
        'Your tokens are now being staked. Sip a cup of coffee while you wait.',
      completed: false,
    },
  ]);
  const [unstakeRes, setUnstakeRes] = useState(null);
  const [unstakeData, setUnstakeData] = useState([]);
  const [unstakePage, setUnstakePage] = useState(1);
  const { showPopup } = useInfoPopup();

  useEffect(() => {
    if (stakeInputValue) {
      let tableBody = getAPYStats(
        stakeInputValue,
        selectedToken?.quanitization,
        selectedToken?.symbol
      );
      setStakeTableData({ ...stakeTableData, body: tableBody });
    } else {
      setStakeTableData(getInitalStakeTableData(selectedFilterOption?.value));
    }
  }, [stakeInputValue, selectedFilterOption?.value]);

  const handleGetUnStakeHistory = async (isRefetch) => {
    setIsGetUnstakeDataProcessing(true);
    try {
      let unStakeRes = await getUnStakeList(isRefetch ? 1 : unstakePage);
      let appendData = [...unstakeData, ...unStakeRes?.payload?.results];
      const uniqData = uniqBy(appendData, 'unstake_uuid');
      setUnstakeData(uniqData);
      setUnstakeRes(unStakeRes);
      setUnstakePage((page) => (isRefetch ? 2 : page + 1));
      setIsGetUnstakeDataProcessing(false);
    } catch (error) {
      setIsGetUnstakeDataProcessing(false);
      console.log({ error });
    }
  };

  const resetState = () => {
    setUnstakeData([]);
    setUnstakeRes(null);
    setUnstakePage(1);
  };

  useEffect(() => {
    if (!isConnected || !userInfo?.payload?.tokens?.access) {
      resetState();
    }
    if (isUserLoggedIn && selectedFilterOption?.value === 'unstake') {
      handleGetUnStakeHistory(true);
    }
    return () => {
      resetState();
    };
  }, [
    isConnected,
    userInfo?.payload?.tokens?.access,
    selectedFilterOption?.value,
  ]);

  useEffect(() => {
    if (searchParams.get(stakeParam)) {
      let filteredOption = stakeOptions.find(
        (option) => option.value === searchParams.get(stakeParam)
      );
      if (filteredOption) {
        setSelectedFilterOption(filteredOption);
      }
    }

    return () => {
      setSelectedToken(null);
    };
  }, [searchParams]);

  if (isCoinDataLoading || isCoinDataError) {
    return <FallBackLoader />;
  }

  return (
    <div className={`flex flex-row stake-container page-main `}>
      <div
        className={`flex flex-column stake-left-container ${
          selectedFilterOption?.value === 'withdraw'
            ? ''
            : 'stake-container-wrapper'
        }`}
      >
        {isGetUnstakeDataProcessing && unstakeData?.length == 0 ? (
          <div className='flex flex-column items-center justify-center h-75 w-100'>
            <DataLoader
              msg='Fetching unstake data'
              addClassName='unstake-data-loader'
            />
          </div>
        ) : (
          <>
            {selectedFilterOption?.value === 'withdraw' ? (
              <StakeWithdrawalContainer showPopup={showPopup} />
            ) : (
              <>
                {selectedFilterOption.value === 'unstake' &&
                unstakeRes?.payload?.count > 0 ? (
                  <UnstakeInfoContainer
                    unstakeListRes={unstakeRes}
                    unstakeData={unstakeData}
                    isGetUnstakeDataProcessing={isGetUnstakeDataProcessing}
                    handleGetUnStakeHistory={handleGetUnStakeHistory}
                    selectedToken={selectedToken}
                  />
                ) : (
                  <APYInfoContainer
                    stakeInputValue={stakeInputValue}
                    selectedToken={selectedToken}
                    stakeTableData={stakeTableData}
                    selectedFilterOption={selectedFilterOption}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className='flex flex-column stake-right-container'>
        <StakeCard
          setStakeInputValue={setStakeInputValue}
          stakeInputValue={stakeInputValue}
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          isRequestModalOpen={isRequestModalOpen}
          setIsRequestModalOpen={setIsRequestModalOpen}
          requestModalMeta={requestModalMeta}
          setRequestModalMeta={setRequestModalMeta}
          steps={steps}
          setSteps={setSteps}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
          setSelectedFilterOption={setSelectedFilterOption}
          selectedFilterOption={selectedFilterOption}
          stakeOptions={stakeOptions}
          showPopup={showPopup}
          handleGetUnStakeHistory={handleGetUnStakeHistory}
        />
      </div>
      {isRequestModalOpen && (
        <Modal
          state={isRequestModalOpen}
          setState={setIsRequestModalOpen}
          modalClassName='stake-stepper-modal'
          disableClose={true}
        >
          <Stepper steps={steps} currentStepIndex={currentStepIndex} />
          <InfoSection
            text="Please don't refresh or close the tab or browser."
            containerClasName='mx-3'
          />
        </Modal>
      )}
    </div>
  );
}
export default Stake;
