import { useDispatch } from 'react-redux';
import { setModalState } from '../../slices/infoModalSlice';

function useInfoPopup() {
  const dispatch = useDispatch();

  const showPopup = (title, description, link, linkText) => {
    dispatch(
      setModalState({
        isModalOpen: true,
        title,
        description,
        link,
        linkText,
        type: 'success',
      })
    );
  };

  return { showPopup };
}

export default useInfoPopup;
