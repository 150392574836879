const PieChartLegend = ({ payload }) => {
  return (
    <ul className='custom-pie-charts'>
      {payload.map((entry, index) => {
        return (
          <li key={`item-${index}`} className='flex flex-row items-center'>
            <div
              className='custom-pie-charts-bg'
              style={{
                background: entry.payload.color,
                borderColor: entry.payload.borderColor,
              }}
            ></div>
            <div>
              <p>{entry.value}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default PieChartLegend;
