import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function LinkWithParams({ pathName, children, ...otherLinkProps }) {
  const location = useLocation();
  return (
    <Link
      {...otherLinkProps}
      to={{
        pathname: pathName,
        search: location.search,
      }}
    >
      {children}
    </Link>
  );
}

export default LinkWithParams;
