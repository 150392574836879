import React, { useEffect, useState } from 'react';
import GradientWrapper from '../../components/common/gradient-wrapper';
import { images } from '../../utils/images';
import LinkText from '../../components/common/link-text';
import InfoBox from '../../components/common/info-box';
import Button from '../../components/common/button';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { FaRegCopy, FaSquareXTwitter } from 'react-icons/fa6';
import toast from 'react-hot-toast';
import IconButton from '../../components/common/icon-buttton';
import OrbsTitle from '../../components/orbs/orbs-title';
import { setModalState } from '../../slices/loginModalSlice';
import ConnectWalletBtn from '../../components/common/connect-wallet-btn';
import useOrbs from '../../utils/hooks/useOrbs';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import useSegment from '../../utils/hooks/useSegment';
// import useCountdown from "../../utils/hooks/useCountDown";
// import ReferralPopup from "../../components/orbs/referral-popup";

function Orbs() {
  const { open } = useWeb3Modal();
  const { displayTextOrPlaceholder, isUserLoggedIn } = useUserAuthentication();
  const { sendTrackEvent } = useSegment();
  const {
    fetchOrbsData,
    orbsResult: { data: orbsData, isLoading: isOrbsLoading, error: errorOrbs },
    fetchReferralCodeData,
    referralCodeResult: {
      data: referralCodeData,
      isLoading: isReferralCodeLoading,
      error: errorReferralCode,
    },
  } = useOrbs();
  // const [isReferralPopupOpen, setIsReferralPopupOpen] = useState(false);
  // const campaignEndDate = '2024-09-29T14:25:30+05:30';
  // const countdown = useCountdown(campaignEndDate);

  useEffect(() => {
    if (isUserLoggedIn) {
      fetchOrbsData();
      fetchReferralCodeData();
    }
  }, [isUserLoggedIn]);

  const handleCopy = (referralURL) => {
    sendTrackEvent('referralCodeCopied', { code: referralCodeData });
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(referralURL)
        .then(() => {
          toast.success('Copied to clipboard, You can share the link');
        })
        .catch((err) => {
          console.log('Failed to copy: ', err);
        });
    } else {
      console.log('Clipboard API not supported');
    }
  };

  return (
    <div className='page-main'>
      <div className='orbs-main'>
        {/* <ReferralPopup isOpen={isReferralPopupOpen} closeModal={() => setIsReferralPopupOpen(false)} /> */}
        <GradientWrapper bodyClassName='orbs-campaign'>
          <OrbsTitle
            title={'Orbs Campaign'}
            secondComponent={
              <img className='banner-image' src={images.graphPin} />
            }
            descriptionComponent={
              <p>
                Stake $BTC to earn Orbs. <LinkText text='Learn More' link='#' />
              </p>
            }
          />
          <GradientWrapper bodyClassName='orbs-campaign-inner-container'>
            <InfoBox
              dataList={[
                { title: 'Season', value: '#1' },
                { title: 'Orbs issued', value: '11.4k' },
                { title: 'Season Countdown', value: '60D 4H 32M 5S' },
              ]}
            />
          </GradientWrapper>
        </GradientWrapper>
        <GradientWrapper bodyClassName='orbs-campaign your-orbs'>
          <OrbsTitle
            title={'Your orbs'}
            otherInnerContainerClassName={
              isUserLoggedIn ? 'wallet-connected' : ''
            }
            secondComponent={
              <>
                {!isUserLoggedIn ? (
                  <ConnectWalletBtn eventSource={'yourOrbs'} />
                ) : null}
              </>
            }
            descriptionComponent={
              <p>
                Rewards August 2024. <LinkText text='Learn More' link='#' />
              </p>
            }
          />
          <GradientWrapper bodyClassName='orbs-campaign-inner-container your-orbs-inner-container'>
            <InfoBox
              dataList={[
                {
                  title: 'Orbs collected',
                  value: displayTextOrPlaceholder(
                    !isOrbsLoading && orbsData
                      ? orbsData?.orbs_collected
                      : '----'
                  ),
                },
                {
                  title: 'Top',
                  value: displayTextOrPlaceholder(
                    !isOrbsLoading && orbsData && orbsData?.top_percentage
                      ? `${Number(orbsData?.top_percentage)?.toFixed(2)} %`
                      : '----'
                  ),
                },
              ]}
            />
          </GradientWrapper>
          <div className='orbs-earn'>
            <OrbsTitle title={'How to earn Orbs?'} />
            <GradientWrapper bodyClassName='orbs-earn-container'>
              <div className='flex flex-row justify-between content-center'>
                <h5>Stake $BTC</h5>
                <h5>Stake $1000 worth $BTC to earn 100 Orbs / Day</h5>
              </div>

              <div className='flex flex-row justify-between content-center mt-4'>
                <h5>Invite people to Multipli</h5>
                <h5>+10% Orbs / Day</h5>
              </div>

              <div className='referral-input mt-4 flex flex-row justify-between content-center'>
                <div className='self-center w-70'>
                  <input
                    value={
                      !isUserLoggedIn
                        ? ''
                        : `${window.location.origin}?referral_code=${
                            isReferralCodeLoading && !referralCodeData
                              ? 'Loading...'
                              : referralCodeData
                          }`
                    }
                    placeholder='Connect wallet to get your referral code'
                    readOnly={true}
                  />
                </div>
                {isUserLoggedIn ? (
                  <div className='referral-input-buttons'>
                    <IconButton
                      title={'Copy'}
                      btnClassName='btn-secondary'
                      iconComp={<FaRegCopy />}
                      onClick={() =>
                        handleCopy(
                          `${window.location.origin}?referral_code=${
                            isReferralCodeLoading && !referralCodeData
                              ? ''
                              : referralCodeData
                          }`
                        )
                      }
                    />
                    <IconButton
                      title={'Twitter'}
                      btnClassName='btn-primary'
                      onClick={() => {
                        sendTrackEvent('referralCodeShareTrigger', {
                          platform: 'Twitter',
                        });
                      }}
                      iconComp={<FaSquareXTwitter />}
                      // onClick={() => toast.success('referral code copied!')}
                    />
                  </div>
                ) : (
                  <ConnectWalletBtn
                    className='btn btn-primary btn-small'
                    eventSource={'ordsReferral'}
                  />
                )}
              </div>
            </GradientWrapper>
          </div>
        </GradientWrapper>
      </div>
    </div>
  );
}

export default Orbs;
