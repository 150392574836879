import React from 'react';
import { HOME_ROUTE } from '../constants';
import { routes } from '../routes';

const getQueryParams = (query = null) => {
  return (query || window.location.search.replace('?', ''))
    .split('&')
    .map((e) => e.split('=').map(decodeURIComponent))
    .reduce((result, [key, value]) => {
      // Validation: Ensure both key and value are not empty or undefined
      if (key && value !== undefined) {
        result[key] = value;
      }
      return result;
    }, {});
};
const returnRouteName = () => {
  const pathname = window.location.pathname;
  const routeName = pathname?.replace('/', '');
  let sourcePage = null;
  if (!routeName) {
    sourcePage = HOME_ROUTE;
  } else {
    sourcePage = routeName;
  }
  return sourcePage;
};

const getQueryParamsString = (extraQuery) => {
  const queryParams = getQueryParams();

  let updatedParams = {};
  if (Object.keys(queryParams).length) {
    updatedParams = {
      ...queryParams,
      ...extraQuery,
    };
  } else {
    updatedParams = {
      ...extraQuery,
    };
  }
  const searchParamsString = new URLSearchParams(updatedParams).toString();
  return searchParamsString;
};

function getPageNumberFromURL(url) {
  const urlObject = new URL(url);
  const pageNumber = urlObject.searchParams.get('page');
  return Number(pageNumber);
}
const normalizePath = (path) => {
  if (path !== '/') {
    return path.replace(/\/+$/, '');
  } else {
    return path;
  }
};

const isInRoutes = (pathname) => {
  const normalizedPathname = normalizePath(pathname);
  return routes.some(
    (route) => normalizePath(route.link) === normalizedPathname
  );
};

function useRoute() {
  return {
    getQueryParams,
    returnRouteName,
    getQueryParamsString,
    getPageNumberFromURL,
    normalizePath,
    isInRoutes,
  };
}

export default useRoute;
