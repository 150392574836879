import multipliLogoLarge from '../assets/images/logo_with_name.png';
import multipliLogoSM from '../assets/images/logo.svg';
import usdcLogo from '../assets/images/USDC.svg';
import graphPin from '../assets/images/graph-pin.png';
import arrowIcon from '../assets/images/arrow.png';
import binanceLogo from '../assets/images/binance_logo.svg';
import okxLogo from '../assets/images/okx_logo.svg';
import bitcoinLogo from '../assets/images/bitcoin_logo.svg';
import tetherLogo from '../assets/images/tether_logo.svg';
import cefuLogo from '../assets/images/cefu_logo.svg';
import copperLogo from '../assets/images/copper_logo.svg';
import telegramLogo from '../assets/images/telegram_logo.svg';
import discordLogo from '../assets/images/discord_logo.svg';
import twitterLogo from '../assets/images/twitter_logo.svg';
import gitBookLogo from '../assets/images/gitbook_logo.svg';
import menuIcon from '../assets/images/menu_icon.svg';
import closeIcon from '../assets/images/close_icon.svg';
import notFoundImage from '../assets/images/404.svg';
import profileImage from '../assets/images/profile.png';
import angleSmallDown from '../assets/images/angle-small-down.png';
import HTXLogo from '../assets/images/htx-logo.png';

export const images = {
  multipliLogoSM,
  multipliLogoLarge,
  usdcLogo,
  graphPin,
  arrowIcon,
  binanceLogo,
  okxLogo,
  bitcoinLogo,
  tetherLogo,
  cefuLogo,
  copperLogo,
  telegramLogo,
  twitterLogo,
  discordLogo,
  menuIcon,
  closeIcon,
  notFoundImage,
  profileImage,
  gitBookLogo,
  angleSmallDown,
  HTXLogo,
};

export default images;
