import React from 'react';
import { useLazyUserOrbsInfoQuery } from '../../slices/leaderboardSlice';
import { useSelector } from 'react-redux';

function useLeaderboard() {
  const [userOrbsInfoData] = useLazyUserOrbsInfoQuery();
  const { userInfo } = useSelector((state) => state.auth);

  const getUserOrbsData = async () => {
    try {
      const userOrbsData = await userOrbsInfoData({
        token: userInfo?.payload?.tokens?.access,
      }).unwrap();

      return userOrbsData;
    } catch (error) {
      throw error?.data?.message || error?.error;
    }
  };

  return { getUserOrbsData };
}

export default useLeaderboard;
