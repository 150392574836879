import React, { useEffect } from 'react';
import FallbackWrapper from '../../components/fallback-wrapper/fallback-wrapper';
import { useNavigate } from 'react-router-dom';
import { useGetHealthStatusMutation } from '../../slices/appSlice';
import { supportEmail } from '../../utils/constants';

function ServerDown() {
  const navigate = useNavigate();
  const [getHealthStatus] = useGetHealthStatusMutation();

  const checkHealthStatus = async () => {
    try {
      const response = await getHealthStatus().unwrap();
      navigate('/');
    } catch (error) {
      if (error?.status === 451) {
        navigate('/ip-fallback');
      }
    }
  };

  const handleButtonClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    checkHealthStatus();
  }, []);

  return (
    <FallbackWrapper
      title={'Oops! Something went wrong!'}
      handleButtonClick={handleButtonClick}
      buttonLabel={'Reload'}
    >
      <div className='fallback-text-div'>
        <p className='mt-3'>
          We've encountered an unexpected issue and are working hard to resolve
          it. Please try refreshing the page or coming back later.
        </p>
        <p className='mt-3'>
          If you're still facing problems, please contact our support team at{' '}
          <a href={`mailto:${supportEmail}`} className='support-link'>
            {supportEmail}
          </a>
          . We're here to help!
        </p>
      </div>
    </FallbackWrapper>
  );
}

export default ServerDown;
