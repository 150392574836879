import React from 'react';
import FallbackWrapper from '../../components/fallback-wrapper/fallback-wrapper';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/');
  };
  return (
    <FallbackWrapper
      title={'Oops! Page Not Found'}
      handleButtonClick={handleButtonClick}
      buttonLabel={'Go Home'}
    >
      <p className='mt-3'>
        Sorry, the page you are looking for does not exist.
      </p>
    </FallbackWrapper>
  );
}

export default NotFound;
