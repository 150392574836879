import { createSlice } from '@reduxjs/toolkit';
import { latestTransactionCacheKey } from '../utils/constants';

/*
Sample schema to store...

1.stake
{
    "amount": "0.000005000000000000",
    "created_at": "2024-08-26T09:23:15.029723Z",
    "currency": "usdt",
    "derived_currency": "xusdt",
    "stake_uuid": "3e1ce534-b602-4fb7-b557-3d33f8043c54",
    "staked_at": "2024-08-26T09:23:15.557244Z",
    "state": "COMPLETED",
    "uuid": "3e1ce534-b602-4fb7-b557-3d33f8043c54"
}

2.stake_deposit
{
    "amount": "0.000005000000000000",
    "created_at": "2024-08-26T09:23:15.029723Z",
    "currency": "usdt",
    "derived_currency": "xusdt",
    "stake_deposit_uuid": "3e1ce534-b602-4fb7-b557-3d33f8043c54",
    "staked_at": "2024-08-26T09:23:15.557244Z",
    "state": "COMPLETED",
    "uuid": "3e1ce534-b602-4fb7-b557-3d33f8043c54"
}
*/

const loadInitialState = () => {
  const storedData = localStorage.getItem(latestTransactionCacheKey);
  if (storedData) {
    return JSON.parse(storedData);
  }
  return {
    deposit: null,
    withdrawal: null,
    stake: null,
    stake_deposit: null,
  };
};

const initialState = {
  latestTransactionData: loadInitialState(),
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setLatestTransactionData: (state, action) => {
      const { type, data } = action.payload;
      if (type in state.latestTransactionData) {
        state.latestTransactionData[type] = data;
        localStorage.setItem(
          latestTransactionCacheKey,
          JSON.stringify(state.latestTransactionData)
        );
      }
    },
    clearLatestTransactionData: (state, action) => {
      const { type } = action.payload;
      if (type in state.latestTransactionData) {
        state.latestTransactionData[type] = null;
        localStorage.setItem(
          latestTransactionCacheKey,
          JSON.stringify(state.latestTransactionData)
        );
      }
    },
    clearTransactionData: (state, action) => {
      state.latestTransactionData = {
        deposit: null,
        withdrawal: null,
        stake: null,
        stake_deposit: null,
      };
      localStorage.removeItem(latestTransactionCacheKey);
    },
    // dispatch(removeTransactionByUUID({ type: 'deposit', uuid: 'some-uuid-here' }));
    removeTransactionByUUID: (state, action) => {
      const { type, uuid } = action.payload;
      if (
        type in state.latestTransactionData &&
        Array.isArray(state.latestTransactionData[type])
      ) {
        state.latestTransactionData[type] = state.latestTransactionData[
          type
        ].filter((item) => item.uuid !== uuid);
        if (state.latestTransactionData[type].length === 0) {
          state.latestTransactionData[type] = null;
        }
        localStorage.setItem(
          latestTransactionCacheKey,
          JSON.stringify(state.latestTransactionData)
        );
      }
    },
  },
});

export const {
  setLatestTransactionData,
  clearLatestTransactionData,
  clearTransactionData,
} = transactionSlice.actions;

export default transactionSlice.reducer;
