import React from 'react';
import { useGetPublicStatsQuery } from '../../slices/publicStatsSlice';
import useNumber from './useNumber';

function useTvlData() {
  // const {
  //   data: platformStatsData,
  //   isLoading: isPlatformStatsDataLoading,
  //   error: isPlatformStatsDataError,
  // } = useGetPlatformStatsQuery();

  const {
    data: publicStatsData,
    isLoading: isPublicStatsDataLoading,
    isError: isPublicStatsDataError,
  } = useGetPublicStatsQuery();

  const { convertToAbbreviation } = useNumber();

  let tvlDataList = [
    {
      title: 'TVL',
      value: '---',
      // img: images.usdcLogo,
      suffix: '($)',
    },
    {
      title: 'Yield',
      value: '---',
      // img: images.usdcLogo,
      suffix: '($)',
    },
    // {
    //   title: 'Yield',
    //   value: '33.27%',
    //   img: 'https://brine-mainnet-fe-assets.s3.ap-southeast-1.amazonaws.com/USDC.png',
    // },
  ];

  const getTVLData = () => {
    if (isPublicStatsDataLoading || isPublicStatsDataError) {
      return tvlDataList;
    }
    if (publicStatsData) {
      let data = [...tvlDataList];
      data[0].value = convertToAbbreviation(
        publicStatsData?.total_volume_locked_usd
      );
      data[1].value = convertToAbbreviation(
        publicStatsData?.total_yield_generated_usd
      );
      return data;
    }
    return tvlDataList;
  };

  return { getTVLData };
}

export default useTvlData;
