import React, { useState } from 'react';
import { images } from '../../utils/images';
import { useLocation } from 'react-router-dom';
import GradientWrapper from './gradient-wrapper';
import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers/react';
import InfoBox from './info-box';
import LoginModal from './login-modal';
import { useDispatch, useSelector } from 'react-redux';
import NavbarConnectWalletButton from './connect-wallet-button-wrapper';
import { routes } from '../../utils/routes';
import { blockExplorerUrl } from '../../utils/constants';
import InfoPopup from './info-popup';
import LinkWithParams from './link-with-params';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import useSegment from '../../utils/hooks/useSegment';
import useRoute from '../../utils/hooks/useRoute';
import useTvlData from '../../utils/hooks/useTvlData';

const navOptions = ['Open in Block Explorer', 'Log Out'];

function Navbar() {
  const { disconnect } = useDisconnect();
  const location = useLocation();
  // const [openAuthModal, setAuthOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { address } = useWeb3ModalAccount();
  const { isUserLoggedIn } = useUserAuthentication();
  const { sendTrackEvent } = useSegment();
  const { returnRouteName, normalizePath } = useRoute();
  const { performLogout } = useUserAuthentication();
  const { getTVLData } = useTvlData();

  const handleEventsDispatch = (eventType) => {
    const eventsource = 'Navbar' + '-' + returnRouteName();
    if (eventType === 'LOGOUT') {
      sendTrackEvent('disconnectWalletInitiated', {
        source: eventsource,
      });
    } else {
      sendTrackEvent('openInBlockExplorerClicked', {
        source: eventsource,
      });
    }
  };

  const handleNavDropdown = (item) => {
    closeMenu();
    if (item === navOptions[1]) {
      performLogout(dispatch);
      disconnect();
      handleEventsDispatch('LOGOUT');
    } else if (item === navOptions[0]) {
      handleEventsDispatch('BLOCK_EXPLORER');
      window.open(`${blockExplorerUrl}/address/${address}`);
    }
  };

  const closeMenu = () => {
    if (menuOpen) {
      setMenuOpen(!menuOpen);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className='navbar-container'>
        <div className='mobile-show w-100'>
          <div className={`mobile-nav`}>
            <LinkWithParams pathName={'/'}>
              <img src={images.multipliLogoLarge} alt='Mulitpli' />
            </LinkWithParams>
            <div className={'hamburger'} onClick={toggleMenu}>
              <img
                src={menuOpen ? images.closeIcon : images.menuIcon}
                alt='Menu'
              />
            </div>
          </div>
        </div>
        <div className={`menu ${menuOpen ? 'menu-open' : ''}`}>
          {routes.map((route) => (
            <LinkWithParams
              pathName={route.link}
              key={route.link}
              onClick={closeMenu}
              className={`mobile-nav-item nav-base-font ${
                route.link === normalizePath(location.pathname) ? 'active' : ''
              } `}
            >
              {route.name}
            </LinkWithParams>
          ))}
          <NavbarConnectWalletButton
            handleNavDropdown={handleNavDropdown}
            onClose={closeMenu}
            navOptions={navOptions}
          />
        </div>
        <div className={`logo-group mobile-hide`}>
          <LinkWithParams pathName={'/'}>
            <img src={images.multipliLogoLarge} alt='Mulitpli' />
          </LinkWithParams>
          {routes.map((route) => (
            <div key={route.link}>
              <LinkWithParams
                onClick={closeMenu}
                pathName={route.link}
                className={`nav-base-font ${
                  route.link === normalizePath(location.pathname)
                    ? 'active'
                    : ''
                } `}
              >
                {route.name}
              </LinkWithParams>
            </div>
          ))}
        </div>
        <div className={`info-group mobile-hide`}>
          <GradientWrapper
            bodyClassName={`tvl-info-gradient-container ${
              isUserLoggedIn ? 'logged-in' : ''
            }  nav-info-container`}
          >
            <InfoBox
              dataList={getTVLData()}
              containerClassName='tvl-info-container'
            />
          </GradientWrapper>
          <NavbarConnectWalletButton
            handleNavDropdown={handleNavDropdown}
            onClose={closeMenu}
            navOptions={navOptions}
          />
        </div>
      </div>
      <LoginModal />
      <InfoPopup />
    </>
  );
}

export default Navbar;
