export default function OrbsTitle({
  title,
  descriptionComponent,
  secondComponent,
  otherInnerContainerClassName,
}) {
  return (
    <div className='flex flex-row items-center justify-between orbs-campaign-title-container w-100'>
      <div
        className={
          otherInnerContainerClassName ? otherInnerContainerClassName : ''
        }
      >
        <h4>{title}</h4>
        {descriptionComponent}
      </div>
      {secondComponent ? secondComponent : null}
    </div>
  );
}
