import React from 'react';
import { AiOutlineInbox } from 'react-icons/ai';

function NotFound({ msg, addClassName }) {
  return (
    <div
      className={`flex flex-column items-center not-found ${
        addClassName ? addClassName : ''
      }`}
    >
      <AiOutlineInbox size={60} />
      <h5 className='mt-3'>{msg}</h5>
    </div>
  );
}

export default NotFound;
