import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { convertXCoin } from '../../utils/string-methods';
import useAppStats from '../../utils/hooks/useAppStats';

function UserBalance({
  isConnected,
  selectedToken,
  userSelectedTokenBalance,
  userSelectedTokenBlockChainBalance,
  userSelectedTokenMultipliBalance,
  selectedFilterOption,
}) {
  const { userInfo } = useSelector((state) => state.auth);
  const { formatValueWithSymbol } = useAppStats();
  return (
    <div className='user-balance-container'>
      {!isConnected || !userInfo?.payload?.tokens?.access ? (
        <p className='text-m text-medium text-active'>
          Balance: 0.00 {convertXCoin(selectedToken?.symbol?.toUpperCase())}
        </p>
      ) : (
        <>
          {userSelectedTokenBalance != null ? (
            <div className='flex flex-row items-center justify-center'>
              {selectedFilterOption?.value !== 'withdraw' ? (
                <>
                  <p className='text-m text-medium text-active'>
                    Balance:{' '}
                    {formatValueWithSymbol(
                      userSelectedTokenBalance,
                      selectedToken?.symbol
                    )}{' '}
                  </p>
                  <span data-tooltip-id='total-balance-tooltip'>
                    <AiOutlineInfoCircle size={12} />
                  </span>
                  <Tooltip id='total-balance-tooltip' place='bottom'>
                    <div className='user-balance-tooltip-content'>
                      <p>
                        Multipli Balance -{' '}
                        {formatValueWithSymbol(
                          userSelectedTokenMultipliBalance,
                          selectedToken?.symbol
                        )}{' '}
                      </p>
                      <p>
                        Blockchain Balance -{' '}
                        {formatValueWithSymbol(
                          userSelectedTokenBlockChainBalance,
                          selectedToken?.symbol
                        )}{' '}
                      </p>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <p className='text-m text-medium text-active'>
                  Multipli Balance:{' '}
                  {formatValueWithSymbol(
                    userSelectedTokenMultipliBalance,
                    selectedToken?.symbol
                  )}{' '}
                </p>
              )}
            </div>
          ) : (
            <p className='text-m text-medium text-active'>Loading Balance...</p>
          )}
        </>
      )}
    </div>
  );
}

export default UserBalance;
