import { BrowserProvider, JsonRpcProvider, ethers } from 'ethers';
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers/react';
import ERC20_ABI from '../abis/erc20_abi.json';
import STARKEX_ABI from '../abis/starkex_abi.json';
import {
  rpcUrl,
  starkContractAddress,
  supportedChainId,
  supportEmail,
} from '../constants';
import BigNumberUtils from '../../utils/stark-ex/bignumber-ethers-v5';
import {
  capitalizeFirstLetter,
  remove0x0Prefix,
  removeHexPrefix,
} from '../../utils/string-methods';
import { getKeyPairFromSignature } from '../stark-ex/app';
import { useSelector } from 'react-redux';
import SignatureUtils from '../../utils/stark-ex/signature';
import useSegment from './useSegment';
import * as Sentry from '@sentry/react';

let unknownError = `Something went wrong! Please contact ${supportEmail} for assistance.`;
let unexpectedError = `Something went wrong; please try again`;

const etherV6ErrorMap = {
  UNKNOWN_ERROR: {
    systemError: 'An unknown error occurred.',
    uiError: unknownError,
  },
  NOT_IMPLEMENTED: {
    systemError: 'This feature is not implemented yet.',
    uiError: unexpectedError,
  },
  UNSUPPORTED_OPERATION: {
    systemError: 'The operation is unsupported.',
    uiError: unexpectedError,
  },
  NETWORK_ERROR: {
    systemError: 'Unable to connect to the network.',
    uiError:
      'Unable to connect to the Ethereum network; please try again later.',
  },
  SERVER_ERROR: {
    systemError: 'An error occurred on the server.',
    uiError: 'Server error; please try again.',
  },
  TIMEOUT: {
    systemError: 'The request timed out.',
    uiError: 'Sorry; your request timed out; please try again.',
  },
  BAD_DATA: {
    systemError: 'The data provided is invalid or corrupted.',
    uiError: unexpectedError,
  },
  CANCELLED: {
    systemError: 'The operation was cancelled.',
    uiError: unexpectedError,
  },
  BUFFER_OVERRUN: {
    systemError: 'A buffer overrun error occurred.',
    uiError: unexpectedError,
  },
  NUMERIC_FAULT: {
    systemError: 'A numeric fault occurred.',
    uiError: unexpectedError,
  },
  INVALID_ARGUMENT: {
    systemError: 'The argument provided is invalid.',
    uiError: unexpectedError,
  },
  MISSING_ARGUMENT: {
    systemError: 'A required argument is missing.',
    uiError: unexpectedError,
  },
  UNEXPECTED_ARGUMENT: {
    systemError: 'An unexpected argument was provided.',
    uiError: unexpectedError,
  },
  VALUE_MISMATCH: {
    systemError: 'The provided value does not match the expected value.',
    uiError: unexpectedError,
  },
  CALL_EXCEPTION: {
    systemError: 'An exception occurred during the call.',
    uiError: unexpectedError,
  },
  INSUFFICIENT_FUNDS: {
    systemError: 'Insufficient funds for the transaction.',
    uiError: 'Insufficient funds in your wallet.',
  },
  NONCE_EXPIRED: {
    systemError: 'The transaction nonce has expired.',
    uiError: unexpectedError,
  },
  REPLACEMENT_UNDERPRICED: {
    systemError: 'The replacement transaction is underpriced.',
    uiError: unexpectedError,
  },
  TRANSACTION_REPLACED: {
    systemError: 'The transaction has been replaced.',
    uiError: unexpectedError,
  },
  UNCONFIGURED_NAME: {
    systemError: 'The name has not been configured.',
    uiError: unexpectedError,
  },
  OFFCHAIN_FAULT: {
    systemError: 'An off-chain fault occurred.',
    uiError: unexpectedError,
  },
  ACTION_REJECTED: {
    systemError: 'The action was rejected.',
    uiError: 'Request denied by user; please try again',
  },
};

// first sign request or second sign request
const getSignRequestEventName = (signType, base) =>
  signType === 'first' ? `first${base}` : `secondMessage${base}`;

function useWallet() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId } = useWeb3ModalAccount();
  const { signature } = useSelector((state) => state.auth);
  const { sendTrackEvent } = useSegment();

  const getOptimalProvider = () => {
    let provider;
    if (chainId && chainId == supportedChainId) {
      provider = new BrowserProvider(walletProvider);
      // console.log(`Using wallet RPC`);
    } else {
      provider = new JsonRpcProvider(rpcUrl);
      // console.log(`Using JSON RPC`);
    }
    return provider;
  };

  const onSignMessage = async (msg, signType) => {
    if (!msg) {
      throw 'Something went wrong, Please try again';
    }

    try {
      const initiateEventName = getSignRequestEventName(
        signType,
        'SignRequestInitiated'
      );
      sendTrackEvent(initiateEventName, {
        address: address,
      });
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const signature = await signer?.signMessage(msg);
      if (signature) {
        const successEventName = getSignRequestEventName(
          signType,
          'SignRequestSuccess'
        );
        sendTrackEvent(successEventName, {
          address: address,
        });
      }
      return signature;
    } catch (error) {
      if (error?.code === 'ACTION_REJECTED' || error?.code === 4001) {
        const eventName = getSignRequestEventName(
          signType,
          'SignRequestUserAbort'
        );
        sendTrackEvent(eventName, {
          address: address,
        });
      } else {
        const eventName = getSignRequestEventName(
          signType,
          'SignRequestFailure'
        );
        sendTrackEvent(eventName, {
          address: address,
          error: error?.shortMessage || error?.data?.message,
        });
      }
      throw formatEtherErrorMessage(error);
    }
  };

  const getUserBlockBalance = async (symbol, tokenAddress) => {
    try {
      if (walletProvider && address) {
        let provider = getOptimalProvider();
        if (symbol === 'eth') {
          // Get ETH balance
          const balance = await provider.getBalance(address);
          return {
            balance: ethers.formatEther(balance),
            symbol: symbol,
            token: tokenAddress,
          };
        } else {
          // Get ERC-20 token balance
          const tokenContract = new ethers.Contract(
            tokenAddress,
            ERC20_ABI,
            provider
          );
          const [tokenBalance, tokenDecimals] = await Promise.all([
            tokenContract.balanceOf(address),
            tokenContract.decimals(),
          ]);
          return {
            balance: ethers.formatUnits(tokenBalance, tokenDecimals),
            symbol: symbol,
            token: tokenAddress,
          };
        }
      }
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const getAllowance = async (
    tokenAddress,
    spenderAddress = starkContractAddress
  ) => {
    try {
      const provider = getOptimalProvider();
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ERC20_ABI,
        provider
      );
      const decimals = await tokenContract.decimals();
      const allowance = await tokenContract.allowance(address, spenderAddress);
      return ethers.formatUnits(allowance, decimals);
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const approveAllowance = async (
    tokenAddress,
    spenderAddress = starkContractAddress,
    amount = ethers.MaxUint256
  ) => {
    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      // console.log({ signer });
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ERC20_ABI,
        signer
      );
      const approveTx = await tokenContract.approve(spenderAddress, amount);
      await approveTx.wait();
      // console.log('Tokens approved');
      return approveTx;
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const getApprovalGasFees = async (
    tokenAddress,
    stakeInputValue,
    spenderAddress = starkContractAddress,
    amount = ethers.MaxUint256
  ) => {
    try {
      const allowance = await getAllowance(tokenAddress);
      const isAllowanceSufficient =
        Number(allowance) >= Number(stakeInputValue);

      if (isAllowanceSufficient) {
        return 0;
      }

      const provider = new ethers.BrowserProvider(walletProvider);
      const optimalProvider = getOptimalProvider();
      const signer = await provider.getSigner();

      const tokenContract = new ethers.Contract(
        tokenAddress,
        ERC20_ABI,
        signer
      );

      const gasEstimate = await tokenContract?.approve?.estimateGas(
        spenderAddress,
        amount
      );

      const gasPriceRes = await optimalProvider?.getFeeData();
      const gasPrice = gasPriceRes?.gasPrice;

      const totalGasFee = gasEstimate * gasPrice;
      return ethers.formatEther(totalGasFee?.toString());
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const getEstimatedGasFees = async (
    userStarkKey,
    selectedTokenInfo,
    vaultId
  ) => {
    const provider = new ethers.BrowserProvider(walletProvider);
    const optimalProvider = getOptimalProvider();

    const signer = await provider.getSigner();
    const starkContract = new ethers.Contract(
      starkContractAddress,
      STARKEX_ABI,
      signer
    );

    if (!starkContract || !selectedTokenInfo?.symbol) {
      return;
    }

    if (selectedTokenInfo && vaultId) {
      let amount = '0';
      try {
        const quantizedAmount = ethers.parseUnits(
          amount.toString(),
          Number(selectedTokenInfo?.quanitization)
        );

        let overrides = {
          value: quantizedAmount,
          nonce: getNonce(),
        };

        let functionGasFees;

        const gasPriceRes = await optimalProvider.getFeeData();
        const gasPrice = gasPriceRes?.gasPrice;

        if (selectedTokenInfo?.symbol === 'eth') {
          functionGasFees = await starkContract?.depositEth?.estimateGas(
            stark_key,
            selectedTokenInfo?.stark_asset_id,
            vaultId,
            overrides
          );
        } else {
          functionGasFees = await starkContract?.depositERC20?.estimateGas(
            userStarkKey,
            selectedTokenInfo?.stark_asset_id,
            vaultId,
            quantizedAmount
          );
        }

        if (functionGasFees) {
          const finalGasPrice = gasPrice * functionGasFees;
          return ethers.formatEther(finalGasPrice.toString());
        }
      } catch (error) {
        throw formatEtherErrorMessage(error);
      }
    }
  };

  const getNonce = async () => {
    const provider = new ethers.BrowserProvider(walletProvider);
    const signer = await provider.getSigner();
    let nonceOffset = 0;

    let baseNonce = provider.getTransactionCount(signer.getAddress());
    return baseNonce.then((nonce) => nonce + nonceOffset++);
  };

  const formatFeed = (
    gweiAmount,
    quantizedAmount,
    depositTransaction,
    userStarkKey,
    selectedTokenInfo,
    vaultId
  ) => {
    return {
      amount: (selectedTokenInfo?.symbol === 'eth'
        ? gweiAmount * 10
        : quantizedAmount
      ).toString(),
      stark_key: remove0x0Prefix(
        BigNumberUtils.BigNumber.from(userStarkKey).toHexString().toString()
      ),
      token_id: remove0x0Prefix(
        BigNumberUtils.BigNumber.from(selectedTokenInfo?.stark_asset_id)
          .toHexString()
          .toString()
      ),
      type: 'DepositRequest',
      vault_id: vaultId.toString(),
      deposit_blockchain_hash: depositTransaction.hash.toString(),
      deposit_blockchain_nonce: depositTransaction.nonce.toString(),
      data: depositTransaction?.data,
    };
  };

  const initiateDeposit = async (
    userStarkKey,
    vaultId,
    amount,
    selectedTokenInfo
  ) => {
    if (!userStarkKey || !vaultId || !amount) {
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const starkContract = new ethers.Contract(
        starkContractAddress,
        STARKEX_ABI,
        signer
      );
      const quantizedAmount = ethers.parseUnits(
        amount.toString(),
        Number(selectedTokenInfo?.quanitization)
      );
      const gweiAmount = ethers.formatUnits(quantizedAmount, 'gwei');
      let depositTransaction;
      if (selectedTokenInfo?.symbol === 'eth') {
        const overrides = {
          value: quantizedAmount,
          nonce: getNonce(),
        };
        depositTransaction = await starkContract.depositEth(
          userStarkKey,
          selectedTokenInfo?.stark_asset_id,
          vaultId,
          overrides
        );
      } else {
        depositTransaction = await starkContract.depositERC20(
          userStarkKey,
          selectedTokenInfo?.stark_asset_id,
          vaultId,
          quantizedAmount
        );
      }

      const transactionFeed = formatFeed(
        gweiAmount,
        quantizedAmount,
        depositTransaction,
        userStarkKey,
        selectedTokenInfo,
        vaultId
      );

      await depositTransaction.wait();
      return transactionFeed;
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const starkWithdrawal = async (assetId) => {
    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const starkContract = new ethers.Contract(
        starkContractAddress,
        STARKEX_ABI,
        signer
      );

      const res = await starkContract.withdraw(address, assetId);
      await res.wait();
      return res;
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const signMsgHash = (msgHash, nonce) => {
    let starkPublicKey = getKeyPairFromSignature(signature);
    let msgHex = BigNumberUtils.BigNumber.from(msgHash).toHexString();
    const msg = SignatureUtils.sign(starkPublicKey, removeHexPrefix(msgHex));
    return {
      msg_hash: removeHexPrefix(msgHex, true),
      signature: {
        r: `0x${msg.r.toString('hex')}`,
        s: `0x${msg.s.toString('hex')}`,
        recoveryParam: msg.recoveryParam,
      },
      nonce: nonce,
    };
  };

  const getStarkUserBalance = async (
    symbol,
    starkAssetId,
    blockchainDecimal
  ) => {
    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const starkContract = new ethers.Contract(
        starkContractAddress,
        STARKEX_ABI,
        signer
      );
      let res = await starkContract.getWithdrawalBalance(address, starkAssetId);
      let withdrawableBalance = Number(res);

      return {
        symbol,
        starkAssetId,
        amount:
          symbol === 'eth'
            ? ethers.formatEther(withdrawableBalance)
            : withdrawableBalance / Math.pow(10, blockchainDecimal),
      };
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const getUserBalances = async (coinsList) => {
    try {
      const balancePromises = coinsList.map(({ symbol, token_contract }) =>
        getUserBlockBalance(symbol, token_contract)
      );
      const balances = await Promise.all(balancePromises);
      return balances;
    } catch (error) {
      throw formatEtherErrorMessage(error);
    }
  };

  const formatEtherErrorMessage = (error) => {
    if (error?.code) {
      // Retrieve the error message from the map, defaulting to a generic unexpected error message
      sendTrackEvent('userEthersErrorLog', {
        errorString: etherV6ErrorMap[error.code]?.systemError,
        error: error,
      });
      return capitalizeFirstLetter(
        etherV6ErrorMap[error.code]?.uiError || unexpectedError
      );
    }
    if (error?.shortMessage) {
      // Capitalize the first letter of the short message
      return capitalizeFirstLetter(error.shortMessage);
    }
    // Return the error string itself if it is a string, otherwise return the default unexpected error message
    return typeof error === 'string'
      ? capitalizeFirstLetter(error)
      : unexpectedError;
  };

  async function getStarkDepositTransferredAmount(
    txHashOrData,
    decimal = 18,
    contractABI = STARKEX_ABI
  ) {
    let transaction;

    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      // If txHashOrData is a transaction hash, fetch the transaction
      if (typeof txHashOrData === 'string' && txHashOrData.startsWith('0x')) {
        transaction = await provider.getTransaction(txHashOrData);
      } else {
        // If txHashOrData is transaction data, use it directly
        transaction = txHashOrData;
      }
      const iface = new ethers.Interface(contractABI);

      // Attempt to decode the input data
      const decodedData = iface.parseTransaction({ data: transaction.data });
      if (decodedData.name === 'depositEth') {
        // For ETH deposits, the transferred amount is in the transaction value
        return ethers.formatEther(transaction.value);
      } else if (decodedData.name === 'depositERC20') {
        // For ERC20 deposits, the transferred amount is the last parameter (quantizedAmount)
        const quantizedAmount = decodedData.args[3];
        return ethers.formatUnits(quantizedAmount, Number(decimal));
      }
    } catch (error) {
      let customError = new Error(
        'Your staking has failed. Your funds should reflect in your Multipli balance within 5 minutes; if not, please reach out to support@multipli.fi.'
      );
      Sentry.captureMessage(
        `Failed to fetch the transferred amount from the Ethereum blockchain for transaction hash [${
          transaction?.deposit_blockchain_hash?.toString() ||
          transaction.hash?.toString()
        }]
        `
      );
      throw customError.message;
    }
  }

  return {
    onSignMessage,
    getUserBlockBalance,
    getAllowance,
    approveAllowance,
    initiateDeposit,
    starkWithdrawal,
    signMsgHash,
    getStarkUserBalance,
    getUserBalances,
    formatEtherErrorMessage,
    getEstimatedGasFees,
    getApprovalGasFees,
    getStarkDepositTransferredAmount,
  };
}

export default useWallet;
