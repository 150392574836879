import React from 'react';
import {
  IoIosInformationCircleOutline,
  IoMdCheckmarkCircleOutline,
} from 'react-icons/io';
import { FaRegCircleXmark } from 'react-icons/fa6';

// Extracted RenderIcon component to a separate function
const RenderIcon = ({ type }) => {
  switch (type) {
    case 'info':
      return (
        <IoIosInformationCircleOutline
          size={25}
          color='rgba(255, 255, 255, 0.5)'
        />
      );
    case 'success':
      return <IoMdCheckmarkCircleOutline size={25} color='#6ebd70' />;
    case 'failure':
      return <FaRegCircleXmark size={25} color='#ff5353' />;
    case 'loading':
      return (
        <div className='w-20px'>
          <div className='loader'></div>
        </div>
      );
    default:
      return null;
  }
};

// InfoSection component for displaying information with an icon
function InfoSection({ text, containerClassName, state = 'info' }) {
  return (
    <div
      className={`flex flex-row justify-center items-center gap-10 stake-info-container info-container ${
        containerClassName || ''
      }`}
    >
      <RenderIcon type={state} />
      <p className='text-m text-medium'>{text}</p>
    </div>
  );
}

export default InfoSection;
