import { apiSlice } from './apiSlice';

export const orbsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrbs: builder.query({
      query: ({ token }) => ({
        url: `/orbs/`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response) => {
        return response.payload;
      },
      providesTags: ['Orbs'],
    }),
    getReferralCode: builder.query({
      query: ({ token }) => ({
        url: `/referral/getCode/`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response) => {
        return response.payload.referral_code;
      },
      providesTags: ['ReferralCode'],
    }),
    createReferral: builder.mutation({
      query: ({ token, data }) => ({
        url: `/referral/createReferral/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useLazyGetOrbsQuery,
  useLazyGetReferralCodeQuery,
  useCreateReferralMutation,
} = orbsApiSlice;
