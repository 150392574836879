import React, { useEffect, useState } from 'react';
import Table from '../common/table';
import useHistory from '../../utils/hooks/useHistory';
import useStake from '../../utils/hooks/useStake';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import uniqBy from 'lodash/uniqBy';
import DataLoader from '../common/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import useNumber from '../../utils/hooks/useNumber';
import moment from 'moment';
import { convertXCoin, truncate } from '../../utils/string-methods';
import NotFound from '../common/not-found';
import useAppStats from '../../utils/hooks/useAppStats';
import { blockExplorerUrl } from '../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import useRoute from '../../utils/hooks/useRoute';
import { useSelector } from 'react-redux';
import { validUpdateStatuses } from '../../utils/hooks/usePrivateWebsocket';

function ArrowRightUpIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      viewBox='0 0 24 24'
    >
      <path d='M7 17L17 7'></path>
      <path d='M7 7L17 7 17 17'></path>
    </svg>
  );
}

const uniqByKey = {
  deposits: 'deposit_blockchain_hash',
  withdrawals: 'id',
  unstakes: 'unstake_uuid',
  stakes: 'stake_uuid',
  yield: 'yield_claim_uuid',
};

const amountKey = {
  deposits: 'unquantized_amount',
  withdrawals: 'amount',
  unstakes: 'amount',
  stakes: 'amount',
  yield: 'amount',
};

const activityHeaders = {
  deposits: [
    {
      logo: null,
      data: null,
      heading: 'Currency',
    },

    {
      logo: null,
      data: null,
      heading: 'Amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Date',
      isComponent: true,
    },
    {
      logo: null,
      data: null,
      heading: 'Tx Hash',
    },
    {
      logo: null,
      data: null,
      heading: 'Status',
    },
  ],
  withdrawals: [
    {
      logo: null,
      data: null,
      heading: 'Currency',
    },

    {
      logo: null,
      data: null,
      heading: 'Amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Date',
      isComponent: true,
    },
    {
      logo: null,
      data: null,
      heading: 'Status',
    },
  ],
  yield: [
    {
      logo: null,
      data: null,
      heading: 'Currency',
    },

    {
      logo: null,
      data: null,
      heading: 'Amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Date',
      isComponent: true,
    },
    {
      logo: null,
      data: null,
      heading: 'Status',
    },
  ],
  unstakes: [
    {
      logo: null,
      data: null,
      heading: 'Currency',
    },
    {
      logo: null,
      data: null,
      heading: 'Derived Currency',
    },
    {
      logo: null,
      data: null,
      heading: 'Amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Date',
      isComponent: true,
    },
    {
      logo: null,
      data: null,
      heading: 'Status',
    },
  ],
  stakes: [
    {
      logo: null,
      data: null,
      heading: 'Currency',
    },
    {
      logo: null,
      data: null,
      heading: 'Derived Currency',
    },
    {
      logo: null,
      data: null,
      heading: 'Amount',
    },
    {
      logo: null,
      data: null,
      heading: 'Date',
      isComponent: true,
    },
    {
      logo: null,
      data: null,
      heading: 'Status',
    },
  ],
};

const returnNotFoundMsgByType = (type) => {
  const messages = {
    stakes: {
      text: 'You haven’t staked any assets yet;',
      linkText: 'start staking now.',
      linkHref: '/?stake-tab=stake',
    },
    deposits: {
      text: 'You haven’t staked any assets yet;',
      linkText: 'start staking now.',
      linkHref: '/?stake-tab=stake',
    },
    unstakes: {
      text: 'You haven’t unstaked any assets yet;',
      linkText: 'unstake now.',
      linkHref: '/?stake-tab=unstake',
    },
    withdrawals: {
      text: 'You haven’t made a withdrawal yet;',
      linkText: 'withdraw now.',
      linkHref: '/?stake-tab=withdraw',
    },
  };

  const { text, linkText, linkHref } = messages[type] || {
    text: `No ${type} data found.`,
  };

  return (
    <NotFound
      addClassName='activity-not-found'
      msg={
        <p>
          {text} <Link to={linkHref}>{linkText}</Link>
        </p>
      }
    />
  );
};

function ActivityTable({ activityActiveTab, triggerHistoryFetch }) {
  const [data, setData] = useState([]);
  const [isFetchOperationLoading, setIsFetchOperationLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataHasMore, setDataHasMore] = useState(true);
  const {
    getListDepositData,
    getListWithdrawalData,
    getStakeList,
    getActivityStatus,
    getUnStakeList,
    getYieldClaimList,
  } = useHistory();
  const { isUserLoggedIn } = useUserAuthentication();
  const { untrailZeros } = useNumber();
  const { getInfoAboutCoins } = useAppStats();
  const navigate = useNavigate();
  const { getPageNumberFromURL } = useRoute();
  const { message, status: wsConnectionStatus } = useSelector(
    (state) => state.websocket
  );

  const returnFetchByType = async (type, page) => {
    if (type === 'deposits') {
      return await getListDepositData(page);
    } else if (type === 'withdrawals') {
      return await getListWithdrawalData(page);
    } else if (type === 'stakes') {
      return await getStakeList(page);
    } else if (type === 'unstakes') {
      return await getUnStakeList(page);
    } else if (type === 'yield') {
      return await getYieldClaimList(page);
    }
  };

  const handleFetchHistoryData = async (page = currentPage) => {
    if (activityActiveTab) {
      setIsFetchOperationLoading(true);
      try {
        let res = await returnFetchByType(
          activityActiveTab?.toLowerCase(),
          page
        );
        setDataHasMore(res?.payload?.has_next || res?.payload?.next?.length);
        let appendData = [...data, ...res?.payload?.results];
        if (page === 1) {
          appendData = [...res?.payload?.results];
        }
        const uniqData = uniqBy(
          appendData,
          uniqByKey[activityActiveTab?.toLowerCase()]
        );
        setData(uniqData);
        if (res?.payload?.has_next || res?.payload?.next?.length) {
          let page = getPageNumberFromURL(res?.payload?.next);
          setCurrentPage(page);
        } else {
          setCurrentPage(1);
        }
        setIsFetchOperationLoading(false);
      } catch (error) {
        setIsFetchOperationLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      handleFetchHistoryData(1);

      return () => {
        setCurrentPage(1);
        setData([]);
        setDataHasMore(true);
      };
    }
  }, [isUserLoggedIn, activityActiveTab, triggerHistoryFetch]);

  useEffect(() => {
    if (wsConnectionStatus === 'CONNECTED' && isUserLoggedIn) {
      const shouldFetchData = () => {
        let { deposit, stake, stake_deposit } = message;
        if (
          deposit &&
          deposit.blockchain_deposit_status === 'Success' &&
          deposit.brine_deposit_status === 'Success' &&
          activityActiveTab === 'Deposits'
        ) {
          return true;
        }
        if (
          stake_deposit &&
          validUpdateStatuses.includes(stake_deposit?.state) &&
          activityActiveTab === 'Stakes'
        ) {
          return true;
        }
        return false;
      };
      if (shouldFetchData()) {
        handleFetchHistoryData(1);
      }
    }
  }, [message, activityActiveTab, wsConnectionStatus, isUserLoggedIn]);

  return (
    <Table addClassName='activity-body'>
      <Table.Header>
        {activityHeaders[activityActiveTab?.toLowerCase()]?.map(
          (item, index) => {
            return (
              <Table.Entry key={index}>
                <span className='table-data-text'>{item?.heading}</span>
              </Table.Entry>
            );
          }
        )}
      </Table.Header>
      <Table.Body>
        <>
          {isFetchOperationLoading && data?.length === 0 ? (
            <div className='mt-5 mb-5 data-loader-empty'>
              <DataLoader
                msg={`Fetching ${activityActiveTab?.toLowerCase()} data`}
              />
            </div>
          ) : (
            <>
              <>
                {!isFetchOperationLoading && data.length === 0 ? (
                  <div className='mt-5 mb-5 data-loader-empty'>
                    {/* <NotFound
                      msg={`No ${activityActiveTab?.toLowerCase()} data found`}
                    /> */}
                    {returnNotFoundMsgByType(activityActiveTab?.toLowerCase())}
                  </div>
                ) : (
                  <>
                    <InfiniteScroll
                      hasMore={dataHasMore}
                      dataLength={data.length}
                      next={handleFetchHistoryData}
                      loader={
                        <div className='mt-2 mb-2'>
                          <DataLoader />
                        </div>
                      }
                      height={500}
                    >
                      {data?.map((activityItem, idx) => {
                        return (
                          <Table.Row key={idx}>
                            <Table.Entry>
                              <Table.EntryLogo
                                src={
                                  getInfoAboutCoins(
                                    activityItem?.currency?.toLowerCase() ||
                                      activityItem?.token?.toLowerCase() ||
                                      activityItem?.token_id?.toLowerCase()
                                  )?.logo
                                }
                                alt=''
                              ></Table.EntryLogo>
                              {activityItem?.currency?.toUpperCase() ||
                                activityItem?.token?.toUpperCase() ||
                                activityItem?.token_id?.toUpperCase()}
                            </Table.Entry>
                            {(activityActiveTab === 'Stakes' ||
                              activityActiveTab === 'Unstakes') && (
                              <Table.Entry>
                                <Table.EntryLogo
                                  src={
                                    getInfoAboutCoins(
                                      activityItem?.derived_currency
                                    )?.logo
                                  }
                                  alt=''
                                ></Table.EntryLogo>
                                {convertXCoin(
                                  activityItem?.derived_currency?.toUpperCase()
                                )}
                              </Table.Entry>
                            )}
                            <Table.Entry>
                              {untrailZeros(
                                activityItem[
                                  amountKey[activityActiveTab?.toLowerCase()]
                                ]
                              )}{' '}
                              {activityItem?.currency?.toUpperCase() ||
                                activityItem?.token?.toUpperCase() ||
                                activityItem?.token_id?.toUpperCase()}
                            </Table.Entry>
                            <Table.Entry>
                              {moment(activityItem?.created_at).format('LLL')}
                            </Table.Entry>
                            {activityActiveTab === 'Deposits' && (
                              <Table.Entry addClassName='tx-hash'>
                                <span>
                                  <a
                                    href={`${blockExplorerUrl}/tx/${activityItem?.deposit_blockchain_hash}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {truncate(
                                      activityItem?.deposit_blockchain_hash,
                                      20
                                    )}
                                  </a>
                                </span>
                              </Table.Entry>
                            )}
                            <Table.Entry
                              addClassName={`activity-status ${
                                getActivityStatus(
                                  activityActiveTab?.toLowerCase(),
                                  activityItem
                                )?.className || ''
                              }`}
                            >
                              {getActivityStatus(
                                activityActiveTab?.toLowerCase(),
                                activityItem
                              )?.text === 'READY' ? (
                                <span
                                  className='ready-button'
                                  onClick={() =>
                                    navigate('/?stake-tab=withdraw')
                                  }
                                >
                                  {
                                    getActivityStatus(
                                      activityActiveTab?.toLowerCase(),
                                      activityItem
                                    )?.text
                                  }
                                  <ArrowRightUpIcon />
                                </span>
                              ) : (
                                <span>
                                  {
                                    getActivityStatus(
                                      activityActiveTab?.toLowerCase(),
                                      activityItem
                                    )?.text
                                  }
                                </span>
                              )}
                            </Table.Entry>
                          </Table.Row>
                        );
                      })}
                    </InfiniteScroll>
                  </>
                )}
              </>
            </>
          )}
        </>
      </Table.Body>
    </Table>
  );
}

export default ActivityTable;
