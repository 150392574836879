import { useEffect, useState, useRef, forwardRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import images from '../../utils/images';

export const DropdownItem = ({ children, onClick }) => {
  return (
    <div className='dropdown-item' onClick={onClick}>
      {children}
    </div>
  );
};

const DropdownContent = forwardRef((props, ref) => {
  const { children, open, top } = props;
  return (
    <div
      className={`dropdown-content ${open ? 'content-open' : null}`}
      style={{ top: top ? `${top}px` : '100%' }}
      ref={ref}
    >
      {children}
    </div>
  );
});

const DropdownButton = forwardRef((props, ref) => {
  const { children, toggle, open } = props;
  return (
    <div
      onClick={toggle}
      className={`dropdown-btn ${open ? 'button-open' : null}`}
      ref={ref}
    >
      {children}
      <span className={`toggle-icon ${open ? 'open' : ''}`}>
        {/* {open ? (
          <FaChevronUp className='toggle-icon-svg' />
        ) : (
          <FaChevronDown className='toggle-icon-svg' />
        )} */}
        <img src={images.angleSmallDown} />
      </span>
    </div>
  );
});

const Dropdown = ({ buttonText, content, buttonComp, dropdownClassName }) => {
  const [open, setOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);

  const dropdownRef = useRef();
  const buttonRef = useRef();
  const contentRef = useRef();

  const toggleDropdown = () => {
    if (!open) {
      const spaceRemaining =
        window.innerHeight - buttonRef.current.getBoundingClientRect().bottom;
      const contentHeight = contentRef.current.clientHeight;

      const topPosition =
        spaceRemaining > contentHeight
          ? null
          : -(contentHeight - spaceRemaining); // move up by height clipped by window
      setDropdownTop(topPosition);
    }
    setOpen((open) => !open);
  };

  useEffect(() => {
    const handler = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${dropdownClassName ? dropdownClassName : ''}`}
    >
      <DropdownButton ref={buttonRef} toggle={toggleDropdown} open={open}>
        {buttonComp ? buttonComp : buttonText}
      </DropdownButton>
      <DropdownContent top={dropdownTop} ref={contentRef} open={open}>
        {typeof content === 'function' ? content(toggleDropdown) : content}
      </DropdownContent>
    </div>
  );
};

export default Dropdown;
