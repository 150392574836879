import React, { useEffect, useRef, useState } from 'react';
import GradientWrapper from '../../components/common/gradient-wrapper';
import InfoBox from '../../components/common/info-box';
import HeadingContainer from '../../components/heading-container/heading-container';
import useWallet from '../../utils/hooks/useWallet';
import useHoldings from '../../utils/hooks/useHoldings';
import { useWalletInfo, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useGetCoinStatsQuery } from '../../slices/appSlice';
import { activityParam, allowedStakeCoins } from '../../utils/constants';
import useAppStats from '../../utils/hooks/useAppStats';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import TabNav from '../../components/common/tab-nav';
import ActivityTable from '../../components/holding/activity-table';
import toast from 'react-hot-toast';
import { StakeHoldings } from '../../components/holding/stake-holdings';
import { UnstakeHoldings } from '../../components/holding/unstake-holdings';
import useSegment from '../../utils/hooks/useSegment';
import useRoute from '../../utils/hooks/useRoute';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/string-methods';
import { useSelector } from 'react-redux';

// const custodiansData = [
//   { logo: images.cefuLogo, address: '0x9cF7073488024f8A32FD75F3f2893' },
//   {
//     logo: images.copperLogo,
//     address: '0x9cF7073488024f8A32FD75F3f2893',
//   },
// ];

const activityTabs = ['Deposits', 'Withdrawals', 'Stakes', 'Unstakes', 'Yield'];

function Holdings() {
  const { getUserBalances } = useWallet();
  const { formatValueWithSymbol } = useAppStats();
  const { isConnected } = useWeb3ModalAccount();
  const { data: coinData } = useGetCoinStatsQuery();
  const [userBlockBalanceList, setUserBlockBalance] = useState([]);
  const [isUserBlockBalanceFetchLoading, setIsUserBlockBalanceFetchLoading] =
    useState(false);
  const { isUserLoggedIn } = useUserAuthentication();
  const { walletInfo } = useWalletInfo();
  // const { getListDepositData, getListWithdrawalData, getStakeList } =
  //   useHistory();
  const [activityActiveTab, setActivityActiveTab] = useState(null);
  const [triggerHistoryFetch, setTriggerHistoryFetch] = useState(false);
  const {
    getStakedHoldingList,
    getUnstakedHoldingList,
    getHoldingPlaceHolderData,
    yieldInfoDataError,
    fetchUserBalanceError,
  } = useHoldings();
  const [stakedCoinsList, setStakedCoinsList] = useState([]);
  const [unstakedCoinsList, setUnStakedCoinsList] = useState([]);
  const [isHoldingFetchOperationLoading, setIsHoldingFetchOperationLoading] =
    useState(false);
  const { sendTrackEvent } = useSegment();
  const { getQueryParamsString } = useRoute();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const activitySectionRef = useRef(null);
  const { message, status: wsConnectionStatus } = useSelector(
    (state) => state.websocket
  );

  const scrollToSection = () => {
    activitySectionRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
    setTimeout(() => {
      const newParams = new URLSearchParams(query);
      newParams.delete('scroll-history');
      setQuery(newParams);
    }, 0);
  };

  const handleGetHoldingsData = async () => {
    setIsHoldingFetchOperationLoading(true);
    try {
      let stakeHoldingList = await getStakedHoldingList();
      let unstakeHoldingList = await getUnstakedHoldingList();

      setUnStakedCoinsList(unstakeHoldingList);
      setStakedCoinsList(stakeHoldingList);
      setIsHoldingFetchOperationLoading(false);
    } catch (error) {
      toast.error(error);
      setIsHoldingFetchOperationLoading(false);
    }
  };

  function handleGetUserHoldings() {
    if (isUserLoggedIn && !fetchUserBalanceError && !yieldInfoDataError) {
      handleGetHoldingsData();
    } else {
      let stakeData = getHoldingPlaceHolderData('stake');
      let unstakeData = getHoldingPlaceHolderData('unstake');
      setStakedCoinsList(stakeData);
      setUnStakedCoinsList(unstakeData);
    }
  }

  useEffect(() => {
    handleGetUserHoldings();
  }, [isUserLoggedIn, fetchUserBalanceError, yieldInfoDataError]);

  // const handleGetDepositHistory = async () => {
  //   try {
  //     let depositRes = await getListDepositData(1);
  //     console.log({ depositRes });
  //   } catch (e) {
  //     console.log({ e });
  //   }
  // };

  // const handleGetStakeHistory = async () => {
  //   try {
  //     let stakeListRes = await getStakeList(1);
  //     console.log({ stakeListRes });
  //   } catch (e) {
  //     console.log({ e });
  //   }
  // };

  // const handleGetWithdrawal = async () => {
  //   try {
  //     let withdrawRes = await getListWithdrawalData(1);
  //     console.log({ withdrawRes });
  //   } catch (e) {
  //     console.log({ e });
  //   }
  // };

  const handleGetUserBlockBalance = async () => {
    setIsUserBlockBalanceFetchLoading(true);
    try {
      let balanceList = await getUserBalances(coinData?.stakeCoinList);
      let formattedData = balanceList.map((balanceInfo) => {
        return {
          title: `${balanceInfo?.symbol?.toUpperCase()} Balance`,
          value: `${formatValueWithSymbol(
            balanceInfo?.balance,
            balanceInfo?.symbol
          )}`,
        };
      });
      setUserBlockBalance(formattedData);
      setIsUserBlockBalanceFetchLoading(false);
    } catch (error) {
      setIsUserBlockBalanceFetchLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    if (coinData?.stakeCoinList) {
      if (isUserLoggedIn) {
        handleGetUserBlockBalance();
      }
    }
  }, [coinData?.stakeCoinList, isUserLoggedIn]);

  const getPlaceholderBalances = (coins, isLoading) => {
    return coins.map((coin) => ({
      title: `${coin?.toUpperCase()} Balance`,
      value: isLoading ? 'Loading..' : '---',
    }));
  };
  const handleChangeActivityTab = (currentTab) => {
    sendTrackEvent('activityTabChangeToggled', {
      tab: currentTab,
    });
    const searchParamsString = getQueryParamsString({
      [activityParam]: currentTab?.toLowerCase(),
    });
    navigate(`/holdings?${searchParamsString}`);
  };

  useEffect(() => {
    if (location.search) {
      let currenctTab = query.get(activityParam);

      if (
        activityTabs
          .map((item) => item.toLowerCase())
          .includes(currenctTab?.toLowerCase())
      ) {
        setActivityActiveTab(capitalizeFirstLetter(currenctTab));
      } else {
        setActivityActiveTab('Deposits');
      }
    } else {
      setActivityActiveTab('Deposits');
    }
  }, [location]);

  useEffect(() => {
    if (location.search) {
      let isScrollSection = query.get('scroll-history');
      if (isScrollSection) {
        scrollToSection();
      }
    }
  }, [location, activitySectionRef?.current]);

  useEffect(() => {
    if (wsConnectionStatus === 'CONNECTED' && isUserLoggedIn) {
      const shouldFetchData = () => {
        let { stake_deposit } = message;
        if (stake_deposit && stake_deposit.state === 'COMPLETED') {
          return true;
        }
        return false;
      };
      if (shouldFetchData()) {
        // alert('refetching after websocket events');
        handleGetUserHoldings();
      }
    }
  }, [message, wsConnectionStatus, isUserLoggedIn]);

  return (
    <div className='page-main'>
      <div className='holdings'>
        <HeadingContainer
          title={'Assets in your wallet'}
          subHeading={`View your asset in ${
            walletInfo?.name || 'your wallet'
          }.`}
          statusIndicator={false}
        />
        <div className='flex flex-row items-center justify-between user-block-balance-container mt-4'>
          <GradientWrapper bodyClassName='user-block-balance'>
            <InfoBox
              dataList={
                userBlockBalanceList?.length &&
                isUserLoggedIn &&
                !isUserBlockBalanceFetchLoading
                  ? userBlockBalanceList
                  : getPlaceholderBalances(
                      allowedStakeCoins,
                      isUserBlockBalanceFetchLoading
                    )
              }
            />
          </GradientWrapper>
        </div>
        <StakeHoldings
          isHoldingFetchOperationLoading={isHoldingFetchOperationLoading}
          stakedCoinsList={stakedCoinsList}
          handleGetHoldingsData={handleGetHoldingsData}
          triggerHistoryFetch={triggerHistoryFetch}
          setTriggerHistoryFetch={setTriggerHistoryFetch}
        />
        <div className='mt-4'>
          <UnstakeHoldings
            isHoldingFetchOperationLoading={isHoldingFetchOperationLoading}
            unstakedCoinsList={unstakedCoinsList}
          />
          {/* <CustodiansContainer data={custodiansData} /> */}
          {isUserLoggedIn && (
            <div>
              <HeadingContainer
                title={'Activity'}
                subHeading={'View your activity.'}
                statusIndicator={false}
              />
              <div className='mt-4' ref={activitySectionRef}>
                <TabNav
                  data={activityTabs}
                  addContainerClassName='activity-tab tablet-hide'
                  setCurrentTab={setActivityActiveTab}
                  onChange={handleChangeActivityTab}
                  currentTab={activityActiveTab}
                />
                <div className='activity-table'>
                  <ActivityTable
                    activityActiveTab={activityActiveTab}
                    triggerHistoryFetch={triggerHistoryFetch}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Holdings;
