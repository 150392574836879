// // websocketMiddleware.js
import { setStatus, setMessage, clearData } from '../slices/websocketSlice';
import { supportedWSEvents, wsbaseUrl } from '../utils/constants';
import * as Sentry from '@sentry/react';

// let socket = null;
// let isConnecting = false;

// const connectWebSocket = (store) => {
//   if (socket !== null || isConnecting) return;

//   isConnecting = true;
//   const { auth } = store.getState();
//   const jwt = auth.jwt;

//   socket = new WebSocket(
//     `wss://api-testnet.multiplis.fi/private?auth_header=${jwt}`
//   );

//   socket.onopen = () => {
//     store.dispatch(setStatus('CONNECTED'));
//     isConnecting = false;
//   };

//   socket.onclose = () => {
//     store.dispatch(setStatus('DISCONNECTED'));
//     socket = null;
//     isConnecting = false;
//   };

//   socket.onerror = (error) => {
//     console.error('WebSocket error:', error);
//     store.dispatch(setStatus('ERROR'));
//     isConnecting = false;
//   };

//   socket.onmessage = (event) => {
//     const data = JSON.parse(event.data);
//     if (data.stake)
//       store.dispatch(setMessage({ type: 'stake', data: data.stake }));
//     if (data.deposit)
//       store.dispatch(setMessage({ type: 'deposit', data: data.deposit }));
//     if (data.withdrawal)
//       store.dispatch(setMessage({ type: 'withdrawal', data: data.withdrawal }));
//   };
// };

// const websocketMiddleware = (store) => (next) => (action) => {
//   switch (action.type) {
//     case 'WEBSOCKET_CONNECT':
//       connectWebSocket(store);
//       break;

//     case 'WEBSOCKET_DISCONNECT':
//       if (socket !== null) {
//         socket.close();
//         socket = null;
//       }
//       store.dispatch(clearData());
//       break;

//     default:
//       return next(action);
//   }
// };

// export default websocketMiddleware;

// websocketMiddleware.js

let socket = null;
let isConnecting = false;
let retryCount = 0;
const MAX_RETRIES = 3;
const RETRY_INTERVAL = 5000; // 5 seconds

const connectWebSocket = (store) => {
  const { auth } = store.getState();
  const jwt = auth?.userInfo?.payload?.tokens?.access;

  if (socket !== null || isConnecting || !jwt) return;

  isConnecting = true;

  socket = new WebSocket(`${wsbaseUrl}/private?auth_header=${jwt}`);

  socket.onopen = () => {
    store.dispatch(setStatus('CONNECTED'));
    isConnecting = false;
    retryCount = 0; // Reset retry count on successful connection
    // Subscribe to the 'supportedWSEvents[]' stream
    const subscribeMessage = JSON.stringify({
      event: 'subscribe',
      streams: supportedWSEvents,
    });

    socket.send(subscribeMessage);
  };

  socket.onclose = (event) => {
    store.dispatch(setStatus('DISCONNECTED'));
    socket = null;
    isConnecting = false;

    if (!event.wasClean && retryCount < MAX_RETRIES) {
      retryCount++;
      store.dispatch(setStatus(`RETRYING (${retryCount}/${MAX_RETRIES})`));
      setTimeout(() => connectWebSocket(store), RETRY_INTERVAL);
    } else if (retryCount >= MAX_RETRIES) {
      Sentry.captureMessage(
        `PRIVATE WS CONNECTION FAILED AFTER MAX_RETRIES[${MAX_RETRIES}]`
      );
      store.dispatch(setStatus('CONNECTION_FAILED'));
    }
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
    Sentry.captureMessage(
      `PRIVATE WS CONNECTION FAILED - ${JSON.stringify(error)}`
    );
    store.dispatch(setStatus('ERROR'));
    isConnecting = false;
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data?.stake)
      store.dispatch(setMessage({ type: 'stake', data: data.stake }));
    if (data?.deposit)
      store.dispatch(setMessage({ type: 'deposit', data: data.deposit }));
    if (data?.withdrawal)
      store.dispatch(setMessage({ type: 'withdrawal', data: data.withdrawal }));
    if (data?.stake_deposit)
      store.dispatch(
        setMessage({ type: 'stake_deposit', data: data.stake_deposit })
      );
  };
};

const websocketMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'WEBSOCKET_CONNECT':
      retryCount = 0; // Reset retry count before attempting to connect
      connectWebSocket(store);
      break;

    case 'WEBSOCKET_DISCONNECT':
      console.log({ socket });
      if (socket !== null) {
        socket.close();
        socket = null;
      }
      retryCount = MAX_RETRIES; // Prevent further auto-retries
      store.dispatch(clearData());
      break;

    case 'WEBSOCKET_RECONNECT':
      if (socket !== null) {
        socket.close();
        socket = null;
      }
      retryCount = 0; // Reset retry count for manual reconnection
      connectWebSocket(store);
      break;

    default:
      return next(action);
  }
};

export default websocketMiddleware;
