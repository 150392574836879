import { createSlice } from '@reduxjs/toolkit';
import { cachePrefixKey } from '../utils/constants';

const initialState = {
  userInfo: localStorage.getItem(`${cachePrefixKey}-userInfo`)
    ? JSON.parse(localStorage.getItem(`${cachePrefixKey}-userInfo`))
    : null,
  signature: localStorage.getItem(`${cachePrefixKey}-user-eth-signature`)
    ? JSON.parse(localStorage.getItem(`${cachePrefixKey}-user-eth-signature`))
    : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem(
        `${cachePrefixKey}-userInfo`,
        JSON.stringify(action.payload)
      );
    },
    setSignatureCredentials: (state, action) => {
      state.signature = action.payload;
      localStorage.setItem(
        `${cachePrefixKey}-user-eth-signature`,
        JSON.stringify(action.payload)
      );
    },
    logout: (state, action) => {
      state.userInfo = null;
      state.signature = null;
      localStorage.clear();
    },
  },
});

export const { setLoginCredentials, setSignatureCredentials, logout } =
  authSlice.actions;
export default authSlice.reducer;
