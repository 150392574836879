import React, { useEffect, useState } from 'react';
import GradientWrapper from '../../components/common/gradient-wrapper';
import InfoBox from '../../components/common/info-box';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import HeadingContainer from '../../components/heading-container/heading-container';
import InfoTable from '../../components/info-table/info-table';
import useLeaderboard from '../../utils/hooks/useLeaderboard';
import toast from 'react-hot-toast';
import { useOrbsLeaderboardInfoQuery } from '../../slices/leaderboardSlice';
import FallBackLoader from '../../components/common/fallback-loader';
import DataLoader from '../../components/common/loader';
import NotFound from '../../components/common/not-found';

const headers = [
  {
    logo: null,
    data: 'Rank',
    heading: null,
    accessor: 'rank',
  },
  {
    logo: null,
    data: 'Address',
    heading: null,
    accessor: 'masked_eth_address',
  },
  {
    logo: null,
    data: 'Orbs Earned',
    heading: null,
    accessor: 'orbs',
  },
  {
    logo: null,
    data: 'Holdings (USDC)',
    heading: null,
    accessor: 'volume_staked',
  },
];

function Leaderboard() {
  const { isConnected } = useWeb3ModalAccount();
  const { isUserLoggedIn, displayTextOrPlaceholder } = useUserAuthentication();
  const { getUserOrbsData } = useLeaderboard();

  const {
    data: orbsLeaderBoardData,
    isLoading: orbsLeaderboardLoading,
    refetch: refetchOrbsLeaderBoardData,
  } = useOrbsLeaderboardInfoQuery(50);

  const [userOrbsData, setUserOrbsData] = useState(null);
  const [userOrbsDataLoading, setUserOrbsDataLoading] = useState(null);

  const handleUserOrbsData = async () => {
    try {
      setUserOrbsDataLoading(true);
      const userOrbsData = await getUserOrbsData();
      setUserOrbsData(userOrbsData?.payload);
      setUserOrbsDataLoading(false);
    } catch (error) {
      setUserOrbsDataLoading(false);
      console.log({ error });
      toast.error(
        'Something went wrong while fetching your rank and orbs data, please try again later.'
      );
    }
  };

  useEffect(() => {
    refetchOrbsLeaderBoardData();
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (isUserLoggedIn) {
      handleUserOrbsData();
    }
  }, [isUserLoggedIn]);

  const getTableData = () => {
    let data = {};
    data.headers = headers;
    data.body = orbsLeaderBoardData.payload;

    return data;
  };

  return (
    <div className='page-main'>
      <div className='leaderboard'>
        <div className='flex flex-row items-center justify-between leaderboard-campaign-container'>
          <GradientWrapper bodyClassName='leaderboard-campaign-inner-container'>
            <InfoBox
              dataList={[
                { title: 'Season', value: '#1' },
                { title: 'Orbs issued', value: '11.4k' },
                { title: 'Season Countdown', value: '60D 4H 32M 5S' },
              ]}
            />
          </GradientWrapper>
          <GradientWrapper
            bodyClassName={`leaderboard-campaign-inner-container ${
              isConnected ? '' : 'invisible'
            }`}
          >
            <InfoBox
              dataList={[
                {
                  title: 'Your Rank',
                  value: userOrbsDataLoading
                    ? 'Loading...'
                    : displayTextOrPlaceholder(
                        userOrbsData?.rank && userOrbsData?.orbs_collected
                          ? `#${userOrbsData?.rank}`
                          : '---'
                      ),
                },
                {
                  title: 'Holdings',
                  value: userOrbsDataLoading
                    ? 'Loading...'
                    : displayTextOrPlaceholder(
                        userOrbsData?.volume_staked || '---'
                      ),
                },
              ]}
            />
          </GradientWrapper>
        </div>
        <div className='mt-5'>
          <HeadingContainer
            title={'Leaderboard'}
            subHeading={'View your rank and earn rewards.'}
            statusIndicator={false}
          />
          <div className='mt-4 info-table-container'>
            {orbsLeaderboardLoading ? (
              <div className='h-50vh flex items-center justify-center '>
                <DataLoader msg={'Fetching top scores...'} />
              </div>
            ) : orbsLeaderBoardData?.payload?.length > 0 ? (
              <InfoTable data={getTableData()} />
            ) : (
              <div className='h-50vh flex items-center justify-center'>
                <NotFound
                  msg='No leaderboard is active right now.'
                  addClassName='leaderboard-not-founded'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
