import { Tooltip } from 'react-tooltip';

export function UnstakeAmountInfoTooltip({}) {
  return (
    <Tooltip id='unstake-amount-tooltip' place='right'>
      <div className='stake-balance-tooltip-content'>
        <p>
          Currently, the amount is not editable. Unstaking will unstake the
          entire amount.
        </p>
      </div>
    </Tooltip>
  );
}
