import React, { useEffect } from 'react';
import Navbar from '../common/navbar';
import toast, { Toaster } from 'react-hot-toast';
import SocialHandler from '../social-handler/social-handler';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import GradientWrapper from '../common/gradient-wrapper';
import InfoBox from '../common/info-box';
import { useLocation, useNavigate } from 'react-router-dom';
import useSegment from '../../utils/hooks/useSegment';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import useRoute from '../../utils/hooks/useRoute';
import useWebSocket from '../../utils/hooks/useWebSocket';
import usePrivateWebsocket from '../../utils/hooks/usePrivateWebsocket';
import { useGetHealthStatusMutation } from '../../slices/appSlice';
import useTvlData from '../../utils/hooks/useTvlData';

function Layout({ children }) {
  const { userInfo } = useSelector((state) => state.auth);
  const { address, isConnected } = useWeb3ModalAccount();
  const { isUserLoggedIn } = useUserAuthentication();
  const dispatch = useDispatch();
  const location = useLocation();
  const { analytics, sendPageEvent } = useSegment();
  const { returnRouteName, isInRoutes } = useRoute();
  const { performLogout } = useUserAuthentication();
  const { latestTransactionData } = useSelector((state) => state.transaction);
  const { websocketStatus } = useWebSocket();
  const { message } = useSelector((state) => state.websocket);
  const { handleStakeDepositUpdateByWSMessage, fetchUpdatedTransactionData } =
    usePrivateWebsocket();

  const [getHealthStatus] = useGetHealthStatusMutation();
  const navigate = useNavigate();

  const { getTVLData } = useTvlData();

  useEffect(() => {
    const checkHealthStatus = async () => {
      try {
        const response = await getHealthStatus().unwrap();
      } catch (error) {
        if (error?.status === 451 || error?.code === 451) {
          navigate('/ip-fallback');
        } else {
          navigate('/server-down');
        }
      }
    };

    checkHealthStatus();
  }, []);

  useEffect(() => {
    const pageName = returnRouteName();
    sendPageEvent(pageName + 'PageVisited');
  }, [analytics, location.pathname]);

  useEffect(() => {
    if (userInfo?.address && isConnected) {
      if (address?.toLowerCase() !== userInfo?.address?.toLowerCase()) {
        toast(
          "It seems like you've changed the current account. Please login again."
        );
        performLogout(dispatch);
      }
    }
  }, [userInfo?.address, isConnected, address]);

  // WS events listenser
  useEffect(() => {
    // Check if both stake_deposit objects are present and have the same UUID
    handleStakeDepositUpdateByWSMessage();
  }, [latestTransactionData, message, dispatch]);

  // Fallback for old transction for missed events
  useEffect(() => {
    fetchUpdatedTransactionData();
  }, [latestTransactionData, dispatch]);

  return (
    <>
      {!isInRoutes(location.pathname) ? (
        children
      ) : (
        <>
          <div className='container large-container'>
            <Navbar />
          </div>
          <div className='container'>
            <GradientWrapper
              bodyClassName={`tvl-info-gradient-container ${
                isUserLoggedIn ? 'logged-in' : ''
              } responsive-view`}
            >
              <InfoBox
                dataList={getTVLData()}
                containerClassName='tvl-info-container'
              />
            </GradientWrapper>
            {children}
            <Toaster
              toastOptions={{
                className: 'toaster-custom-styles',
                style: {
                  backgroundColor: 'var(--background-primary)',
                  color: '#fff',
                },
              }}
            />
          </div>
          <div className='container large-container'>
            <SocialHandler />
          </div>
        </>
      )}
    </>
  );
}

export default Layout;
