import { apiSlice } from './apiSlice';

export const historySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    yieldInfo: builder.query({
      query: ({ token }) => ({
        url: `/yields`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['YieldHistory'],
    }),
    listDeposits: builder.query({
      query: ({ token, page }) => ({
        url: `/deposits/?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['DepositHistory'],
    }),
    listWithdrawals: builder.query({
      query: ({ token, page }) => ({
        url: `/withdrawals/?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['WithdrawalsHistory'],
    }),
    listUnstake: builder.query({
      query: ({ token, page }) => ({
        url: `/unstake/list/?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['UnstakeHistory'],
    }),
    listStake: builder.query({
      query: ({ token, page }) => ({
        url: `/stake/list/?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['stakeHistory'],
    }),
    listYieldClaim: builder.query({
      query: ({ token, page }) => ({
        url: `/yield-claim/list/?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['yieldClaimHistory'],
    }),
    stakeDepositInfoByUUID: builder.mutation({
      query: ({ token, uuid }) => ({
        url: `/stake-deposit/?stake_deposit_uuid=${uuid}`,
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useLazyYieldInfoQuery,
  useLazyListWithdrawalsQuery,
  useLazyListDepositsQuery,
  useLazyListUnstakeQuery,
  useLazyListStakeQuery,
  useLazyListYieldClaimQuery,
  useStakeDepositInfoByUUIDMutation,
} = historySlice;
