import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { publicStatsUrl } from '../utils/constants';

// Create a new API slice for public stats
export const publicStatsSlice = createApi({
  reducerPath: 'publicStatsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: publicStatsUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['PublicMarketMetaData'],
  endpoints: (builder) => ({
    getPublicStats: builder.query({
      query: () => ({
        url: '/',
        method: 'GET',
      }),
      keepUnusedDataFor: 60,
      providesTags: ['PublicMarketMetaData'],
      transformResponse: (reponse) => {
        reponse['apy'] = {
          XUSDC: reponse.xUSDC_APY,
          XUSDT: reponse.xUSDT_APY,
          XBTC: reponse.xBTC_APY,
          XPAXG: reponse.xPAXG_APY,
          XSOL: reponse.xSOL_APY,
          USDC: reponse.xUSDC_APY,
          USDT: reponse.xUSDT_APY,
          BTC: reponse.xBTC_APY,
          PAXG: reponse.xPAXG_APY,
          SOL: reponse.xSOL_APY,
        };
        return reponse;
      },
    }),
  }),
});

export const { useGetPublicStatsQuery } = publicStatsSlice;
