import useAppStats from '../../utils/hooks/useAppStats';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';
import Button from '../common/button';
import DataLoader from '../common/loader';
import NotFound from '../common/not-found';
import Table from '../common/table';
import HeadingContainer from '../heading-container/heading-container';

const unstakedHoldings = {
  headers: [
    {
      logo: null,
      heading: null,
      data: 'Asset',
      accessor: 'asset',
    },
    {
      logo: null,
      heading: null,
      data: 'Value',
      accessor: 'valueLocked',
    },
    {
      logo: null,
      heading: null,
      data: 'Yield',
      accessor: 'apy',
    },
    {
      logo: null,
      heading: null,
      data: 'Missed Yield',
      accessor: 'apy',
    },
  ],
};

export function UnstakeHoldings({
  isHoldingFetchOperationLoading,
  unstakedCoinsList,
}) {
  const { displayTextOrPlaceholder } = useUserAuthentication();
  return (
    <div>
      <HeadingContainer
        title={'Multipli Holdings (Unstaked)'}
        subHeading={'View your unstaked assets.'}
        statusIndicator={false}
      />
      <div className='holding-table-container'>
        <Table>
          <Table.Header>
            {unstakedHoldings?.headers?.map((item, index) => {
              return (
                <Table.Entry key={index}>
                  <span className='table-data-text'>{item?.data}</span>
                </Table.Entry>
              );
            })}
          </Table.Header>
          <Table.Body>
            <>
              {isHoldingFetchOperationLoading ? (
                <div className='mt-4 mb-4'>
                  <DataLoader msg='Fetching unstaked holdings' />
                </div>
              ) : (
                <>
                  <>
                    {unstakedCoinsList === 0 ? (
                      <div className='mt-5'>
                        <NotFound msg={'No data found'} />
                      </div>
                    ) : (
                      <>
                        {unstakedCoinsList?.map((unstakeCoin, idx) => {
                          return (
                            <Table.Row key={idx}>
                              <Table.Entry>
                                <Table.EntryLogo
                                  src={unstakeCoin.logo}
                                  alt=''
                                ></Table.EntryLogo>
                                {unstakeCoin?.currency?.toUpperCase()}
                              </Table.Entry>
                              <Table.Entry>
                                {displayTextOrPlaceholder(unstakeCoin?.amount)}
                              </Table.Entry>
                              <Table.Entry addClassName='unstake-status'>
                                {displayTextOrPlaceholder(unstakeCoin.yield)}
                              </Table.Entry>
                              <Table.Entry addClassName='unstake-status'>
                                {displayTextOrPlaceholder(
                                  unstakeCoin.missed_yield
                                )}
                              </Table.Entry>
                            </Table.Row>
                          );
                        })}
                      </>
                    )}
                  </>
                </>
              )}
            </>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
