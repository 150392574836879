import { useSelector } from 'react-redux';
import {
  useLazyGetOrbsQuery,
  useLazyGetReferralCodeQuery,
  useCreateReferralMutation,
} from '../../slices/orbsSlice';

function useOrbs() {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo?.payload?.tokens?.access;

  const [triggerGetOrbs, resultGetOrbs] = useLazyGetOrbsQuery();

  const [triggerGetReferralCode, resultGetReferralCode] =
    useLazyGetReferralCodeQuery();

  const [
    createReferral,
    {
      data: createReferralData,
      isLoading: isCreateReferralLoading,
      error: errorCreateReferral,
    },
  ] = useCreateReferralMutation();

  const createNewReferral = async (referralCode) => {
    try {
      const data = { code: referralCode };
      const response = await createReferral({ token, data }).unwrap();
      return response.data;
    } catch (error) {
      throw error.data.message || 'Something went wrong';
    }
  };

  const fetchOrbsData = async () => {
    try {
      return await triggerGetOrbs({ token }, true).unwrap();
    } catch (error) {
      console.log({ error });
      throw error?.data?.message || error?.error;
    }
  };

  const fetchReferralCodeData = async () => {
    try {
      return await triggerGetReferralCode({ token }, true).unwrap();
    } catch (error) {
      console.log({ error });
      throw error?.data?.message || error?.error;
    }
  };

  return {
    fetchOrbsData,
    orbsResult: resultGetOrbs,
    fetchReferralCodeData,
    referralCodeResult: resultGetReferralCode,
    createNewReferral,
    createReferralData,
    isCreateReferralLoading,
    errorCreateReferral,
  };
}

export default useOrbs;
