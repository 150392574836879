import { HOME_ROUTE } from './constants';

export const routes = [
  {
    link: '/',
    name: 'Stake',
  },
  {
    link: '/holdings',
    name: 'Holdings',
  },
  {
    link: '/orbs',
    name: 'Orbs',
  },
  {
    link: '/dashboard',
    name: 'Dashboard',
  },
  // {
  //   link: '/leaderboard',
  //   name: 'Leaderboard',
  // },
];
