import { useState } from 'react';
import useViewport from '../../utils/hooks/useViewPort';
import Chart from '../charts';
import Dropdown, { DropdownItem } from '../common/dropdown';
import GradientWrapper from '../common/gradient-wrapper';
import useSegment from '../../utils/hooks/useSegment';
import { capitalizeFirstLetter } from '../../utils/string-methods';

function DashboardChart({
  data,
  title,
  type = 'multiLine',
  overrideChartStyle,
  handleChartTimelineChange,
  chartKey,
  disableFilter = false,
  filterOptions = ['max', 'weekly', 'monthly', 'yearly'],
  selectedFilterOption = 'weekly',
}) {
  const [selectedTimeStamp, setSelectedTimeStamp] =
    useState(selectedFilterOption);
  // const [selectedTimeStamp, setSelectedTimeStamp] = useState('1d');
  const { width } = useViewport();
  const { sendTrackEvent } = useSegment();

  const trackTimeFrameChangeEvent = (item) => {
    sendTrackEvent('chartTimeFrameChanged', {
      title: title,
      timeFrame: item,
    });
  };

  const handleTimestampChange = (item, toggleTab) => {
    setSelectedTimeStamp(item);
    toggleTab();
    trackTimeFrameChangeEvent(item);
    if (
      chartKey &&
      handleChartTimelineChange &&
      typeof handleChartTimelineChange === 'function'
    ) {
      handleChartTimelineChange(chartKey, item);
    }
  };
  return (
    <GradientWrapper bodyClassName='chart-gradient-wrapper w-100'>
      <h3>{title}</h3>
      {!disableFilter && (
        <Dropdown
          buttonComp={
            <div className='button-comp-title'>
              {capitalizeFirstLetter(
                selectedTimeStamp === 'max' ? 'All' : selectedTimeStamp
              )}
            </div>
          }
          content={(toggleTab) => (
            <>
              {/* {['All', '1d', '1w', '1m', '3m', '6m', '1y', '3y'].map( */}
              {filterOptions.map((item, id) => (
                <DropdownItem
                  key={id}
                  onClick={() => {
                    handleTimestampChange(item, toggleTab);
                  }}
                >
                  {capitalizeFirstLetter(item === 'max' ? 'All' : item)}
                </DropdownItem>
              ))}
            </>
          )}
        />
      )}
      <div className='flex flex-row chart-wrapper w-100'>
        <Chart
          type={type}
          chartData={data}
          title={title}
          chartStyle={
            overrideChartStyle
              ? overrideChartStyle
              : {
                  width: '100%',
                  height: width > 550 ? 300 : 400,
                }
          }
        />
      </div>
    </GradientWrapper>
  );
}

export default DashboardChart;
