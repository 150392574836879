import ConnectWalletBtn from './connect-wallet-btn';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { truncate } from '../../utils/string-methods';
import Dropdown, { DropdownItem } from './dropdown';
import { images } from '../../utils/images';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';

const NavbarConnectWalletButton = ({
  handleNavDropdown,
  onClose,
  navOptions,
}) => {
  const { address } = useWeb3ModalAccount();
  const { isUserLoggedIn } = useUserAuthentication();
  return (
    <>
      {isUserLoggedIn ? (
        <Dropdown
          buttonComp={
            <div className='button-comp-title'>
              <img src={images.profileImage} />
              <p>{truncate(address, 12)}</p>
            </div>
          }
          content={
            <>
              {navOptions.map((item, id) => (
                <DropdownItem key={id} onClick={() => handleNavDropdown(item)}>
                  {item}
                </DropdownItem>
              ))}
            </>
          }
        />
      ) : (
        <ConnectWalletBtn onClose={onClose} eventSource={'Navbar'} />
      )}
    </>
  );
};

export default NavbarConnectWalletButton;
