import { apiSlice } from './apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `/auth/login/`,
        body: data,
        method: 'POST',
      }),
    }),
    getLoginNonce: builder.mutation({
      query: (data) => ({
        url: '/auth/nonce/',
        method: 'POST',
        body: data,
      }),
    }),
    getUserMultipliBalance: builder.query({
      query: (token) => ({
        url: '/getbalances',
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      // transformResponse: (res) => {
      //   return {
      //     status: 'success',
      //     message: 'Retrieved Successfully',
      //     payload: [
      //       {
      //         currency: 'btc',
      //         balance: '0.0',
      //         locked: '0.0',
      //       },
      //       {
      //         currency: 'eth',
      //         balance: '0.0',
      //         locked: '0.0',
      //       },
      //       {
      //         currency: 'matic',
      //         balance: '0.0',
      //         locked: '0.0',
      //       },
      //       {
      //         currency: 'usdc',
      //         balance: '120.0',
      //         locked: '0.0',
      //       },
      //       {
      //         currency: 'usdt',
      //         balance: '100.0',
      //         locked: '0.0',
      //       },
      //       {
      //         currency: 'xusdc',
      //         balance: '150.0',
      //         locked: '0.0',
      //       },
      //       {
      //         currency: 'xusdt',
      //         balance: '340.0',
      //         locked: '0.0',
      //       },
      //     ],
      //   };
      // },
      keepUnusedDataFor: 5,
      providesTags: ['UserBalanceData'],
    }),
    getUserDepositVaultIdList: builder.query({
      query: ({ token, coin }) => ({
        url: '/create_vault/',
        body: { coin },
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      keepUnusedDataFor: 5,
      providesTags: ['UserDepositVaultIdData'],
    }),
    startDeposit: builder.mutation({
      query: ({ token, data }) => ({
        url: `/stark/start/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    initiateNormalWithdrawal: builder.mutation({
      query: ({ token, data }) => ({
        url: `/withdrawals/initiate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    validateNormalWithdrawal: builder.mutation({
      query: ({ token, data }) => ({
        url: `/withdrawals/validate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    initiateNormalL1Withdrawal: builder.mutation({
      query: ({ token, data }) => ({
        url: `/l1-withdrawals/initiate/`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetLoginNonceMutation,
  useLoginMutation,
  useLazyGetUserMultipliBalanceQuery,
  useLazyGetUserDepositVaultIdListQuery,
  useStartDepositMutation,
  useInitiateNormalWithdrawalMutation,
  useValidateNormalWithdrawalMutation,
  useInitiateNormalL1WithdrawalMutation,
} = usersApiSlice;
