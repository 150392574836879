import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { FaPaperclip } from 'react-icons/fa6';

const FeedbackImageUploader = ({
  openFeedBackPopup,
  selectedImages,
  setSelectedImages,
}) => {
  const allowedFileTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
  ];
  const maxFileSize = 2 * 1024 * 1024; // 2MB

  const sliderResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      paritialVisibilityGutter: 30,
    },
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    const invalidFiles = [];

    if (selectedImages?.length >= 5 || files.length > 5) {
      return toast.error('Only 5 images are allowed.', {
        id: 'allowed_image',
      });
    }

    files.forEach((file) => {
      if (allowedFileTypes.includes(file.type) && file.size <= maxFileSize) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    setSelectedImages((prevImages) => [...prevImages, ...validFiles]);

    if (invalidFiles.length > 0) {
      // Display a notification for invalid files
      toast.error(
        'Invalid file type or size. Only image files (JPEG, PNG, GIF) up to 400 KB are allowed.'
      );
    }
  };

  const handleDelete = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
    document.getElementById('fileInput').value = '';
  };

  useEffect(() => {
    const handlePaste = (evt) => {
      if (openFeedBackPopup) {
        const clipboardItems = evt.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
          // Filter the image items only
          return /^image\//.test(item.type);
        });
        if (items.length === 0) {
          return;
        }
        const validFiles = [];
        items?.forEach((image) => {
          const blob = image.getAsFile();
          let file = new File(
            [blob],
            'file_name.jpeg',
            { type: 'image/jpeg', lastModified: new Date().getTime() },
            'utf-8'
          );
          validFiles.push(file);
        });
        if (selectedImages?.length >= 5 || validFiles?.length > 5) {
          return toast.error('Only 5 images are allowed.', {
            id: 'allowed_image',
          });
        }
        setSelectedImages((prevImages) => {
          if (
            prevImages?.length > 5 ||
            prevImages?.length + validFiles?.length > 5
          ) {
            toast.error('Only 5 images are allowed.', {
              id: 'allowed_image',
            });
            return [...prevImages];
          }
          return [...prevImages, ...validFiles];
        });
      }
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [openFeedBackPopup]);

  return (
    <div>
      <label>Upload image (optional)</label>
      <div className='image-uploader'>
        <label
          htmlFor='fileInput'
          className='feedback-image-uploader-placeholder'
        >
          {selectedImages.length === 0 && <span>Choose an image</span>}
          <input
            id='fileInput'
            className='fileInput'
            type='file'
            multiple
            onChange={handleImageChange}
          />
          <span>
            {selectedImages.length === 0
              ? null
              : `${selectedImages.length} file(s) selected`}
          </span>
          <span className='image-uploader-icon'>
            <FaPaperclip />
          </span>
        </label>
      </div>
      <div
        className={selectedImages?.length ? 'mb-2' : ''}
        style={{ position: 'relative', width: '100%', maxWidth: '100%' }}
      >
        {selectedImages?.length > 0 && (
          <Carousel
            responsive={sliderResponsive}
            containerClass='feedback-selected-image-container'
          >
            {selectedImages?.map((image, index) => (
              <div
                className={`feedback-image-item ${index !== 0 ? 'ml-2' : ''}`}
                key={index}
              >
                <div>
                  <button
                    title='Close (Esc)'
                    type='button'
                    className='mfp-close'
                    onClick={() => handleDelete(index)}
                  >
                    ×
                  </button>
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt={`Image ${index}`}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default FeedbackImageUploader;
