import React from 'react';

function HeadingContainer({ title, subHeading, statusIndicator }) {
  return (
    <div className='heading-container'>
      <div className='heading-title'>
        {statusIndicator && <div className='active-status blink'></div>}
        <h1 className='heading'>{title}</h1>
      </div>
      <div className='sub-heading-container'>
        <span className='sub-heading'>{subHeading}</span>
      </div>
    </div>
  );
}

export default HeadingContainer;
