import React, { useEffect, useRef } from 'react';

function useDebouncedEffect(callback, delay, condition, dependencies) {
  const timerRef = useRef();

  useEffect(() => {
    // Clear the previous timer on every useEffect call
    return () => clearTimeout(timerRef?.current);
  }, []);

  useEffect(() => {
    // Set a new timer whenever the dependencies change
    if (condition) {
      timerRef.current = setTimeout(callback, delay);
    }
    // Clear the timer if the dependencies change before the delay has elapsed
    return () => clearTimeout(timerRef?.current);
  }, dependencies);
}

export default useDebouncedEffect;
