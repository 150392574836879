import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment/moment';
import { MultiLineChartLegends, ChartTooltip } from './utils';
import useSegment from '../../utils/hooks/useSegment';

const formatTimestamp = (timestamp) => moment(timestamp).format('LL');

function MultiLineChart({ chartData, chartStyle, title }) {
  const [activeLegends, setActiveLegends] = useState(
    chartData?.length ? chartData.map((item) => item.name) : []
  );

  const { sendTrackEvent } = useSegment();

  const trackLegendChangeEvent = (item, status) => {
    sendTrackEvent('chartLegendChanged', {
      title: title,
      legend: item,
      status: status,
    });
  };

  // Handler to toggle active legend and update state
  const handleLegendClick = (dataKey) => {
    if (activeLegends.includes(dataKey)) {
      trackLegendChangeEvent(dataKey, 'inactive');
      setActiveLegends(activeLegends.filter((legend) => legend !== dataKey)); // Deselect if already selected
    } else {
      trackLegendChangeEvent(dataKey, 'active');
      setActiveLegends([...activeLegends, dataKey]); // Select legend
    }
  };

  return (
    <ResponsiveContainer height={300} width={'50%'} {...chartStyle}>
      <LineChart>
        {/* <CartesianGrid strokeDasharray='0.1 0.1' /> */}
        <XAxis
          dataKey='timestamp'
          type='category'
          allowDuplicatedCategory={false}
          tickFormatter={formatTimestamp}
          fontSize={12}
          minTickGap={30}
        />
        <YAxis dataKey='value' fontSize={12} />
        <Tooltip content={<ChartTooltip />} />
        {chartData.length > 1 ? (
          <Legend
            content={
              <MultiLineChartLegends
                handleLegendClick={handleLegendClick}
                activeLegends={activeLegends}
              />
            }
          />
        ) : null}
        {chartData?.map((s, idx) => {
          return (
            <Line
              connectNulls={true}
              dataKey='value'
              data={s.data}
              name={s.name}
              key={idx}
              dot={false}
              stroke={s.color}
              strokeWidth={activeLegends.includes(s.name) ? 1 : 0.5} // Highlight selected line
              opacity={!activeLegends.includes(s.name) ? 0.2 : 1}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default MultiLineChart;
