import React from 'react';
import Lottie from 'react-lottie';

const LottieWrapper = ({ animationData, ...otherLottieProps }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        {...otherLottieProps}
      />
    </div>
  );
};

export default LottieWrapper;
