import moment from 'moment/moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useHistory from './useHistory';
import { setLatestTransactionData } from '../../slices/transactionSlice';

export const validUpdateStatuses = ['COMPLETED', 'FAILED'];

function usePrivateWebsocket() {
  const { message } = useSelector((state) => state.websocket);
  const { latestTransactionData } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const { getStakeDepositInfoByUUID } = useHistory();

  const handleStakeDepositUpdateByWSMessage = () => {
    if (latestTransactionData?.stake_deposit && message?.stake_deposit) {
      const latestStakeDeposit = latestTransactionData.stake_deposit;
      const messageStakeDeposit = message.stake_deposit;

      if (
        latestStakeDeposit.uuid === messageStakeDeposit.uuid &&
        !validUpdateStatuses.includes(latestStakeDeposit?.state)
      ) {
        // Check for status update in the WebSocket message
        if (validUpdateStatuses.includes(messageStakeDeposit.state)) {
          // Update the state in Redux
          // alert('update, after getting websocket event');
          dispatch(
            setLatestTransactionData({
              type: 'stake_deposit',
              data: messageStakeDeposit,
            })
          );
        }
      }
    }
  };

  const fetchUpdatedTransactionData = async () => {
    if (latestTransactionData) {
      const stakeDeposit = latestTransactionData?.stake_deposit;
      const stakeDepositUUID = stakeDeposit?.uuid;
      const createdAt = stakeDeposit?.created_at;

      // Check if state is not 'COMPLETED' or 'FAILED'
      if (!validUpdateStatuses?.includes(stakeDeposit?.state)) {
        // Check if created_at is more than or equal to 3 minutes old
        const isOlderThanFiveMinutes =
          moment().diff(moment(createdAt), 'minutes') >= 3;

        if (isOlderThanFiveMinutes) {
          try {
            const response = await getStakeDepositInfoByUUID(stakeDepositUUID);
            const updatedData = response?.payload;
            // Update the Redux store if state is 'COMPLETED' or 'FAILURE'
            if (validUpdateStatuses?.includes(updatedData?.state)) {
              // alert("update after 3 mins -  old tx's");
              dispatch(
                setLatestTransactionData({
                  type: 'stake_deposit',
                  data: updatedData,
                })
              );
            }
          } catch (error) {
            console.log('Failed to fetch updated transaction data:', error);
          }
        }
      }
    }
  };

  return {
    handleStakeDepositUpdateByWSMessage,
    fetchUpdatedTransactionData,
  };
}

export default usePrivateWebsocket;
