import React from 'react';
import Select from 'react-select';

function CustomSelect({ defaultValue, options, ...otherProps }) {
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: 'transparent',
      borderColor: state.isFocused ? '#6c5dd30d' : 'transparent',
      cursor: 'pointer',
      borderRadius: 10,
      flex: 1,
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#8173E0' : 'white',
      borderColor: 'transparent',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: 1,
      backgroundColor: state?.isDisabled
        ? undefined
        : state?.isSelected
        ? 'rgba(128, 129, 145,0.2)'
        : state?.isFocused
        ? 'rgba(128, 129, 145,0.05)'
        : undefined,
    }),
    container: (baseStyles, state) => ({
      ...baseStyles,
      borderBottom: '0.5px solid rgba(165, 108, 253, 0.5)',
      borderTop: '0.5px solid rgba(255, 255, 255, 0.2)',
      borderLeft: '0.5px solid rgba(255, 255, 255, 0.2)',
      borderRight: '0.5px solid rgba(255, 255, 255, 0.2)',
      background:
        'linear-gradient(231deg, rgba(255, 255, 255, 0.01) 4.25%, rgba(255, 255, 255, 0.02) 72.31%, rgba(166, 108, 255, 0.05) 95.61%)',
      borderRadius: 10,
      bordercolor: '#8173E0',
      width: '50vw',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: '#fff',
      fontSize: 16,
      fontFamily: 'Roobert-Light, sans-serif',
      paddingLeft: 10,
      fontWeight: 600,
      textTransform: 'revert',
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 125,
      fontSize: 14,
      fontFamily: 'Roobert-Light, sans-serif',
      borderRadius: 10,
      '::-webkit-scrollbar': {
        width: '2px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#242731',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      background: '#000f',
      zIndex: 9999, // Set a higher z-index value
      position: 'absolute', // Ensure position is set
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: 10,
        position: 'relative',
        height: 54,
        borderRadius: 10,
        backgroundColor: 'transparent',
        zIndex: 2,
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#808191',
        fontSize: 12,
        zIndex: 2,
      };
    },
  };

  return (
    <div style={{ fontFamily: 'Poppins' }}>
      <Select
        defaultValue={defaultValue}
        styles={customStyles}
        options={options}
        {...otherProps}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            primary: '#6c5dd3',
          },
        })}
      />
    </div>
  );
}

export default CustomSelect;
