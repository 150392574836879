import { AiOutlineInfoCircle } from 'react-icons/ai';
import useNumber from '../../utils/hooks/useNumber';
import InfoTable from '../info-table/info-table';
import { Tooltip } from 'react-tooltip';
import { useGetPublicStatsQuery } from '../../slices/publicStatsSlice';
import useAppStats from '../../utils/hooks/useAppStats';

function APYInfoContainer({
  stakeInputValue,
  selectedToken,
  stakeTableData,
  selectedFilterOption,
}) {
  const { formatNumber, getCoveragePercentage } = useNumber();
  const {
    data: platformStatsData,
    isLoading: isPlatformStatsDataLoading,
    error: isPlatformStatsDataError,
  } = useGetPublicStatsQuery();
  const { getAPYByCurrency } = useAppStats();

  const getDepositLimitData = () => {
    if (isPlatformStatsDataLoading || isPlatformStatsDataError) {
      return {};
    }
    if (platformStatsData) {
      let totalVolumeStaked =
        platformStatsData?.volume_staked[selectedToken?.symbol?.toLowerCase()]
          ?.total_volume_staked;
      let stakeVolumeLimit =
        platformStatsData?.volume_staked[selectedToken?.symbol?.toLowerCase()]
          ?.stake_volume_limit;

      return {
        totalVolumeStaked,
        stakeVolumeLimit,
        coveragePercentage: getCoveragePercentage(
          stakeVolumeLimit,
          totalVolumeStaked
        ),
      };
    }
  };

  return (
    <>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-column gap-15'>
          <h1 className='text-xl'>Projected APY</h1>
          <h1 className='text-xl text-active'>
            {getAPYByCurrency(selectedToken?.symbol?.toUpperCase()) || '--'}%
          </h1>
        </div>
        <div className='flex flex-column gap-15'>
          <h1 className='text-xl text-right'>
            You {selectedFilterOption.label}
          </h1>
          <h1 className='text-xl text-active text-right'>
            {stakeInputValue ? formatNumber(stakeInputValue) : ''}{' '}
            {selectedToken?.symbol?.toUpperCase()}
          </h1>
        </div>
      </div>
      <div className='flex flex-column info-table-container stake-info-table-container'>
        <InfoTable data={stakeTableData} />
        {selectedFilterOption?.value === 'stake' ? (
          <>
            <div className='flex flex-row'>
              <div className='progress-container'>
                <div
                  className='progress-bar'
                  style={{
                    width: `${getDepositLimitData().coveragePercentage}%`,
                  }}
                ></div>
              </div>
            </div>
            {!platformStatsData ? null : (
              <div className='flex flex-row mt-3 deposit-limit-text'>
                <p className='text-m'>
                  {` 
              ${formatNumber(
                getDepositLimitData().totalVolumeStaked
              )} /  ${formatNumber(
                    getDepositLimitData().stakeVolumeLimit
                  )} Deposit Limit`}
                  {/* "" */}
                </p>
                <p className='text-m'>
                  <span
                    data-tooltip-id='deposit-limit-tooltip'
                    data-tooltip-content={`Total stake volume limit on ${selectedToken?.symbol?.toUpperCase()} / Total volume staked on ${selectedToken?.symbol?.toUpperCase()}`}
                  >
                    <AiOutlineInfoCircle size={13} />
                  </span>
                  <Tooltip
                    id='deposit-limit-tooltip'
                    place='bottom'
                    className='deposit-limit-tooltip'
                  />
                </p>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
}

export default APYInfoContainer;
