import React from 'react';
import ReactStepper from '@keyvaluesystems/react-stepper';
import { AiOutlineCheck } from 'react-icons/ai';

const RenderNodeComp = ({ stepIndex, otherStepperProps }) => {
  if (otherStepperProps.currentStepIndex === stepIndex) {
    return <div className='loader'></div>;
  } else if (stepIndex < otherStepperProps.currentStepIndex) {
    return <AiOutlineCheck />;
  } else {
    return <div key={stepIndex}>{stepIndex + 1}</div>;
  }
};

function Stepper({ overrideStyles, ...otherStepperProps }) {
  const styles = {
    LineSeparator: () => ({
      backgroundColor: '#a66cff',
    }),
    ActiveNode: () => ({
      backgroundColor: '#a66cff',
    }),
    CompletedNode: () => ({
      backgroundColor: '#a66cff',
    }),
    LabelTitle: () => ({
      fontSize: '14px',
    }),
    LabelDescription: () => ({
      fontSize: '12px',
    }),
    ...overrideStyles,
  };

  return (
    <ReactStepper
      styles={styles}
      showDescriptionsForAllSteps={true}
      renderNode={(step, stepIndex) => (
        <RenderNodeComp
          stepIndex={stepIndex}
          otherStepperProps={otherStepperProps}
        />
      )}
      {...otherStepperProps}
    />
  );
}

export default Stepper;
