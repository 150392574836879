import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
  title: '',
  description: '',
  link: '',
  linkText: '',
  type: 'success',
};

const infoModalState = createSlice({
  name: 'infoModalState',
  initialState,
  reducers: {
    setModalState: (state, action) => {
      state.isModalOpen = action.payload.isModalOpen;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.link = action.payload.link;
      state.linkText = action.payload.linkText;
      state.type = action.payload.type;
    },
  },
});

export const { setModalState } = infoModalState.actions;

export default infoModalState.reducer;
