import React, { useEffect, useState } from 'react';
import Button from './button';
import useWallet from '../../utils/hooks/useWallet';
import toast from 'react-hot-toast';
import { useGetAppStatsQuery } from '../../slices/appSlice';
import {
  useGetLoginNonceMutation,
  useLoginMutation,
} from '../../slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout,
  setLoginCredentials,
  setSignatureCredentials,
} from '../../slices/authSlice';
import { getKeyPairFromSignature as getStarkKeyFromSignature } from '../../utils/stark-ex/app';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWalletInfo,
  useWeb3ModalState,
  useWeb3ModalEvents,
} from '@web3modal/ethers/react';
import { remove0x0Prefix } from '../../utils/string-methods';
import BigNumberUtils from '../../utils/stark-ex/bignumber-ethers-v5';
import useAppStats from '../../utils/hooks/useAppStats';
import Modal from './modal';
import { setModalState } from '../../slices/loginModalSlice';
import { useSearchParams } from 'react-router-dom';
import useSegment from '../../utils/hooks/useSegment';
import useUserAuthentication from '../../utils/hooks/useUserAuthentication';

const stageInfo = {
  CONNECT_WALLET: {
    title: 'Connect Your Wallet',
    description: "You'll be prompted to choose a wallet in the next step",
    buttonTitle: 'Proceed',
  },
  SIGN_MESSAGE: {
    title: 'Sign Message',
    description:
      'We require two signatures to confirm your ownership and wallet compatibility.',
    buttonTitle: 'Sign Message',
  },
  VERIFY_MESSAGE: {
    title: 'Verify Message',
    description:
      'We require two signatures to confirm your ownership and wallet compatibility.',
    buttonTitle: 'Verify Message',
  },
  REFERRAL_MESSAGE: {
    title: 'Unlock Multipli Beta',
    description:
      'Multipli Beta is currently invite only, please enter your referral code to join!',
    buttonTitle: 'Login',
  },
};

const getLoginBaseEventName = (base) => `loginNonceRequest${base}`;

function LoginModal() {
  const [stage, setStage] = useState('CONNECT_WALLET');
  const { isConnected, address } = useWeb3ModalAccount();
  const [signatureInfo, setSignatureInfo] = useState({
    nonce: null,
    starkPublicKey: null,
    staticSignature: null,
    nonceSignature: null,
    address: null,
  });
  const [referralCode, setReferralCode] = useState('');
  const [isWalletOperationLoading, setIsWalletOperationLoading] =
    useState(false);
  const { getStaticMessageFromAppStats } = useAppStats();
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const { onSignMessage } = useWallet();
  const { walletInfo } = useWalletInfo();
  const { isModalOpen } = useSelector((state) => state.loginModal);
  const [searchParams, setSearchParams] = useSearchParams();
  // api calls:
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const { isLoading: isGetAppStatsLoading } = useGetAppStatsQuery();
  const [getLoginNonce, { isLoading: isGetLoginNonceLoading }] =
    useGetLoginNonceMutation();
  const events = useWeb3ModalEvents();
  const { performLogout } = useUserAuthentication();

  const { sendTrackEvent } = useSegment();

  const handleGetNonce = async (ethAddress) => {
    try {
      sendTrackEvent(getLoginBaseEventName('Initiated'), {
        address: ethAddress,
      });
      const data = await getLoginNonce({ eth_address: ethAddress }).unwrap();
      if (data?.payload?.nonce) {
        sendTrackEvent(getLoginBaseEventName('Success'), {
          address: ethAddress,
        });
      }
      return data?.payload?.nonce;
    } catch (error) {
      console.error('Error getting login nonce:', {
        address: ethAddress,
        error: error?.data?.message || error?.error,
      });
      sendTrackEvent(getLoginBaseEventName('Failure'), {
        address: ethAddress,
        error: error?.data?.message || error?.error,
      });

      throw error?.data?.message || error?.error;
    }
  };

  const handleLogin = async (userSignature, referralCode = '') => {
    try {
      let loginRequestBody = {
        eth_address: address,
        user_signature: userSignature,
        static_user_signature: signatureInfo.staticSignature,
        stark_key: remove0x0Prefix(signatureInfo.starkPublicKey),
      };
      if (referralCode.length) {
        loginRequestBody['referral_code'] = referralCode;
      }
      const data = await login(loginRequestBody).unwrap();
      let response = { ...data };
      response['address'] = address;
      dispatch(setLoginCredentials({ ...response }));

      sendTrackEvent('connectWalletSuccess', { address });
    } catch (error) {
      console.error('Login error:', error);

      sendTrackEvent('connectWalletFailed', {
        address,
        error: error?.data?.message || error?.error,
      });

      throw error?.data?.message || error?.error;
    }
  };

  useEffect(() => {
    if (isConnected && !signatureInfo.nonce) {
      setStage('SIGN_MESSAGE');
    } else if (isConnected && signatureInfo.nonce) {
      setStage('VERIFY_MESSAGE');
      handleConnection('VERIFY_MESSAGE');
    } else if (!isConnected) {
      setStage('CONNECT_WALLET');
    }
  }, [isConnected, signatureInfo.nonce]);

  const handleConnection = async (currentStage = stage) => {
    setIsWalletOperationLoading(true);
    try {
      if (currentStage === 'CONNECT_WALLET') {
        await open();
        setIsWalletOperationLoading(false);
      } else if (currentStage === 'SIGN_MESSAGE') {
        let staticMsg = getStaticMessageFromAppStats();
        let signature = await onSignMessage(staticMsg, 'first');
        let starkPublicKey = getStarkKeyFromSignature(signature)
          .getPublic()
          .getX();
        const finalKey = BigNumberUtils.BigNumber.from(
          starkPublicKey.toString()
        ).toHexString();
        dispatch(setSignatureCredentials(signature));
        let nonce = await handleGetNonce(address);
        setSignatureInfo({
          nonce,
          starkPublicKey: finalKey,
          staticSignature: signature,
          address: address,
        });
        setIsWalletOperationLoading(false);
      } else if (currentStage === 'VERIFY_MESSAGE') {
        let signature = await onSignMessage(signatureInfo.nonce, 'second');
        setSignatureInfo({
          ...signatureInfo,
          nonceSignature: signature,
          address: address,
        });
        await handleLogin(signature);
        setIsWalletOperationLoading(false);
        toogleModalState(false);
        toast.success('Login successful');
        resetState();
      } else if (currentStage === 'REFERRAL_MESSAGE') {
        handleReferralLogin(referralCode);
      }
    } catch (error) {
      if (error === 'Referral code is required') {
        setStage('REFERRAL_MESSAGE');
        setIsWalletOperationLoading(false);
      } else {
        toast.error(error);
        resetState();
        performLogout(dispatch);
        setIsWalletOperationLoading(false);
      }
    }
  };

  const removeReferralQueryParams = () => {
    searchParams.delete('referral_code');
    setSearchParams(searchParams);
  };

  const handleReferralLogin = async (referralCode) => {
    setIsWalletOperationLoading(true);
    try {
      await handleLogin(signatureInfo.nonceSignature, referralCode);
      setIsWalletOperationLoading(false);
      toogleModalState(false);
      toast.success('Login successful');
      removeReferralQueryParams();
      resetState();
    } catch (error) {
      removeReferralQueryParams();
      setIsWalletOperationLoading(false);
      error && toast.error(error);
    }
  };

  const toogleModalState = () => {
    dispatch(setModalState(!isModalOpen));
  };

  const onClose = () => {
    resetState();
    setReferralCode('');
    sendTrackEvent('connectWalletUserAborted', { address: address });
    if (isConnected) {
      setStage('SIGN_MESSAGE');
    } else {
      setStage('CONNECT_WALLET');
    }
  };

  const resetState = () => {
    setSignatureInfo({
      nonce: null,
      staticSignature: null,
      starkPublicKey: null,
      nonceSignature: null,
    });
  };

  useEffect(() => {
    // This will cover the edge case where the user can change the account in the referral section.
    if (
      signatureInfo?.address?.length &&
      address?.length &&
      signatureInfo?.address !== address
    ) {
      toast(
        "It seems like you've changed the current account. Please login again."
      );
      onClose();
    }
  }, [signatureInfo?.address, address]);

  useEffect(() => {
    if (location.search && stage === 'REFERRAL_MESSAGE') {
      let userReferralCode = searchParams.get('referral_code');
      setReferralCode(userReferralCode);
      handleReferralLogin(userReferralCode);
    }
  }, [location, stage]);

  const handleOpenWeb3Modal = async () => {
    await open();
  };

  useEffect(() => {
    if (isModalOpen && stage === 'CONNECT_WALLET' && !isConnected) {
      handleOpenWeb3Modal();
    }
  }, [isModalOpen, stage, isConnected]);

  useEffect(() => {
    if (
      events?.data?.event === 'MODAL_CLOSE' &&
      !isConnected &&
      stage === 'CONNECT_WALLET'
    ) {
      toogleModalState();
    }
  }, [isConnected, stage, events?.data?.event]);

  if (stage === 'CONNECT_WALLET') {
    return null;
  }

  return (
    <Modal
      state={isModalOpen}
      setState={toogleModalState}
      modalClassName='login-modal'
      onClose={onClose}
    >
      <div className='login-modal-container'>
        <div className='flex flex-row'>
          {walletInfo?.icon ? (
            <img src={walletInfo?.icon} className='wallet-icon' />
          ) : null}
          <h3 className='text-medium'>{stageInfo[stage].title}</h3>
        </div>
        <p className='mt-3 text-secondary'>{stageInfo[stage].description}</p>
        {stage === 'REFERRAL_MESSAGE' ? (
          <input
            className='mt-3'
            placeholder='Please enter your referral code'
            autoFocus={true}
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
        ) : null}
        <Button
          className={`btn btn-block ${
            stage === 'REFERRAL_MESSAGE' ? 'mt-4' : 'mt-4'
          }  btn-primary`}
          onClick={() => handleConnection()}
          isLoading={isWalletOperationLoading || isGetAppStatsLoading}
        >
          {stageInfo[stage].buttonTitle}
        </Button>
      </div>
    </Modal>
  );
}

export default LoginModal;
