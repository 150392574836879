import React from 'react';
import HeadingContainer from '../heading-container/heading-container';
import moment from 'moment';

function CustodiansContainer({ data }) {
  return (
    <div className='custodians-container'>
      <HeadingContainer
        title={'Asset Custodians'}
        subHeading={`Last Updated: ${moment()
          .subtract(1, 'days')
          .format('llll')}`}
        statusIndicator={false}
      />

      <div className='info-table-body'>
        {data.map((custodian, index) => (
          <div className='info-table-row' key={index}>
            <img src={custodian?.logo} alt='' />
            <div className='custodian-address'>{custodian?.address}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustodiansContainer;
