import React from 'react';

const Table = ({ children, addClassName, ...otherProps }) => {
  return (
    <div className={`info-table ${addClassName || ''}`} {...otherProps}>
      {children}
    </div>
  );
};

Table.Header = ({ children }) => {
  return <div className='info-table-header'>{children}</div>;
};

Table.Entry = ({ children, addClassName, ...otherProps }) => {
  return (
    <div className={`info-table-entry ${addClassName || ''}`} {...otherProps}>
      {children}
    </div>
  );
};

Table.EntryText = ({ children }) => {
  return <div className='table-entry-text'>{children}</div>;
};

Table.EntryLogo = ({ ...otherImageProps }) => {
  return <img className='table-entry-logo' {...otherImageProps} />;
};

Table.Row = ({ children }) => {
  return <div className='info-table-row'>{children}</div>;
};

Table.Body = ({ children }) => {
  return <div className='info-table-body'>{children}</div>;
};

export default Table;
